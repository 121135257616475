import React from 'react';
import { StatusDisplay } from 'components/utils';
import { formatDecimals } from 'helpers';

import { Link } from 'react-router-dom';
import { ITickerData } from 'store/app/types';
import { 
    SparkItem, Handle, Border, Flex, 
    TickerDisplay, TickerListHeader, 
    BorderTitle 
} from 'styles';

export function Similar(props: { title: string; ticker: string; data: ITickerData[]; }) {
    // data is in stock state.
    // put in live state instead?
    const { title, ticker, data } = props;
    const isEmpty = data === undefined || data.length === 0;

    if( isEmpty )
        return <></>;

    return (
        <Border padding="0.5rem" className="flex-column mt-4">
            <BorderTitle>
                <h3>{props.title}</h3>
            </BorderTitle>
            <Flex direction="column" grow="1">
                {
                    data.filter( (item) => item.ticker !== ticker).map((item) => {
                        //const icon = item.icon; to do
                        const hasData = item.data && item.data.length !== 0;
                        const showStatusIcon = item.data && item.data.length < 10;
                        const title = props.title === 'Indexes' ? item.name : item.ticker;
                        
                        return (
                            <SparkItem key={`similar-${item.ticker}`}>
                                <TickerDisplay showStatusIcon={showStatusIcon}>
                                        <Link
                                            to={'/symbol/' + item.ticker}
                                            data-tip={item.id}
                                            data-for="item-id"
                                            >
                                            {title}
                                        </Link>
                                    {
                                        // to do
                                        //icon
                                    }
                                </TickerDisplay>

                                <StatusDisplay
                                    data={item.data}
                                    status={item.status}
                                    hasData={hasData}
                                    showStatusIcon={showStatusIcon}
                                    />

                                <div className="valueDisplay"> {/* to do <ValueDisplay /> */}
                                    <span>${formatDecimals(item.price)}</span>
                                    {item.changePercent ? (
                                        <span className={item.status}>
                                            {formatDecimals(item.changePercent)}%
                                        </span>
                                    ) : null}
                                </div>
                            </SparkItem>
                        );
                    })
                }
            </Flex>
        </Border>
    );
}
