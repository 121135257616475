import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItem, NotificationsAlert, NotificationItem, NotificationTitle, NotificationsMenu, NotificationText, NotificationOptionsButton, MenuOption, AlertOptionsMenu } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Icon } from 'styles';
import { NotificationsDropdown } from './styles';
import { useVisible } from 'hooks';
import { NotificationSVG } from "components/svg";

export function Notifications(): JSX.Element {

    //const NotificationsDisplay = () => <FontAwesomeIcon icon={['fas', 'bell']} style={{ color: "#b8d0e1", fontSize: "1.4rem" }} />;
    
    const NotificationsToggle = React.forwardRef(({ children, onClick }, ref) => (
        <NotificationsMenu onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}>
            {children}      
        </NotificationsMenu>
    ));

    const handleNotificationClick = (e) => {
        console.log( e.target );
    }

    return (
        <NotificationsDropdown>
            <NotificationsDropdown.Toggle as={NotificationsToggle}>
                <NotificationSVG />
                <NotificationsAlert show={true} />
            </NotificationsDropdown.Toggle>
        
            <NotificationsDropdown.Menu>

                <DropdownItem eventKey="1" active={true} $read={false}>
                    <NotificationItem onClick={handleNotificationClick}>
                        <Icon className="bullish">
                            {
                                <FontAwesomeIcon icon={['far', 'chevron-up']} />
                                //<FontAwesomeIcon icon={['far', statusDisplay === 'bullish' ? 'chevron-up' : ( statusDisplay === 'bearish' ? 'chevron-down' : 'hand-spock' ) ]} />
                            }
                        </Icon>
                        <Flex direction="column">
                            <NotificationTitle>DKNG Alert</NotificationTitle>
                            <NotificationText>10% increase</NotificationText>
                        </Flex>
                        <NotificationOptions id={1} ticker="DKNG" type="ALERT" />
                    </NotificationItem>
                </DropdownItem>

                <DropdownItem eventKey="2" $read={true}>
                    <NotificationItem>
                        <Icon className="bearish">
                            {
                                <FontAwesomeIcon icon={['far', 'chevron-down']} />
                                //<FontAwesomeIcon icon={['far', statusDisplay === 'bullish' ? 'chevron-up' : ( statusDisplay === 'bearish' ? 'chevron-down' : 'hand-spock' ) ]} />
                            }
                        </Icon>
                        <Flex direction="column">
                            <NotificationTitle>AAPL Alert</NotificationTitle>
                            <NotificationText>25% decrease</NotificationText>
                        </Flex>
                        <NotificationOptions id={2} ticker="AAPL" type="ALERT" />
                    </NotificationItem>
                </DropdownItem>

                <DropdownItem eventKey="3" $read={true}>
                    <NotificationItem>
                        <Icon className="bearish">
                            {
                                <FontAwesomeIcon icon={['far', 'hand-spock']} />
                                //<FontAwesomeIcon icon={['far', statusDisplay === 'bullish' ? 'chevron-up' : ( statusDisplay === 'bearish' ? 'chevron-down' : 'hand-spock' ) ]} />
                            }
                        </Icon>
                        <Flex direction="column">
                            <NotificationTitle>New message</NotificationTitle>
                            <NotificationText>Hey! I just wanted to know if</NotificationText>
                        </Flex>
                        <NotificationOptions id={2} ticker="AAPL" type="MESSAGE" />
                    </NotificationItem>
                </DropdownItem>

            </NotificationsDropdown.Menu>
        </NotificationsDropdown>
    )
}

const NotificationOptions = ( props: { id: number, ticker: string, type: string }) => {

    const { ref, isVisible, setIsVisible } = useVisible( false );

    const handleClick = (e) => {
        // re renders home (or page) component?
        e.stopPropagation();
        setIsVisible( !isVisible );
    }

    const OptionsMenu = (): JSX.Element => {
        switch( props.type ) {
            case "ALERT": {
                return <AlertMenu {...props} show={isVisible} />;
            }
            case "MESSAGE": {
                return <AlertMenu {...props} show={isVisible} />;
            }
            default: {
                return <AlertMenu {...props} show={isVisible} />;
            }
        }
    }

    return (
        <React.Fragment>
            
            <NotificationOptionsButton onClick={handleClick} ref={ref as any}>
                <FontAwesomeIcon icon={['fas', 'cog']} />
            </NotificationOptionsButton>

            <OptionsMenu />

        </React.Fragment>
    )
}

const AlertMenu = ( props: { id: number, ticker: string, show: boolean } ) => {
    return (
        <AlertOptionsMenu show={props.show}>
            <ul>
                <li>
                    <MenuOption>Create new {props.ticker} alert</MenuOption>
                </li>
                <li>
                    <MenuOption>Remove notification</MenuOption>
                </li>
            </ul>
        </AlertOptionsMenu>
    );
}