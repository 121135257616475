import React, { useState, useRef, memo } from 'react';
import 'simplebar';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
//import { updateSearchResults } from '../../redux/selectors';
//import { search } from '../../redux/actions';
import { useVisible } from '../../hooks';
import { EmptyResult, SearchResults } from 'styles';
import { SearchResultProps } from 'store/app/types/props';
import { updateSearchResults } from 'store/app/selectors';
import { search } from 'store/app/actions/search';
import { SearchIcon, SearchInput } from 'components/layout/Header/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdateWatchlist } from 'store/app/types';
import { useTranslation } from 'react-i18next';
import { StyledForm } from './styles';

export function SearchForm(props: { updateWatchlist?: UpdateWatchlist; component?: string; }): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const staticUrl = useSelector((state: { app: { staticUrl: string } }) => state.app.staticUrl);
    const { list, lastFetch, isLoaded } = useSelector(updateSearchResults);

    const [searchValue, setSearchValue] = useState<string>('');
    const { ref, isVisible, setIsVisible } = useVisible(false);

    const searchBar = useRef<HTMLInputElement>();
    const updateWatchlistProp = props.updateWatchlist !== undefined;
    const updateWatchlist = updateWatchlistProp
        ? props.updateWatchlist
        : (id: number) => console.log('No updateWatchlist prop');
    // voir si utile?
    /*useEffect(() => {
        if ( searchForm.length ) {
            
        }
    }, [searchForm]);*/

    // find fix for "as any"?

    function handleSearchForm(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value);
        dispatch(search(event.target.value, lastFetch));

        // ref instead?
        setIsVisible(true);
    }

    function resetSearch() {
        setSearchValue('');
        dispatch(search('', lastFetch));

        // ref instead?
        setIsVisible(false);
    }

    function handleFocusIfNotEmpty() {
        if (searchValue !== '') {
            setIsVisible(true);
        }
    }

    return (
        <StyledForm
            className="my-2 my-md-0 order-md-3"
            autoComplete="off"
            ref={ref as any}
            >
            <SearchIcon focus={false}>
                <FontAwesomeIcon icon={['far', 'search']} />
            </SearchIcon>

            <SearchInput
                ref={searchBar as any}
                className="form-control"
                type="text"
                placeholder={t('search.placeholder')}
                autoComplete="off"
                aria-label={t('search.label')}
                onChange={handleSearchForm}
                onFocus={handleFocusIfNotEmpty}
                value={searchValue}
                />

            <SearchResults
                component={props.component}
                visible={isVisible}
                staticUrl={staticUrl}
                data-simplebar
                >
                <ul>
                    {isLoaded && list.length === 0 ? (
                        <Result empty={true} query={searchValue} resetSearch={resetSearch} />
                    ) : (
                        list
                            .slice(0, 10)
                            .map(
                                (item): JSX.Element => (
                                    <Result
                                        key={item.ticker}
                                        id={parseInt(item.id)}
                                        ticker={item.ticker}
                                        name={item.name}
                                        hasLink={!updateWatchlistProp}
                                        updateWatchlist={updateWatchlist}
                                        resetSearch={resetSearch}
                                        empty={false}
                                        query={searchValue}
                                    />
                                )
                            )
                    )}
                </ul>
            </SearchResults>
        </StyledForm>
    );
}

// (p, n) => p.ticker === n.ticker ? true : false même chose que function isEqual( prev, next ) ...
const Result = memo<SearchResultProps>(SearchResult, (p, n) =>
    p.ticker === n.ticker ? true : false
);

function SearchResult(props: SearchResultProps): JSX.Element {
    const message = props.query.length === 1 ? 'Specify at least 2 characters' : 'No results';
    const empty = props.empty;
    const id = props.id ? props.id : 0;
    const ticker = props.ticker ? props.ticker : '';
    const name = props.name ? props.name : '';
    const handleError = (id: number) =>
        console.log('Function "onClick" error in Search.tsx. No function passed as prop?');

    const attClickLink = !empty && { onClick: props.resetSearch };
    const onClickAddButton = (id: number) => {
        if (props.updateWatchlist && props.resetSearch) {
            props.updateWatchlist(id, ticker, name, 'ADD');
            props.resetSearch();
        } else {
            handleError(id);
        }
    };

    if (empty) {
        return (
            <EmptyResult>
                <span>{message}</span>
            </EmptyResult>
        );
    }

    return props.hasLink ? (
        <li>
            <Link to={'/symbol/' + props.ticker} {...attClickLink}>
                <span>{props.ticker}</span>
                <span>{props.name}</span>
            </Link>
        </li>
    ) : (
        <li>
            <Button onClick={() => onClickAddButton(id)}>
                <span>{props.ticker}</span>
                <span>{props.name}</span>
                <FontAwesomeIcon icon={['fal', 'plus']} size="2x" />
            </Button>
        </li>
    );
}
