import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sparkline } from "components/charts/Sparkline";
import { Button } from "react-bootstrap";
import { StatusDisplayProps } from "store/app/types/props";

export const ModalClose = ( props: { close: () => void }): JSX.Element => {
    return (
        <Button onClick={props.close} style={{position:"absolute", top:"10px", right:"10px", zIndex:999}}>
            <FontAwesomeIcon icon={["fal", "times"]} style={{fontSize: "1.5rem"}} />
        </Button>
    );
}

export const StatusDisplay = (props: StatusDisplayProps) => {
    const { data, status, hasData, showStatusIcon } = props;

    if (!hasData) 
        return <></>;       

    if (showStatusIcon) 
        return <></>;

    return (
        <div>
            <Sparkline 
                status={status} 
                data={data.map((value) => value)} 
                />
        </div>
    );
};