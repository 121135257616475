export const lightTheme: ThemeType = {
    body: '#FFFFFF',
    text: '#363537',
    textActive: '#363537',
    toggleBorder: '#6B8096',
    background: '#999',
    border: '#E1E1E1',

    bullish: 'rgb(86, 183, 112)',
    bearish: 'rgb(245, 132, 117)',

    light: '#CCCCCC',
    lighter: 'rgba( 255,255,255, 0.1 )',
    lightest: '#F0F0F1',
    dark: 'rgba( 0,0,0, 0.2 )',
    darker: '#E1E1E1',
    bgModal: '#EAEAEA',
    pixelPopDark: 'transparent',
    pixelPopLight: '#E1E1E1',
    shadow: {
        small: '0px 1px 1px rgba(0,0,0, 0.5)',
        big: '0px 3px 8px rgba(0,0,0, 0.5)',
    },
    //border-tooltip: '#4F6270',
    header: {
        background: '#FFFFFF',
        shadow: '0px 1px 3px rgba(0,0,0, 0.2)',
        search: {
            input: '#FFFFFF',
            border: '#E1E1E1',
        },
        featured: {
            background: "#253A49",
            item: {
                background: "transparent",
                text: "#F8F8F8",
                border: "rgba( 255,255,255, 0.1 )"
            }
        },
        dropdown: {
            background: '#FFFFFF',
            active: '#EAEAEA',
            hover: '#F8F8F8'
        },
        marquee: {
            gradientRGB: "255,255,255",
        }
    },
    footer: {
        background: "#EAEAEA",
    },
    fonts: {
        varela: '\'Varela Round\', sans-serif',
        openSans: '\'Open Sans\', sans-serif',
        number: '\'Source Code Pro\', sans-serif'
    },
    handle: 'rgba( 0,0,0, 0.2 )',
    button: {
        bgSelected: 'rgba( 0,0,0, 0.05 )',
        borderSelected: '#00A2FF',
        icon: {
            'watching': {
                color: '#4F6270',
                selected: '#e3c707',
            },
            'alert': {
                color: '#4F6270',
                selected: '#00C6B1',
            },
            'theme': {
                color: '#CCCCCC',
                selected: '#CCCCCC',
            },
        }
    },
    watchlist: {
        mode: {
            normal: 'transparent',
            edit: '#325f79'
        },
        dropdown: {
            background: '#FFFFFF',
            active: '#EAEAEA',
            hover: '#F8F8F8'
        }
    },
    marketIndicator: {
        live: 'rgba(51, 217, 178, 1)',
        trading: 'rgba(255, 82, 82, 1)',
        offline: '#64707A'
    }
}

const bodyBg = "#141f27";
const darkText = "#ABB8C3";
const darkerBg = "#10191f";
const darkBorder = "#1e2f3c";
// change because not used as header background anymore

export const darkTheme: ThemeType = {
    body: '#141f27',
    text: darkText,
    textActive: '#F8F8F8',
    toggleBorder: '#6B8096',
    background: '#999',
    border: darkBorder,

    bullish: 'rgb(86, 183, 112)',
    bearish: 'rgb(245, 132, 117)',

    light: '#CCCCCC',
    lighter: 'rgba( 255,255,255, 0.2 )',
    lightest: '#F0F0F1',
    dark: 'rgba( 0,0,0, 0.2 )',
    darker: darkerBg,
    bgModal: '#1a2831',
    pixelPopDark: '#0d1419',
    pixelPopLight: darkBorder,
    shadow: {
        small: '0px 1px 1px rgba(0,0,0, 0.5)',
        big: '0px 3px 8px rgba(0,0,0, 0.5)',
    },
    header: {
        background: "transparent",
        shadow: '0px 1px 3px rgba(0,0,0, 0.2)',
        search: {
            input: bodyBg,
            border: "#2f404c",
        },
        featured: {
            background: darkerBg,
            item: {
                background: "transparent",
                text: darkText,
                border: darkBorder
            }
        },
        dropdown: {
            //background: "#1e2f3c",
            background: "#22313e",
            active: "rgba(255,255,255,0.075)",
            hover: darkerBg
        },
        marquee: {
            gradientRGB: "20,31,39",
        }
    },
    footer: {
        background: darkerBg
    },
    fonts: {
        varela: '\'Varela Round\', sans-serif',
        openSans: '\'Open Sans\', sans-serif',
        number: '\'Source Code Pro\', sans-serif'
    },
    handle: 'rgba( 255,255,255, 0.2 )',
    button: {
        bgSelected: 'rgba( 255,255,255, 0.05 )',
        borderSelected: '#00A2FF',
        icon: {
            'watching': {
                color: '#4F6270',
                selected: '#e3c707',
            },
            'alert': {
                color: '#4F6270',
                selected: '#00C6B1',
            },
            'theme': {
                color: '#e7e698',
                selected: '#e7e698',
            },
        }
    },
    watchlist: {
        mode: {
            normal: 'transparent',
            edit: '#325f79'
        },
        dropdown: {
            background: "#0d1419",
            active: "rgba(255,255,255,0.075)",
            hover: '#18252f'
        }
    },
    marketIndicator: {
        live: 'rgba(51, 217, 178, 1)',
        trading: 'rgba(255, 82, 82, 1)',
        offline: '#64707A'
    }
}

export const ActiveTheme = darkTheme;

// semi column instead of comma
export type ThemeType = {
    body: string,
    text: string,
    textActive: string,
    toggleBorder: string,
    background: string,
    border: string,

    bullish: string,
    bearish: string,

    light: string,
    lighter: string,
    lightest: string,
    dark: string,
    darker: string,
    bgModal: string,
    pixelPopDark: string,
    pixelPopLight: string,
    shadow: {
        small: string,
        big: string,
    },
    header: {
        background: string,
        shadow: string,
        search: {
            input: string,
            border: string,
        },
        featured: {
            background: string,
            item: {
                background: string,
                text: string,
                border: string
            }
        },
        dropdown: {
            background: string,
            active: string,
            hover: string
        },
        marquee: {
            gradientRGB: string,
        }
    },
    footer: {
        background: string;
    },
    fonts: {
        varela: string,
        openSans: string,
        number: string,
    },
    handle: string,
    button: {
        bgSelected: string,
        borderSelected: string,
        icon: {
            [key: string]: {
                color: string,
                selected: string,
            }
        }
    },
    watchlist: {
        mode: {
            [key: string]: string
        },
        dropdown: {
            background: string,
            active: string,
            hover: string
        }
    },
    marketIndicator: {
        live: string,
        trading: string,
        offline: string
    }
};