import { produce } from 'immer';
import update from 'immutability-helper';
import { AdminState } from '../types/state';
import { ActionTypes } from '../actions/admin';
import { Action } from '../types';
import { ErrorInit } from '../init';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<AdminState>({
    stats: {
        list: [],
        selected: [],
        isFetching: false,
        isLoaded: false,
    
        error: ErrorInit
    },

    // check if global error necessary
    error: ErrorInit
});

export default ( state: AdminState = initialState, action: Action ): AdminState =>
    produce(state, (draft) => {
        switch( action.type ) { 

            

            // temporary
            case ActionTypes.LOAD_STATS: {
                draft.stats.isLoaded = false;
                draft.stats.isFetching = true;

                return draft;
            }

            case ActionTypes.LOAD_STATS_SUCCESS: {
                draft.stats.list = action.payload;
                draft.stats.isFetching = false;
                draft.stats.isLoaded = true;

                return draft;
            }

            case ActionTypes.LOAD_STATS_FAIL: {
                draft.stats.error = {
                    status: true,
                    type: "Error",
                    message: action.payload
                };

                return draft;
            }

            case ActionTypes.ADD_TICKER: {
                const tickerToAdd = action.payload;
                const tempSelected = [...draft.stats.selected];
                const findIndex = draft.stats.selected.findIndex( item => item.id === tickerToAdd.id);
                const exists = findIndex !== -1;

                if( !exists ) {
                    const updatedSelectedList = update([...tempSelected], {$push: [tickerToAdd]});
                    draft.stats.selected = updatedSelectedList;
                }

                return draft;
            }
              
            

            default:
              return draft;
        }
    });
