import { produce } from 'immer';
import { PostState } from '../types/state';
import { ActionTypes } from '../actions/post';
import { Action } from '../types';
import { ErrorInit, SeriesInit, SeriesItemInit } from '../init';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<PostState>({
    dashboard: {
        title: "My Draftkings DD",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi blandit, lorem sed efficitur facilisis, velit urna tempor lorem, sed venenatis lorem nisl nec ipsum. Vivamus at sem dolor. Donec ut odio auctor, euismod enim id, porttitor mi. In eget magna ut nibh fringilla consectetur a sed libero. Nulla facilisi.",
        image: "https://logo.stocklight.com/NASDAQ/DKNG.png",
        items: [],
        isEditing: false,
        settings: {
            logo: {
                background: "transparent",
            }
        }
    },
    panel: {
        selected: null,
        title: "",
        text: "",
        data: [], // use series instead?
        series: [SeriesInit],
        isEditing: false,
        settings: {
            textPosition: "top",
            titleBackground: false,
            panelBackground: false,
            timeSeries: false,
            displayPercent: false,
            colorPalette: {
                isFetching: false,
                colors: [],
                palettes: []
            }
        }
    },
    error: ErrorInit
});
  
export default ( state: PostState = initialState, action: Action ): PostState =>
    produce(state, (draft) => {

        switch( action.type ) {

            case ActionTypes.ADD_TO_DASHBOARD: {
                const id = action.payload.id;
                const size = action.payload.size;
                const newItem = { 
                    id: `panel${id}`, 
                    type: draft.panel.selected, 
                    title: draft.panel.title, 
                    text: draft.panel.text,
                    size: size,
                    data: draft.panel.series,
                    settings: draft.panel.settings
                };

                draft.dashboard = { ...draft.dashboard, items: [...draft.dashboard.items, newItem ] };
                // must reset panel state
                draft.panel = { ...initialState.panel };

                return draft;
            }

            case ActionTypes.SET_DASHBOARD: {
                const dashboard = action.payload;
                draft = { ...draft, dashboard: { ...draft.dashboard, ...dashboard }};

                return draft;
            }

            case ActionTypes.SET_SELECTED: {
                draft = { ...draft, panel: { ...draft.panel, selected: action.payload }};

                return draft;
            }

            case ActionTypes.SET_PANEL_INFO: {
                const panelInfo = action.payload;
                draft = { ...draft, panel: { ...draft.panel, ...panelInfo }};

                return draft;
            }

            case ActionTypes.SET_CHART_DATA: {
                const newPanelState = action.payload;
                draft = { ...draft, panel: { ...newPanelState }};

                return draft;
            }

            case ActionTypes.SET_DASHBOARD_STATUS: {
                draft.dashboard = { ...draft.dashboard, isEditing: action.payload };

                return draft;
            }

            case ActionTypes.SET_PANEL_STATUS: {
                draft.panel = { ...draft.panel, isEditing: action.payload };

                return draft;
            }

            case ActionTypes.GET_COLOR_PALETTE: {

                return draft;
            }

            case ActionTypes.SET_COLOR_PALETTE: {
                const tResult = action.payload;
                const returnType = (typeof tResult[0] === "string") ? "palettes" : "colors"; // if typeof == string, that means it's a palette name
                const data = action.payload;
                
                draft.panel.settings.colorPalette = { ...draft.panel.settings.colorPalette, isFetching: false, [returnType]: data };

                return draft;
            }
              
            default: {
                return draft;
            }
        }
    });