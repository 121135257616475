import React, { useEffect, useRef } from "react";
import sparkline from "@fnando/sparkline";

export type SparklineProps = {
    close?: boolean,
    width?: number,
    height?: number,
    stroke?: number,
    status?: "bullish" | "bearish" | "",
    previousClose?: string | number, // get as number instead, remove parseFloat/ type check from line 19
    data: number[]
}

export function Sparkline( props: SparklineProps ): JSX.Element {

    const sparkRef = useRef<SVGSVGElement>(null);
    const data = props.data;
    const close = props.close ? true : false;
    const previousClose = typeof props.previousClose === 'string' ? parseFloat( props.previousClose ) : props.previousClose; // type check
    const checkMin = (previousClose && previousClose < Math.min( ...data )) ? previousClose : Math.min( ...data );
    const checkMax = (previousClose && previousClose > Math.max( ...data )) ? previousClose : Math.max( ...data );
    const sparkData = data.map( r => r - checkMin );
    //const sparkDataMin = Math.min( ...sparkData );
    const sparkDataMax = Math.max( ...sparkData );
    //const sparkClose = previousClose ? ( sparkDataMax * previousClose ) / checkMax : null;
    const width = props.width ? props.width : 75;
    const height = props.height ? props.height : 25;

    const maxHeight = checkMax - checkMin;
    const diff = previousClose ? checkMax - previousClose : 0;
    const percent = ((diff*100) / maxHeight);
    const closeLineTop = (height*percent/100)+'px';

    const strokeWidth = props.stroke ? props.stroke : 2;
    
    useEffect( () => {
        if( sparkRef && sparkRef.current ) {
            sparkline( sparkRef.current, sparkData );
        }
    }, [data.length]);

    return (
        <div className="sparklineContainer">
            {
                close ? <div className="sparklineClose" style={{ top: closeLineTop }} /> : null
            }
            <svg className={"sparkline "+props.status} width={width} height={height} strokeWidth={strokeWidth} ref={sparkRef}></svg>
        </div>
    );
}