/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { RootState } from 'store';
import { AppState, SearchState, SettingsState, StocksState, UserState, WatchlistState } from './types/state';
import { Locale, ITickerData, ITickerListData, IWatchlists, WatchlistDataSelector, SearchQueryResultData, TickerBarListData } from './types';
import { TRENDING } from 'components/utils/const';

// States
export const app = (state: { app: AppState }): AppState => state.app;
export const settings = (state: { settings: SettingsState }): SettingsState => state.settings;
export const user = (state: { user: UserState }): UserState => state.user;
export const stocks = ( state: { stocks: StocksState }): StocksState => state.stocks;
export const watchlist = ( state: { watchlist: WatchlistState }): WatchlistState => state.watchlist;

export const watchlists  = ( state: { watchlist: WatchlistState }) => state.watchlist.watchlists;
export const watchlistLive = ( state: { watchlist: WatchlistState }) => state.watchlist.live;
export const watchlistFeatured = ( state: { watchlist: WatchlistState }) => state.watchlist.featured;
export const watchlistTrending = ( state: { watchlist: WatchlistState }) => state.watchlist.trending;
export const featuredSettings = ( state: { settings: SettingsState }) => state.settings.featured;

// 

const getTicker = ( state: RootState, props: { ticker: string } ) => props.ticker;

const getKey = ( state: RootState, key: number ) => key;

const getCompanyData = ( state: StocksState, ticker: string ) => state.companyData.filter( ( item ) => item.ticker === ticker );

const getCompanyFinancials = ( state: { stocks: StocksState }, { type, symbol }: { type: string, symbol: string } ) => {
  const companyData = state.stocks.companyData.filter( ( item ) => item.ticker === symbol )[0];
  return { 
    data: companyData.financials, 
    isLoaded: companyData.financials.isLoaded 
  };
};

// ajouter check des autres params: start, end, interval (ou juste tagger comme étant données "overview" sans plus de tri)
const getIntraday = ( state: StocksState, ticker: string ) => state.intraday.filter( ( item ) => item.ticker === ticker );

// ajouter check des autres params: start, end, interval (ou juste tagger comme étant données "overview" sans plus de tri)
const getHistorical = ( state: StocksState, props: { ticker: string, range: string } ) => state.historical[props.range];

const getAllWatchlists = ( state: { watchlist: WatchlistState } ) => state.watchlist.watchlists.data;

const getTickerListDisplayValue = ( state: { app: AppState } ) => state.app.ticker.display;

const getSelectedTickerList = ( state: { app: AppState } ) => state.app.ticker.selected;

const getTickerListLength = ( state: { app: AppState } ) => state.app.ticker.selected.length;

// check if necessary (watchlistFeatured)
const getFeaturedList = (state: { watchlist: WatchlistState }) => {
  /*
  const list = state.watchlist.featured.list;
  const liveData = state.watchlist.live;
  const updatedList: ITickerData[] = [];
  if( list ) {
    for( let i=0; i<list.length; i++ ) {
      const item = liveData.find( item => item.ticker === list[i].symbol );
      if( item ) { updatedList.push( item ); }
      
      //updatedList.push({
      //      ...list[i], 
      //      ...(liveData.find( item => item.ticker === list[i].symbol ))
      //    });
          
    }
  }
  */
  return state.watchlist.featured.list;
};

// old selector
//const getTickerList = ( state: RootState, props?: string[] | number[] | null ): ITickerListData[] => {

const getTickerList = ( state: RootState, props?: string[] | number[] | null ): string | number => {

  // if has props, get first item, else get list from state.
  const selectedList = props ? props[0] : state.app.ticker.selected.id;

  return selectedList;
  /*
  let watchlist;
  const selectedList = props ? props[0] : state.app.ticker.selected.id;
  const liveData = [ ...state.watchlist.live ];
  const country = state.settings.country;
  const updatedList = [];


  if( selectedList === 'TRENDING' ) { // add INDEX
    const trendingList = state.watchlist.trending[country].list;
    watchlist = { 
      id: 0, 
      name: 'Trending '+country, 
      list: trendingList 
    };
  } else {
    const watchlists = { ...state.watchlist.watchlists };
    watchlist = watchlists.data.filter( item => selectedList === item.id )[0];
  }

  if( watchlist ) {
    const list = watchlist.list;
    for( let i=0; i<list.length; i++ ) {
          updatedList.push({
            ...list[i], 
            ...(liveData.find( item => item.ticker === list[i].symbol )),
            // override watching key set by live data. check to add "watching" to live data?
            watching: list[i].watching,
          });
    }
  }
  return updatedList;
  */
}

const getTickerListStatus = ( state: RootState, props?: string[] | number[] | null ) => {
  /*
  let isLoadedList: boolean;
  let isLoadedData: boolean;
  const selectedList = props ? props[0] : state.app.ticker.selected.id;
  const country = state.settings.country;
  if( selectedList === 'TRENDING' ) {
    isLoadedList = state.watchlist.trending[country].list.length !== 0;
    isLoadedList = state.watchlist.trending[country].isLoaded;
    isLoadedData = isLoadedList && !state.watchlist.trending[country].isFetching;
  } else {
    // check also isFetching?
      isLoadedList = state.watchlist.watchlists.isLoaded;
      isLoadedData = state.watchlist.watchlists.data.filter( item => selectedList === item.id )[0].isLoaded;
  }
  
  return { isLoadedList: isLoadedList, isLoadedData: isLoadedData };
  */
}

const getSelectedWatchlist = ( state: RootState ) => {
  /*
  const liveData = [...state.watchlist.live];
  const selectedList = state.watchlist.watchlists.selected;
  const watchlists = [...state.watchlist.watchlists.data];
  const selectedWatchlist = watchlists.filter( item => selectedList === item.id )[0];
  const updatedList = [];
  const returnWatchlist: WatchlistDataSelector = { id: null, name: null, list: [] };

  if( selectedWatchlist ) {
    const list = [...selectedWatchlist.list];

    for(let i=0; i<list.length; i++) {
      const findIndex = liveData.findIndex( item => item.ticker === list[i].symbol );
      if( findIndex !== -1 ) { 
        updatedList.push( liveData[findIndex] ); 
      }
    }

    returnWatchlist.id = selectedWatchlist.id;
    returnWatchlist.name = selectedWatchlist.name;
    returnWatchlist.list = updatedList;

    console.log( 'returnWatchlist');
    console.log( returnWatchlist ); 
  }

  return returnWatchlist;
  */
}
    
const getWatchlistStatus = ( state: RootState ) => {
    let watchlist: IWatchlists;
    let isLoadedList = false;
    let isLoadedData = false;
    const selectedList = state.watchlist.watchlists.selected;
    const watchlists = [...state.watchlist.watchlists.data];
    if( watchlists.length ) {
      watchlist = watchlists.filter( item => selectedList === item.id )[0];
      if( watchlist ) {
        isLoadedList = watchlist.list.length !== 0;
        isLoadedData = watchlist.isLoaded;
      }
    }
    return { isLoadedList: isLoadedList, isLoadedData: isLoadedData };
}
    
const getDisplayValue = (state: { app: AppState }) => state.app.watchlist.display;

/* Search results selector */
const getResults = (state: { search: SearchState }) => state.search;

// Selectors

  // App

  // temp
  export const getLocale = createSelector( [app], (app): Locale => app.locale );

  // change from settings to app...
  export const checkCurrentPage = createSelector( [app], (app): string => app.current.page );

  // User
  export const checkUserState = createSelector( [user], (user): UserState => user );
    
  // Settings or watchlist?
  export const checkFeaturedListState = createSelector(
    [ watchlistLive, watchlistFeatured, featuredSettings],
    ( liveState, featuredState, settingsState ) => {

      console.log("Output selector running (checkFeaturedListState)");

      const liveData = [...liveState];
      const updatedList: ITickerData[] = [];
      const featuredList = [...featuredState.list];

      if( featuredList ) {
        for( let i=0; i<featuredList.length; i++ ) {
          const item = liveData.find( item => item.ticker === featuredList[i].symbol );
          if( item ) { 
            updatedList.push( item ); 
          }    
        }
      }
      
      return { 
        settings: settingsState,
        isFetching: featuredState.isFetching, 
        isLoaded: featuredState.isLoaded, 
        list: updatedList 
      };
    }
  );

  // Company
  export const checkCompanyDataState = createSelector( [getCompanyData], (companyData) => {
    console.log("Output selector running (checkCompanyDataState)");
    return companyData 
  });

  export const checkIntradayState = createSelector( [getIntraday], (intraday) => {
    console.log("Output selector running (checkIntradayState)");
    return intraday 
  });

  export const checkHistoricalState = createSelector( [getHistorical, getTicker], (history, ticker) => {
    console.log("Output selector running (checkHistoricalState)");
    const historical = [...history];
    return historical.filter( item => item.ticker === ticker ); 
  });

  export const checkWatchingState = createSelector( [getAllWatchlists, getTicker], (watchlists, ticker) => {
    console.log("Output selector running (checkWatchingState)");
      let watching: boolean = false;
      for( let i = 0; i < watchlists.length; i++ ) {
        watching = watchlists[i].list.some( item => item.symbol === ticker );
        if( watching ) { break; }
      }
      return watching
  });

  export const checkFetchStatus = createSelector( [stocks], (stocks) => stocks.isFetching );

  export const checkCompanyFinancialsState = createSelector( [ getCompanyFinancials ], ({ data, isLoaded }) => ({ data: data, isLoaded: isLoaded }) );

  // Settings  
  export const checkTickerListState = createSelector(
    [getTickerList, getTickerListDisplayValue, getSelectedTickerList, watchlists, watchlistLive, watchlistTrending, settings],
    (tickerList, display, selected, watchlists, liveState, trendingState, settingsState) => {
      
      console.log("Output selector running (checkTickerListState)");

      let watchlist;
      let isLoadedList: boolean;
      let isLoadedData: boolean;

      const selectedList = tickerList;
      const liveData = [...liveState];
      const country = settingsState.country;
      const updatedList: TickerBarListData[] = [];
      const hasError = trendingState[country].error.status;

      if( selectedList === TRENDING ) { // add INDEX
        const trendingList = trendingState[country].list;
        watchlist = { 
          id: 0, 
          name: 'Trending '+country, 
          list: trendingList 
        };

        //isLoadedList = trendingState[country].list.length !== 0;
        isLoadedList = trendingState[country].isLoaded;
        //isLoadedData = isLoadedList && !trendingState[country].isFetching;
        isLoadedData = trendingState[country].isLoadedData;
      } else {
        watchlist = watchlists.data.filter( item => selectedList === item.id )[0];

        // check also isFetching?
          isLoadedList = watchlists.isLoaded;
          isLoadedData = watchlists.data.filter( item => selectedList === item.id )[0].isLoaded;
      }

      if( watchlist ) {
        const list = watchlist.list;
        for( let i=0; i<list.length; i++ ) {
              updatedList.push({
                ...list[i], 
                ...(liveData.find( item => item.ticker === list[i].symbol )),
                // override watching key set by live data. check to add "watching" to live data?
                watching: list[i].watching,
              });
        }
      }
    
      return { 
        list: updatedList, 
        isLoadedList: isLoadedList, 
        isLoadedData: isLoadedData, 
        displayValue: display, 
        selectedList: selected,
        hasError: hasError
      };
    }
  );

  export const checkTickerListLength = createSelector(
    [ getTickerListLength ], (length) => length
  );

  // Watchlist
  export const checkLivePrice = createSelector( [(state: WatchlistState) => state, getTicker], (watchlist, ticker) => {
      console.log("Output selector running (checkLivePrice)");
      const liveData = [...watchlist.live];
      const price = liveData.filter( item => item.symbol === ticker );
      return (price.length !== 0) ? price[0] : null;
    }
  );

  export const checkWatchlistState = createSelector(
    [ watchlists, watchlistLive, watchlistTrending, getWatchlistStatus, getDisplayValue],
    ( watchlistsState, liveState, trendingState, isLoaded, display ) => {

      const liveData = [...liveState];
      const selectedList = watchlistsState.selected;
      const trendingList = [...trendingState["US"].list];
      const watchlists = [...watchlistsState.data];
      const selectedWatchlist = watchlists.filter( item => selectedList === item.id )[0];
      const updatedList = [];
      const returnWatchlist: WatchlistDataSelector = { id: null, name: null, list: [] };

      if( selectedWatchlist ) {
        const list = [...selectedWatchlist.list];

        for(let i=0; i<list.length; i++) {
          const findIndex = liveData.findIndex( item => item.ticker === list[i].symbol );
          if( findIndex !== -1 ) { 
            // after adding to watchlist, item in live state doesn't have id? so add it from watchlist.
            const foundItem = { ...liveData[findIndex], id: list[i].id };
            const tickerIsTrending = trendingList.findIndex(item => item.symbol === foundItem.ticker);
            const updatedItem = tickerIsTrending !== -1 ? { ...foundItem, isTrending: true } : foundItem;
            updatedList.push( updatedItem ); 
          }
        }

        returnWatchlist.id = selectedWatchlist.id;
        returnWatchlist.name = selectedWatchlist.name;
        returnWatchlist.list = updatedList;
      }
      
      return { 
        watchlist: returnWatchlist, 
        isLoadedList: isLoaded.isLoadedList, 
        isLoadedData: isLoaded.isLoadedData, 
        isEmpty: returnWatchlist.list.length === 0, 
        displayValue: display 
      };
    }
  );

  export const checkUserWatchlists = createSelector(
    [getAllWatchlists],
    ( watchlists ) => watchlists.map( (watchlist) => watchlist )
  );

  export const checkWatchlistStatus = createSelector(
    [getAllWatchlists, getKey],
    ( watchlists, key ) => watchlists.filter( (watchlist) => watchlist.id === key )
  );

  // Search

  export const updateSearchResults = createSelector(
    [getResults],
    (results) => {
        const list = results.searchResults;
        const fetch = results.lastFetch;
        const query = results.searchQuery.toLowerCase();
        const loaded = results.searchResultsLoaded;

        // doesn't exist? check
        //const focused = results.isFocused

        const fRes: SearchQueryResultData[] = []
    
        if( query.length >= 2 ) {
          list.filter( (e) => Object.keys(e).some( (a) => e[a] ? e[a].toLowerCase().includes( query ) : null )).map( item => fRes.push( item ));
          //item.ticker.startsWith( query )       
        }
    
        return {
          list: fRes,
          lastFetch: fetch,
          isLoaded: loaded,
          isLoading: query.length >=2 && !loaded,
          //isFocused: focused
        }
    }
  );