import auth0, { Auth0DecodedHash } from 'auth0-js';
import params from 'auth.config.json';
import { getAccessToken, getTimestamp } from 'helpers';
import { UserType } from 'store/app/types';

export default class Auth {

  webAuth0 = new auth0.WebAuth({
    domain: params.domain,
    clientID: params.clientId,
    // audience: `https://${params.domain}/userinfo`,
    audience: params.apiAudience,
    redirectUri: params.callbackUrl,
    scope: params.scope,
    responseType: 'token id_token'
  });

  auth0 = new auth0.Authentication({
    domain: params.domain,
    clientID: params.clientId,
    // audience: `https://${params.domain}/userinfo`,
    audience: params.apiAudience,
    redirectUri: params.callbackUrl,
    scope: params.scope,
    responseType: 'token id_token'
  });

  constructor() {
    this.signin = this.signin.bind(this);
    this.signup = this.signup.bind(this);
    this.signout = this.signout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.setUserInfo = this.setUserInfo.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.setSession = this.setSession.bind(this);
    this.getSession = this.getSession.bind(this);
  }

  signin(username: string, password: string) {
    /*this.auth0.authorize({
      audience: params.apiAudience,
      scope: params.scope,
      responseType: 'token id_token',
      redirectUri: params.callbackUrl
    });*/

    return new Promise((resolve, reject) => {
      this.auth0.login(
        { realm: params.realm, username, password },
        (err, authResult) => {
          if (err) {
            console.log(err);
            return reject(err);
          }
          this.setSession(authResult);
          this.auth0.userInfo(authResult.accessToken, function(err, user) {
            if (err) return console.error(err);
            localStorage.setItem('email', String(user.email));
            localStorage.setItem('avatar', user.picture);
            localStorage.setItem('lastLogin', String(getTimestamp(user.updated_at)));
            localStorage.setItem('verified', user.email_verified === true ? "true" : "false");
            return resolve( user );
          });
        }
      );
    })
  }

  signup( username: string, email: string, password: string ) {
    return new Promise((resolve, reject) => {
      this.webAuth0.signup({
        connection: 'Username-Password-Authentication',
        username: username,
        email: email,
        password: password,
        //user_metadata: { plan: 'silver', team_id: 'a111' }
      }, (err) => {
        if (err) return console.error(err);
        //this.signin( email, password )
        //  .then( () => resolve({ result: 'Successfully signed up as ' + username + '.' }) );
        resolve({ result: 'Successfully signed up as ' + username + '.' });
      });
    });
  }

  signout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('avatar');
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('verified');
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.webAuth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {

          this.setSession( authResult );
          console.log( 'HANDLE AUTH' );
          
          return resolve(true);
        } else if (err) {
          console.log(err);
          return reject(err);
        }
      });
    });
  }

  fetchUserInfo() {
    return new Promise((resolve, reject) => {
        const token = getAccessToken();
          if (token) {
            this.auth0.userInfo(token, function(err, user) {
              return resolve( user );
            });
          } else {
            console.log('No authResult');
          }
      });
  }

  setSession( authResult: Auth0DecodedHash ) {
    // Set the time that the access token will expire at
    if( authResult ) {
      const expiresIn = authResult.expiresIn ? authResult.expiresIn : 1000;
      let expiresAt = JSON.stringify((expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('access_token', String(authResult.accessToken));
      localStorage.setItem('id_token', String(authResult.idToken));
      localStorage.setItem('expires_at', expiresAt);
    }
  }

  getSession() {
    // Set the time that the access token will expire at
    return {
      access: localStorage.getItem('access_token'),
      id: localStorage.getItem('id_token')
    };
  }

  setUserInfo( user: UserType ) {
    localStorage.setItem('id', String(user.id));
    localStorage.setItem('username', String(user.username));
  }

  getUserInfo() {
    return {
      id: localStorage.getItem('id'), 
      username:localStorage.getItem('username'),
      email: localStorage.getItem('email'),
      avatar: localStorage.getItem('avatar'),
      lastLogin: localStorage.getItem('lastLogin'),
      verified: localStorage.getItem('verified'),
    }
  }

  isLoaded() {
    if( localStorage.getItem('id') === null ) {
      return false;
    }
    return true;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expire = localStorage.getItem('expires_at');
    if( !expire ) {
      return false;
    }
    return new Date().getTime() < JSON.parse( expire );
  }

}
