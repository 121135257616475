import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDecimals } from 'helpers';

export const DayRange = ( props: { currentPrice: number | null, low: number, high: number} ): JSX.Element => {

    const { currentPrice, low, high } = props;
    // temp
    if( currentPrice === null ) { 
        return <div>Error, no price</div>; 
    }

    var rangeLow = low;
    var lastPrice = currentPrice;
    var rangeHigh = high;
    // if range changes, force update of company stats
    if( lastPrice < rangeLow ) {
        rangeLow = lastPrice;
    } else if( lastPrice > rangeHigh ) {
        rangeHigh = lastPrice;
    }
    var diff = lastPrice - rangeLow;
    var range = rangeHigh - rangeLow;
    var width = Math.round( ( diff * 100 ) / range );

    return (
        <div className="range">
            <div className="header">
                <div>Day Low</div>
                <div>Day High</div>
            </div>
            <div className="rangeContainer">
                <div className="priceLow">{formatDecimals( rangeLow )}</div>
                <div className="bar">
                    <div id="now" style={{width: width+'%'}}>
                        <FontAwesomeIcon icon={['fas', 'caret-up' ]} />
                    </div>
                </div>
                <div className="priceHigh">{formatDecimals( rangeHigh )}</div>
            </div>
        </div>
    );
}

export const WeekRange = ( props: { currentPrice: number | null, low: number, high: number} ) => {

    const { currentPrice, low, high } = props;
    // temp
    if( currentPrice === null || low === 0 || high === 0 ) { 
        return <div>Error, no price</div>; 
    }

    var rangeLow = low;
    var lastPrice = currentPrice;
    var rangeHigh = high;

    // if range changes, force update of company stats
    if( lastPrice < rangeLow ) {
        rangeLow = lastPrice;
    } else if( lastPrice > rangeHigh ) {
        rangeHigh = lastPrice;
    }

    var diff = lastPrice - rangeLow;
    var range = rangeHigh - rangeLow;
    var width = Math.round( ( diff * 100 ) / range )

    return (
        <div className="range">
            <div className="header">
                <div>52 Wk Low</div>
                <div>52 Wk High</div>
            </div>
            <div className="rangeContainer">
                <div className="priceLow">{formatDecimals( rangeLow )}</div>
                <div className="bar">
                    <div id="now" style={{width: width+'%'}}>
                        <FontAwesomeIcon icon={['fas', 'caret-up' ]} />
                    </div>
                </div>
                <div className="priceHigh">{formatDecimals( rangeHigh )}</div>
            </div>
        </div>
    );
}