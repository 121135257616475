import React from "react";
import { Interval } from "luxon";
import { DateTime } from "luxon-business-days";
import atob from "atob";
import { SHOW_NAMES } from "components/utils/const";

const logEnabled = true;

const units: Intl.RelativeTimeFormatUnit[] = ["year", "month", "week", "day", "hour", "minute", "second"];

export const ID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
};

export const getTimestamp = (date: string): number => {
    return Math.floor(DateTime.fromISO(date).toSeconds());
};

export function log(msg: any, type: string = "log") {
    if (logEnabled) {
        if (type === "log") {
            console.log(msg);
        } else if (type === "table") {
            console.table(msg);
        }
    }
}

export const numZeroesAfterPoint = (x: number) => {
    if (x % 1 == 0) {
        return 0;
    } else {
        return -1 - Math.floor(Math.log10(x % 1));
    }
};

export const formatDecimals = (
    input: number | null,
    min: number = 2,
    max: number = 2,
    base: number | null = null
) => {
    const _ = input;
    let output: number;

    if (isNaN(Number(_)) || _ === null) {
        return null;
    } else if (typeof _ === 'string') {
        output = Number(_);
    } else {
        output = _;
    }

    const zeros = numZeroesAfterPoint(output);

    output = base ? output / base : output;

    // check what it does
    const useGrouping = !min && !max;
    const maxZeros = zeros > 1 ? zeros + 2 : max;

    return output.toLocaleString("en", {
        useGrouping: /*useGrouping*/ true,
        minimumFractionDigits: min,
        maximumFractionDigits: maxZeros,
    });
};

export const timeAgo = (time: number) => {
    const dt = DateTime.local();
    let dateTime = DateTime.fromMillis(time);
    let dateReturn;
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit) => diff.get(unit) !== 0) || "second";
    const relativeFormatter = new Intl.RelativeTimeFormat('en', { numeric: "auto" });
    dateReturn = relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
    return dateReturn;
};

export const ISODate = () => DateTime.local().toISODate();

// Check if we are currently in trading hours
export const isTrading = (endOfDay: boolean = false, timeInput: number | null = null) => {
    const time = timeInput !== null ? DateTime.fromMillis(timeInput) : DateTime.local();
    const endDayHour = endOfDay ? 23 : 15;
    const endDayMinute = endOfDay ? 59 : 59;
    const start = DateTime.local(time.year, time.month, time.day, 9, 30);
    const end = DateTime.local(time.year, time.month, time.day, endDayHour, endDayMinute);
    return time.isBusinessDay() ? Interval.fromDateTimes(start, end).contains(time) : false;
};

export const lastBusinessDay = (format: "ISO" | "TS" | null = null, includeToday: boolean = true) => {
    // manage to get last business day data if today is business but before 9:30AM
    const dt = DateTime.local();
    const datetime = (dt.isBusinessDay() && includeToday) || (dt.isBusinessDay() && isTrading(true)) ? dt : dt.minusBusiness();

    switch (format) {
        case "ISO":
            return datetime.toISODate();
        case "TS":
            const ts = datetime.toMillis();
            return (ts - (ts % 1000)) / 1000;
        default:
            return datetime;
    }
};

export const getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len) throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};

export const randKey = (prefix: string) => `${prefix}_${Math.floor(Math.random() * 10000)}`;

export const generateMissingData = (data) => {

    console.log("helper generateMissingData");
    console.log(data);

    const minutes = 391;
    const chartData = data;
    const length = chartData.length;
    const lastData = chartData[length - 1];
    let newTime = lastData.time + 60;
    const generatedData = [];

    for (let i = 0; i < 390 - length; i++) {
        generatedData.push({ time: newTime });
        newTime += 60;
    }

    console.log(generatedData);

    return chartData.concat(generatedData);
};

export const checkForNull = (data) => {
    return data.reduce((results, item) => {
        const hasNull = item === null;
        !hasNull ? results.push(item) : console.log('has null');
        return results;
    }, []);
};

export const getTime = (source: string = "", date: string, iso?: string): number => {
    switch (source) {
        case "MarketWatch":
            return DateTime.fromHTTP(date).toMillis();
        case "BusinessInsider":
            return DateTime.fromHTTP(date).toMillis();
        case "ValueWalk":
            return DateTime.fromRFC2822(date).toMillis();
        case "Investing":
            return DateTime.fromSQL(date, { zone: "Etc/GMT" }).toMillis();
        default:
            return iso ? DateTime.fromISO(iso).toMillis() : 0;
    }
};

export const cap = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

// From Redux sign in example

export const getIdToken = () => {
    const idToken = localStorage.getItem("id_token");
    if (!idToken) {
        throw new Error("No id token found");
    }
    return idToken;
};

export const getAccessToken = () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
        return false;
    }
    return accessToken;
};

// check type claim
export const getClaimFromToken = (token: string, claim: any) => {
    var payload = token.split(".")[1];
    var bin = atob(payload);
    var obj = JSON.parse(bin);
    return obj[claim];
};

export const getDisplayByTicker = (ticker: string, name: string | null) => {

    if(SHOW_NAMES.includes( ticker ) && name !== null) return name;

    return ticker;
};