 
import Auth from './auth';
  
const auth = new Auth();

export const ActionTypes = {
    AUTH_USER: 'AUTH_USER',
    AUTH_IN_PROGRESS: "AUTH_IN_PROGRESS",
    UNAUTH_USER: "UNAUTH_USER",
    AUTH_ERROR: "AUTH_ERROR",
    CLEARDOWN: "CLEARDOWN",
    FORGOT_SUCCESS: "FORGOT_SUCCESS",
    SIGNUP_IN_PROGRESS: "SIGNUP_IN_PROGRESS",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
};
  
export const signinUser = ({ email, password }: { email: string, password: string }) => {
    return {
        type: ActionTypes.AUTH_IN_PROGRESS,
        payload: {
          type: 'SIGNIN',
          email: email,
          password: password
        },
    }
};
  
export const signupUser = ({ username, email, password }: { username: string, email: string, password: string }) => {
    console.log( 'signupUser' );
    return {
        type: ActionTypes.AUTH_IN_PROGRESS,
        payload: {
          type: 'SIGNUP',
          email: email,
          password: password,
          username: username,
        },
    }
};

/*
export function signinUser({ email, password }, callback) {
  
    return function (dispatch) {
  
      dispatch({ type: ActionTypes.AUTH_IN_PROGRESS });
  
      auth.signin(email, password, callback)
        .then(() => {
          dispatch({ type: ActionTypes.AUTH_USER });
          return callback();
        })
        .catch((error) => {
          const errorMsg = error.description || error.message || 'Unspecified error';
          return dispatch(authError(errorMsg));
        })
    }
}
*/
  
export function authError(error: any) {
    const timestamp = Date.now();
    return {
      type: ActionTypes.AUTH_ERROR,
      error,
      timestamp
    };
}
  
export function signoutUser() {
    auth.signout();
    return { type: ActionTypes.UNAUTH_USER };
}
  
export function cleardown() {
    return { type: ActionTypes.CLEARDOWN };
}

// check what is used for
export function handleAuthentication(callback) {
    return function (dispatch) {
      auth.handleAuthentication()
        .then(() => {
          return dispatch({ type: ActionTypes.AUTH_USER });
        })
        .catch(err => {
          return dispatch({ type: ActionTypes.UNAUTH_USER });
        });
    }
}

export const Actions = {
    signinUser: signinUser,
    authError: authError,
    signoutUser: signoutUser,
    handleAuthentication: handleAuthentication,
};