
import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';

import { DostrikButton, DefaultButton, Flex } from 'styles';
import { darkTheme, lightTheme, ThemeType } from 'styles/Themes';
import { 
    Dashboard, DashboardHeader, DashboardTitleEdit, 
    DashboardDescEdit, DashboardTitle, DashboardDesc, DashboardSettings, 
    PixelPop, DashboardContent } from './styles';
import { PostState, SettingsState } from 'store/app/types/state';
import { Grid } from './components/grid';
import { Actions } from 'store/app/actions/post';
import { Placeholder } from './components/Placeholder';
import { ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardImage } from './components/dashboard/DashboardImage';

const PanelModal = loadable(() => import('./components/modal'));

export function Post(): JSX.Element {

    const dispatch = useDispatch();

    const theme = useSelector( (state: { settings: SettingsState }) => state.settings.theme );
    const activeTheme = ( theme === "dark" ) ? darkTheme : lightTheme;

    const [ showModal, setShowModal ] = useState<boolean>(false);

    const dashboardState = useSelector( (state: { post: PostState }) => state.post.dashboard );
    //const panelState = useSelector( (state: { post: PostState }) => state.post.panel );
    //const chartData = useSelector( (state: { post: PostState }) => state.post.panel.series );

    const dbTitle = dashboardState.title !== "" ? dashboardState.title : "(no title)";
    const dbDescription = dashboardState.description !== "" ? dashboardState.description : "(no description)";
    const isEmpty = dashboardState.items.length === 0;
    const isEditing = dashboardState.isEditing;

    const onHide = (): false | void => {
        if( showModal ) {
            if ( confirm('Discard changes?') ) {
                return setShowModal( false );
            } else {
                return false;
            }
        }
    }

    // redux?
    const handleEdit = (event: React.MouseEvent) => dispatch( Actions.setDashboardStatus( !isEditing ) );

    const handleChange = ( data: { [key: string]: string } ) => {
        Actions.setDashboard( data );
        
    };

    const removeFromDashboard = ( id: string ) => { // rename DashboardPanel?
        const index = [...dashboardState.items].findIndex( (item) => (item.id === id));

        const updatedDashboardItems = [...dashboardState.items];

        if( index === -1 ) {
            return alert('Error');
        }

        updatedDashboardItems.splice(index, 1);

        alert('removeFromDashboard');
    };

    return (
        <Dashboard>

            <DashboardHeader>

                <DashboardImage 
                    isEditing={isEditing} 
                    image={dashboardState.image}
                    background={dashboardState.settings.logo.background}
                    />

                <Flex direction="column" grow="1">
                    {
                        isEditing ?
                            <React.Fragment>
                                <DashboardTitleEdit 
                                    placeholder="Title" 
                                    defaultValue={dbTitle} 
                                    maxLength={40} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange({ 'title': event.target.value })} 
                                    />
                                <DashboardDescEdit 
                                    placeholder="Description" 
                                    defaultValue={dbDescription} 
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleChange({ 'description': event.target.value })} 
                                    />
                            </React.Fragment> :

                            <React.Fragment>
                                <DashboardTitle>
                                    <h2>{dbTitle}</h2>
                                    <span>Created by <Link to="/u/deagle">deagle</Link></span>
                                </DashboardTitle>
                                <DashboardDesc>
                                    <p>{dbDescription}</p>
                                </DashboardDesc>
                            </React.Fragment>
                    }
                </Flex>

                <div className="p-3">
                    Buttons
                </div>
            
            </DashboardHeader>

            <DashboardSettings>
                {
                    /*

                    <ReactTooltip id="add-panel" type="light" effect="solid" place="right">
                        <span>Test tooltip</span>
                    </ReactTooltip>

                    */
                }

                <ButtonGroup>
                    <DefaultButton 
                        onClick={handleEdit} 
                        data-tip 
                        data-for="add-panel"
                        fontSize="1rem"
                        padding="0.5rem 1rem 0.4rem 1rem"
                        >
                        { isEditing ? 'Save' : 'Edit' }
                    </DefaultButton>
                    { 
                        isEditing ? 
                        <React.Fragment>
                            <DostrikButton 
                                color="green"
                                onClick={() => setShowModal(true)}
                                >
                                <FontAwesomeIcon icon={["fal", "plus"]} /> Add new panel
                            </DostrikButton>
                        </React.Fragment> : null
                    }
                </ButtonGroup>

                {
                    showModal ?
                        <PanelModal 
                            show={true} 
                            hide={onHide} 
                            /> : null
                }

            </DashboardSettings>

            <PixelPop color="light" />

            <DashboardContent isEditing={isEditing}>
                {
                    isEmpty ?
                        <Placeholder>Start by adding a panel</Placeholder> :
                        <Grid
                            isEditing={isEditing}
                            dashboardState={dashboardState}
                            setShowModal={setShowModal}
                            removeFromDashboard={removeFromDashboard}
                            theme={activeTheme}
                            />
                }

            </DashboardContent>

        </Dashboard>
    );
}