import React, { useState } from 'react';
import { formatDecimals, randKey, timeAgo } from 'helpers';
import { Table, Card, DefaultDropdown, Flex } from 'styles';
import { DateTime } from 'luxon-business-days';
import { TCompanyData, TCompanyStats } from 'store/app/types';
import { Dropdown } from 'react-bootstrap';
import { ALL_NEWS } from 'components/utils/const';
import { Selected } from 'pages/temp/Admin/Selected';
import { Loading } from 'components/display/Loading';
import Toggle from 'react-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const generateAnnualDates = ( data ) => {

    let currentYears = "";
    const tYears: string[] = [];

    for( let i = 0; i < data.length; i++ ) {
        const currentYear = String(DateTime.fromSQL(data[i].dateEnding).year);

        const last = i+1 === data.length;
        const mod = (i+1) % 5;

        if( currentYears === "" ) {
            currentYears = currentYear;
        } else if( mod === 0 && !last ) {
            currentYears += (" - " + currentYear);
            tYears.push( currentYears );
            currentYears = "";
        }  else if( last && currentYears !== "" ) {
            currentYears += (" - " + currentYear);
            tYears.push( currentYears );
        }


    }
    return tYears;
}

function YearSelection( props: { data: any, selectedYear: string, type: "QUARTERLY" | "ANNUAL", handleChange: any, } ): JSX.Element {  // check type
    const { data, selectedYear, type, handleChange } = props;

    let checkYear = "";
    let tYears: string[] = [];
    const filteredData = data.filter( item => item.subType === type );  // check type

        let currentYears = "";

        for( let i = 0; i < filteredData.length; i++ ) {
            const currentYear = String(DateTime.fromSQL(filteredData[i].dateEnding).year);
    
            if( type === "QUARTERLY" ) {
                if( currentYear !== checkYear ) {
                    checkYear = currentYear;
                    tYears.push( currentYear );
                }
            } else if( type === "ANNUAL" ) {
                tYears = generateAnnualDates( filteredData );
 
            }
        }

        console.log( tYears );
        console.log( selectedYear );

    return (
        <DefaultDropdown onSelect={handleChange} fontSize="1rem">
            <DefaultDropdown.Toggle>{selectedYear}</DefaultDropdown.Toggle>
            <DefaultDropdown.Menu>
            {
                tYears.map( year => {
                    return <DefaultDropdown.Item key={year} eventKey={year} active={year === selectedYear}>{year}</DefaultDropdown.Item>;
                })
            }
            </DefaultDropdown.Menu>
        </DefaultDropdown>
    );

}



export function StatsTable(): JSX.Element {
    return ( 
        <div className="card data-card" data-controller="companies--statement">
            <div className="card-header">
                <div className="card-header-tab-switch d-flex">
                    <div className="statement-title">
                        <h2 className="card-title hidden-mobile-app d-inline-block mr-4" style={{fontSize: '1.5rem'}}>
                            <div className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2597" data-original-title="The Balance sheet is a financial statement that summarizes a company's assets, liabilities and shareholders' equity at a particular point in time.">Balance sheet</div>
                        </h2>
                        <div className="period-switcher d-inline-block">
                            <div className="tab-container">
                                <ul className="justify-content-end nav nav-tabs">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-action="click->companies--statement#switch_tabs" data-statement-interval="quarterly" data-toggle="tab" href="#balance_sheets-quarterly">Quarterly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-action="click->companies--statement#switch_tabs" data-statement-interval="annual" data-toggle="tab" href="#balance_sheets-annual">Annual</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="download-buttons ml-auto hidden-mobile-app">
                        <div className="hidden-sm-down d-inline-block statement-download statement-download__annual">
                            <a data-toggle="modal" data-target="#sign-up-modal" className="btn btn-secondary btn-sm waves-effect" href="#"><img src="https://wallmine.com/assets/company/download-9bb6e02b9288e5688ad5ea5ddd3f9f6191dda1897fb89fb03a4fc5ba0f1468da.svg" />XSLX</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content" data-controller="companies--statements-table" data-statement-kind="balance_sheets" style={{ padding: '0', overflowY: 'auto' }}>
                <div className="tab-pane active" id="balance_sheets-quarterly">
                    <table className="table table-sm table--no-margin">
                        <tbody>
                            <tr>
                                <th colSpan={2}>
                                    <small>(in millions USD)</small>
                                </th>
                                <th className="text-right">
                                    <b>31 Mar 2021</b>
                                </th>
                                <th className="text-right">
                                <b>31 Dec 2020</b>
                                </th>
                                <th className="hidden-xs-down text-right">
                                <b>30 Sep 2020</b>
                                </th>
                                <th className="hidden-xs-down text-right">
                                <b>30 Jun 2020</b>
                                </th>
                                </tr>
                                <tr className="js-tooltip" data-theme="wide" title="">
                                <th className="section-heading" colSpan={6}>Current assets</th>
                                </tr>
                                <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                                Cash
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="hidden-xs-down js-tooltip" data-chart-title="Cash" data-distance="25" data-period="quarterly" data-property="cash" data-target="companies--statements-table.property" data-theme="historical-values" data-series="[[1617163200000,3123860000],[1609390800000,2104976000],[1601438400000,1398339000],[1593489600000,1376261000],[1585627200000,157758],[1577768400000,8144000],[1569816000000,870851],[1561867200000,1144931],[1554004800000,0],[1546146000000,117908000]]" data-tooltipped="" aria-describedby="tippy-tooltip-2599" data-original-title="<div className='js-chart historical-values-chart'></div>">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                                    <span className="positive-text ">$3,123.86</span>
                                </td>
                                <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2601" data-original-title="+50.53% vs. previous period">
                                    <span className="positive-text ">$2,104.976</span>
                                </td>
                                <td className="hidden-xs-down js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2602" data-original-title="+1.60% vs. previous period">
                                    <span className="positive-text ">$1,398.339</span>
                                </td>
                                <td className="hidden-xs-down js-tooltip text-right" data-theme="wide" title="">
                                    <span className="number-with-sign"><span className="number__currency ">$</span>1,376.261</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2603" data-original-title="Short-term investments represent any investments that a company has made that is expected to be converted into cash within one year.">
                                    Short term investments
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="hidden-xs-down js-tooltip" data-chart-title="Cash" data-distance="25" data-period="quarterly" data-property="cash" data-target="companies--statements-table.property" data-theme="historical-values" data-series="[[1617163200000,3123860000],[1609390800000,2104976000],[1601438400000,1398339000],[1593489600000,1376261000],[1585627200000,157758],[1577768400000,8144000],[1569816000000,870851],[1561867200000,1144931],[1554004800000,0],[1546146000000,117908000]]" data-tooltipped="" aria-describedby="tippy-tooltip-2599" data-original-title="<div className='js-chart historical-values-chart'></div>">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                <td className="js-tooltip text-right" data-theme="wide" title=""></td>
                                <td className="js-tooltip text-right" data-theme="wide" title=""></td>
                                <td className="hidden-xs-down js-tooltip text-right" data-theme="wide" title=""></td>
                                <td className="hidden-xs-down js-tooltip text-right" data-theme="wide" title=""></td>
                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2604" data-original-title="Net receivables is the aggregated total of the money owed to a company by its customers that the company expects them to actually pay.">
                                    Net receivables
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="hidden-xs-down js-tooltip" data-chart-title="Cash" data-distance="25" data-period="quarterly" data-property="cash" data-target="companies--statements-table.property" data-theme="historical-values" data-series="[[1617163200000,3123860000],[1609390800000,2104976000],[1601438400000,1398339000],[1593489600000,1376261000],[1585627200000,157758],[1577768400000,8144000],[1569816000000,870851],[1561867200000,1144931],[1554004800000,0],[1546146000000,117908000]]" data-tooltipped="" aria-describedby="tippy-tooltip-2599" data-original-title="<div className='js-chart historical-values-chart'></div>">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2606" data-original-title="-3.18% vs. previous period">
                                    <span className="negative-text ">$72.395</span>
                                </td>
                                <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2607" data-original-title="-0.55% vs. previous period">
                                    <span className="negative-text ">$74.771</span>
                                </td>
                                <td className="hidden-xs-down js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2608" data-original-title="+49.87% vs. previous period">
                                    <span className="positive-text ">$75.183</span>
                                </td>
                                <td className="hidden-xs-down js-tooltip text-right" data-theme="wide" title="">
                                    <span className="number-with-sign"><span className="number__currency ">$</span>50.164</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export function OverviewTable( props: { currentPrice: string | number | null, hasStats: TCompanyStats | null, companyData: TCompanyData } ): JSX.Element {
    const currentPrice = props.currentPrice;
    const hasStats = props.hasStats;
    const companyData = props.companyData;
    const info = companyData.info;
    const update = hasStats ? DateTime.fromISO( companyData.stats.updatedAt ).toMillis() : null;
    const sharesOutstanding = companyData.stats ? ( companyData.stats.data.sharesOutstanding ? formatDecimals(companyData.stats.data.sharesOutstanding, 0, 0) : 'No data' ) : 'No data';
    const marketcap = companyData.stats ? ( sharesOutstanding !== 'No data' ? '$'+formatDecimals(companyData.stats.data.sharesOutstanding*currentPrice) : 'No data' ) : 'No data';

    return (
        <Card className="card overview-card" data-controller="companies--statement">
            <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="overview-title">
                        <h2 className="card-title d-inline-block mr-4 mb-0">
                            Overview
                        </h2>
                    </div>
                    {
                        update ?
                            <span>
                                <small>Last updated {timeAgo( update )}</small>
                            </span> : null
                    }
                </div>
            </div>
            <Table className="table table-sm table--no-margin">
                <tbody>
                    <tr className="js-tooltip" data-theme="wide" title="">
                        <th className="section-heading" colSpan={6}>Company</th>
                    </tr>
                    <tr>
                        <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                            Exchange
                        </td>
                        <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                            <span className="positive-text ">{ info.exchange }</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                            Industry
                        </td>
                        <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                            <span className="positive-text ">{ companyData.info.industry }</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                            Sector
                        </td>
                        <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                            <span className="positive-text ">{ companyData.info.sector }</span>
                        </td>
                    </tr>
                    <tr className="js-tooltip" data-theme="wide" title="">
                        <th className="section-heading" colSpan={6}>Fundamentals</th>
                    </tr>
                    <tr>
                        <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                            Market Cap
                        </td>
                        <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                            <span className="positive-text ">{ marketcap }</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                            Shares Outstanding
                        </td>
                        <td className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                            <span className="positive-text ">{ sharesOutstanding }</span>
                        </td>
                    </tr>

                </tbody>
            </Table>
        </Card>
    );
}

export function IncomeTable( props: any ): JSX.Element {
    return <div>Income table</div>
}

export function CashTable( props: any ): JSX.Element {
    return <div>Cash table</div>
}

export function EarningsTable( props ): JSX.Element {
    // filter quarterly so 4 max, same for years?
    let i = 0;
    const { data, isLoaded, isEmpty } = props;

    console.log( "render EarningsTable");

    if( isEmpty )
        return <div>No data</div>;
        
    if( !isLoaded )
        return <Loading />;

    const handleChange = ( key: string ) => {
        setEarningsState({...earningsState, year2: key });
    };

    const handleType = ( key: "QUARTERLY" | "ANNUAL" ) => {
        setEarningsState({...earningsState, type: key });
    };

    // check type
    const sortedData = data.slice().sort( (a,b) => DateTime.fromSQL(b.dateEnding).toMillis() - DateTime.fromSQL(a.dateEnding).toMillis() );
    const defaultType = "QUARTERLY";

    // check type
    const tempFilter = sortedData.filter( item => item.subType === defaultType );
    const defaultDate = tempFilter[0].dateEnding;
    const defaultDate2 = generateAnnualDates( sortedData.filter( item => item.subType === "ANNUAL" ) );

    const [ earningsState, setEarningsState ] = useState<{ year: string; year2: string; type: "QUARTERLY" | "ANNUAL"; }>({ year: DateTime.fromSQL(defaultDate).year, year2: defaultDate2[0], type: defaultType });

    const selectedYear = earningsState.type === "QUARTERLY" ? earningsState.year : earningsState.year2;



            // ##################################################################
            // use selectors instead? Logic in selector, display in component?
            // ##################################################################





    // check type
    const filtered = sortedData.filter( item => {
        const year = DateTime.fromSQL(item.dateEnding).year;

        if( earningsState.type === "QUARTERLY" ) {
            return item.subType === earningsState.type && year === earningsState.year;
        }

        const copy = earningsState.year2;
        const years = copy.split(" - ");

        return item.subType === earningsState.type && (year >= Number(years[1]) && year <= Number(years[0] ));
    });

    console.log( filtered);

    return ( 
        <Card className="card data-card" data-controller="companies--statement">
            <div className="card-header">
                <div className="card-header-tab-switch d-flex">
                    <Flex align="center">
                        <h2 className="card-title hidden-mobile-app d-inline-block mr-4 mb-0" style={{fontSize: '1.5rem'}}>
                            Earnings
                        </h2>
                        <div className="period-switcher d-inline-block">
                            <div className="tab-container">
                                <ul className="justify-content-end nav nav-tabs">
                                    <li className="nav-item">
                                        <a role="button" className={`nav-link${earningsState.type==="QUARTERLY" ? ` active` : ``}`} onClick={()=>handleType("QUARTERLY")}data-toggle="tab">Quarterly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a role="button" className={`nav-link${earningsState.type==="ANNUAL" ? ` active` : ``}`} onClick={()=>handleType("ANNUAL")} data-toggle="tab">Annual</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="d-inline-block ml-4">
                            <YearSelection 
                                data={sortedData} 
                                selectedYear={selectedYear} 
                                type={earningsState.type}
                                handleChange={handleChange} 
                                />
                        </div>
                    </Flex>
                    <div className="download-buttons ml-auto hidden-mobile-app">
                        <div className="hidden-sm-down d-inline-block statement-download statement-download__annual">
                            <a data-toggle="modal" data-target="#sign-up-modal" className="btn btn-secondary btn-sm waves-effect" href="#"><img src="https://wallmine.com/assets/company/download-9bb6e02b9288e5688ad5ea5ddd3f9f6191dda1897fb89fb03a4fc5ba0f1468da.svg" />XSLX</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content" data-controller="companies--statements-table" data-statement-kind="balance_sheets" style={{ padding: '0', overflowY: 'auto' }}>
                <div className="tab-pane active" id="balance_sheets-quarterly">
                    <Table className="table table-sm table--no-margin">
                        <tbody>
                            <tr>
                            <th colSpan={2}>
                            <small>EPS = Earnings per share</small>
                            </th>
                            {
                                filtered.map( item => (
                                    <th key={randKey('ET_DE')} className="text-right">
                                        <b>{ item.dateEnding }</b>
                                    </th> 
                                ))
                            }
                            </tr>
                            <tr className="js-tooltip" data-theme="wide" title="">
                                <th className="section-heading" colSpan={6}>Current assets</th>
                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                                    Estimated EPS
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                    <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                {
                                    filtered.map( item => (
                                        <td key={randKey('ET_EE')} className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                                            <span className="positive-text ">{ item.data.estimatedEPS }</span>
                                        </td> 
                                    ))
                                }
                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2603" data-original-title="Short-term investments represent any investments that a company has made that is expected to be converted into cash within one year.">
                                    Reported EPS
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                    <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                {
                                    filtered.map( item => (
                                        <td key={randKey('ET_RE')} className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                                            <span className="positive-text ">{ item.data.reportedEPS }</span>
                                        </td> 
                                    ))
                                }
                            </tr>
                            {
                                earningsState.type === "QUARTERLY" ?
                                <tr>
                                    <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2603" data-original-title="Short-term investments represent any investments that a company has made that is expected to be converted into cash within one year.">
                                        Surprise
                                    </td>
                                    <td style={{width:'40px'}}>
                                        <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                                <g id="glyphicons_x5F_halflings">
                                                    <g id="signal">
                                                        <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                    </g>
                                                </g>
                                                <g id="Layer_2"></g>
                                            </svg>
                                        </span>
                                    </td>
                                    {
                                        filtered.map( item => (
                                            <td  key={randKey('ET_SP')}className="js-tooltip text-right" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2600" data-original-title="+48.40% vs. previous period">
                                                <span className="positive-text ">{ item.data.surprise + " ("+item.data.surprisePercentage+"%)" }</span>
                                            </td> 
                                        ))
                                    }
                                </tr> : null
                            }
                            
                        </tbody>
                    </Table>
                </div>
            </div>
        </Card>
    );
}

export function BalanceTable( props: any ): JSX.Element {
    // filter quarterly so 4 max, same for years?
    let i = 0;
    const { data, isLoaded, isEmpty } = props;

    console.log( "render balance table");

    if( isEmpty )
        return <div>No data</div>;

    if( !isLoaded )
        return <Loading />;

    const handleChange = ( key: string ) => {
        setBalanceState({...balanceState, year: key });
    };

    const handleType = ( key: string ) => {
        setBalanceState({...balanceState, type: key });
    };

    // check type
    const sortedData = data.slice().sort( (a,b) => DateTime.fromSQL(b.dateEnding).toMillis() - DateTime.fromSQL(a.dateEnding).toMillis() );
    const defaultType = "QUARTERLY";

    // check type
    const tempFilter = sortedData.filter( item => item.subType === defaultType );

    console.log( tempFilter );

    const defaultDate = tempFilter[0].dateEnding;
    const defaultDate2 = generateAnnualDates( sortedData.filter( item => item.subType === "ANNUAL" ) );
    const [ balanceState, setBalanceState ] = useState<{ year: string, year2: string; type: "QUARTERLY" | "ANNUAL" }>({ year: DateTime.fromSQL(defaultDate).year, year2: defaultDate2[0], type: defaultType });

    const selectedYear = balanceState.type === "QUARTERLY" ? balanceState.year : balanceState.year2;
    // check type



            // ##################################################################
            // use selectors instead? Logic in selector, display in component?
            // ##################################################################




    const filtered = sortedData.filter( item => {
        const year = DateTime.fromSQL(item.dateEnding).year;
        if( balanceState.type === "QUARTERLY" ) {
            return item.subType === balanceState.type && year === balanceState.year;
        }

        const copy = balanceState.year2;
        const years = copy.split(" - ");


        return item.subType === balanceState.type && (year >= Number(years[1]) && year <= Number(years[0] ));
    });


    return ( 
        <Card className="card data-card" data-controller="companies--statement">
            <div className="card-header">
                <div className="card-header-tab-switch d-flex">
                    <Flex align="center">
                        <h2 className="card-title hidden-mobile-app d-inline-block mr-4 mb-0" style={{fontSize: '1.5rem'}}>
                            Balance Sheet
                        </h2>
                        <div className="period-switcher d-inline-block">
                            <div className="tab-container">
                                <ul className="justify-content-end nav nav-tabs">
                                    <li className="nav-item">
                                        <a role="button" className={`nav-link${balanceState.type==="QUARTERLY" ? ` active` : ``}`} onClick={()=>handleType("QUARTERLY")}data-toggle="tab">Quarterly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a role="button" className={`nav-link${balanceState.type==="ANNUAL" ? ` active` : ``}`} onClick={()=>handleType("ANNUAL")} data-toggle="tab">Annual</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="d-inline-block ml-4">
                            <YearSelection 
                                data={sortedData} 
                                selectedYear={selectedYear} 
                                type={balanceState.type}
                                handleChange={handleChange} 
                                />
                        </div>
                        <div>
                            <Toggle
                                className="mt-2 ml-3"
                                onClick={() => alert("Test")}
                                icons={{
                                    checked: <FontAwesomeIcon icon={["fas", "moon"]} />,
                                    unchecked: <FontAwesomeIcon icon={["fas", "moon"]} />,
                                }}
                                />
                                Toggle Chart
                        </div>
                    </Flex>
                    <div className="download-buttons ml-auto hidden-mobile-app">
                        <div className="hidden-sm-down d-inline-block statement-download statement-download__annual">
                            <a data-toggle="modal" data-target="#sign-up-modal" className="btn btn-secondary btn-sm waves-effect" href="#"><img src="https://wallmine.com/assets/company/download-9bb6e02b9288e5688ad5ea5ddd3f9f6191dda1897fb89fb03a4fc5ba0f1468da.svg" />XSLX</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content" data-controller="companies--statements-table" data-statement-kind="balance_sheets" style={{ padding: '0', overflowY: 'auto' }}>
                <div className="tab-pane active" id="balance_sheets-quarterly">
                    <Table className="table table-sm table--no-margin">
                        <tbody>
                            <tr>
                            <th colSpan={2}>
                                <small>Price in millions</small>
                            </th>
                            {
                                filtered.map( item => (
                                    <th key={randKey('BT_CA')} className="text-right">
                                        <b>{ item.dateEnding }</b>
                                    </th> 
                                ))
                            }
                            </tr>
                            <tr className="js-tooltip" data-theme="wide" title="">
                                <th className="section-heading" colSpan={6}>Current assets</th>
                            </tr>
                            <tr>
                                <td style={{width:'200px'}} className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2598" data-original-title="Cash and cash equivalents report the value of a company's assets that are cash or can be liquidated on a short notice.">
                                    Total Assets
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                    <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                            
                                {
                                    filtered.map( item => (
                                        <td key={randKey('BT_TA')} className="js-tooltip text-right">
                                            <span>{ formatDecimals( item.data.totalAssets, 0, 0, 1000 ) }</span>
                                        </td> 
                                    ))
                                }

                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2603" data-original-title="Short-term investments represent any investments that a company has made that is expected to be converted into cash within one year.">
                                    Investments
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                    <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                {
                                    filtered.map( item => (
                                        <td key={randKey('BT_I')} className="js-tooltip text-right">
                                            <span>{ formatDecimals( item.data.investments, 0, 0, 1000 ) }</span>
                                        </td> 
                                    ))
                                }
                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2603" data-original-title="Short-term investments represent any investments that a company has made that is expected to be converted into cash within one year.">
                                    Debt
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                    <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                {
                                    filtered.map( item => (
                                        <td key={randKey('BT_CD')} className="js-tooltip text-right">
                                            <span>{ formatDecimals( item.data.currentDebt, 0, 0, 1000 ) }</span>
                                        </td> 
                                    ))
                                }
                            </tr>
                            <tr>
                                <td className="js-tooltip" data-theme="wide" data-tooltipped="" aria-describedby="tippy-tooltip-2603" data-original-title="Short-term investments represent any investments that a company has made that is expected to be converted into cash within one year.">
                                    Total Shareholder Equity
                                </td>
                                <td style={{width:'40px'}}>
                                    <span className="historical-values js-historical-values hidden-mobile-app hidden-xs-down hidden" data-chart-title="Short term investments" data-distance="25" data-period="quarterly" data-property="short_term_investments" data-target="companies--statements-table.property" data-theme="historical-values" title="">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"  xmlSpace="preserve">
                                            <g id="glyphicons_x5F_halflings">
                                                <g id="signal">
                                                    <path fill="#434067" id="path_22_" d="M9,17.75C9,17.888,8.887,18,8.75,18h-1.5C7.113,18,7,17.888,7,17.75v-2.5C7,15.113,7.113,15,7.25,15h1.5    C8.887,15,9,15.113,9,15.25V17.75z M12,13.25c0-0.137-0.113-0.25-0.25-0.25h-1.5C10.113,13,10,13.113,10,13.25v4.5    c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V13.25z M15,10.25c0-0.137-0.113-0.25-0.25-0.25h-1.5    C13.113,10,13,10.113,13,10.25v7.5c0,0.138,0.113,0.25,0.25,0.25h1.5c0.137,0,0.25-0.112,0.25-0.25V10.25z M18,6.25    C18,6.113,17.888,6,17.75,6h-1.5C16.112,6,16,6.113,16,6.25v11.5c0,0.138,0.112,0.25,0.25,0.25h1.5c0.138,0,0.25-0.112,0.25-0.25    V6.25z"></path>
                                                </g>
                                            </g>
                                            <g id="Layer_2"></g>
                                        </svg>
                                    </span>
                                </td>
                                {
                                    filtered.map( item => (
                                        <td key={randKey('BT_TSE')} className="js-tooltip text-right">
                                            <span>{ formatDecimals( item.data.totalShareholderEquity, 0, 0, 1000 ) }</span>
                                        </td> 
                                    ))
                                }
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </Card>
    );
}