import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeType } from 'styles/Themes';
import loadable from '@loadable/component';

import { DashboardPanel } from 'store/app/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Area = loadable(() => import('./charts/Area'));
const Bar = loadable(() => import('./charts/Bar'));
const Candle = loadable(() => import('./charts/Candlestick'));
const Line = loadable(() => import('./charts/Line'));
const Pie = loadable(() => import('./charts/Pie'));
const Treemap = loadable(() => import('./charts/Treemap'));

type PanelProps = { 
    panel: DashboardPanel;
    theme: ThemeType;
    width: number;
    height: number;
    isEditing?: boolean;
    removeFromDashboard?: (id: string) => void
};

export function Panel( props: PanelProps ) {

    const colors = {
        text: props.theme.text,
        body: props.theme.body,
    };

    const size = { 
        width: props.width, 
        height: props.height,
        w: props.panel.size.w,
        h: props.panel.size.h
    };

    const isEditing = props.isEditing === true;
    const removeFromDashboard = props.removeFromDashboard !== undefined ? props.removeFromDashboard : () => console.log('Error');

    /*
    const handleClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setShowModal(true);
    }
    */

    const panelContent = (): JSX.Element => {
        switch( props.panel.type ) {
        
            case 'AREA': {
                return <Area 
                            id={props.panel.id} 
                            width={1270}
                            colors={colors}
                            columns={12}
                            size={size}
                            hasTitle={props.panel.title !== ""} 
                            />;
            }

            case 'BAR': {
                return <Bar 
                            id={props.panel.id} 
                            width={1270} 
                            colors={colors}
                            columns={12} 
                            size={size}
                            hasTitle={props.panel.title !== ""} 
                            />;
            }
            
            case 'CANDLE': {
                return <Candle 
                            id={props.panel.id} 
                            width={1270} 
                            colors={colors}
                            columns={12} 
                            size={size}
                            hasTitle={props.panel.title !== ""} 
                            />;
            }
            
            case 'LINE': {
                return <Line 
                            id={props.panel.id} 
                            width={1270} 
                            colors={colors}
                            columns={12} 
                            size={size}
                            hasTitle={props.panel.title !== ""} 
                            data={props.panel.data}
                            settings={props.panel.settings}
                            />;
            }
    
            case 'PIE': {
                return <Pie 
                            id={props.panel.id} 
                            width={1270}
                            colors={colors}
                            columns={12}
                            size={size}
                            data={props.panel.data}
                            hasTitle={props.panel.title !== ""} 
                            />;
            }
            
            case 'TREE': {
                return <Treemap 
                            id={props.panel.id} 
                            width={1270} 
                            colors={colors}
                            columns={12} 
                            size={size}
                            hasTitle={props.panel.title !== ""} 
                            />;
            }
    
            case 'TEXT': {
                return <p>{ props.panel.text }</p>;
            }
    
            default: {
                return <div>Default</div>;
            }
        }
    }

    return (    
        <ViewPanel hasBackground={props.panel.settings.panelBackground}>
            {
                isEditing ?
                    <ViewPanelOptions>
                        <ViewPanelButton>
                            <FontAwesomeIcon icon={[ 'far', 'edit']} />
                        </ViewPanelButton>
                        <ViewPanelButton onClick={() => removeFromDashboard(props.panel.id)}>
                            <FontAwesomeIcon icon={[ 'far', 'trash-alt']} />
                        </ViewPanelButton>
                    </ViewPanelOptions> : null
            }

            {
                ( props.panel.title !== "" ) ?
                    <ViewPanelTitle hasBackground={props.panel.settings.titleBackground}>
                        { props.panel.title }
                    </ViewPanelTitle> : null
            }
            
            <ViewPanelContent hasTitle={props.panel.title !== ""}>
                {
                    ( props.panel.text !== "" && props.panel.settings.textPosition === "top" ) ?
                        <p>{ props.panel.text }</p> : null
                }
                { panelContent() }
                {
                    ( props.panel.text !== "" && props.panel.settings.textPosition === "bottom" ) ?
                        <p>{ props.panel.text }</p> : null
                }
            </ViewPanelContent>
            
        </ViewPanel>
    );

    
}


const ViewPanel = styled.div<{ hasBackground: boolean }>`
    background: ${ props => props.hasBackground ? 'rgba( 255,255,255, 0.05 )' : 'none' };
    height: 100%;
`;

const ViewPanelOptions = styled.div`
    width: 100%;
    height: 100%;
    z-index: 99;
    position: absolute;
    background: rgba( 7,13,16, 0.85 );
    padding-right: 0.5rem;
    border-radius: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
`;

const ViewPanelButton = styled.button`
    color: ${ props => props.theme.text };
    font-size: 2rem;
    padding: 0.5rem 0.75rem 0.35rem 1rem;

    &:hover {
        background: ${ props => props.theme.body };
    }
`;

const ViewPanelTitle = styled.h3<{ hasBackground: boolean }>`
    background: ${ props => props.hasBackground ? 'rgba( 0,0,0, 0.5 )' : 'none' };
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    
`;

const ViewPanelContent = styled.div<{ hasTitle: boolean }>`
    padding: 0 0.75rem 0 0.75rem;
`;