// import React, { Suspense } from 'react';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import loadable from '@loadable/component';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-circular-progressbar/dist/styles.css';

//import { ActiveTheme } from './styles/Themes';
import { GlobalStyles } from './styles/GlobalStyles';
import { Container } from 'react-bootstrap';

/* Font Awesome Icons */
import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
    faPlay,
    faPause,
    faCog,
    faCircleNotch,
    faStar,
    faBell,
    faSort,
    faSave,
    faCaretUp,
    faCaretDown,
    faGripVertical,
    faHome,
    faMoon,
    faPlus as faPlusSolid,
    faFire,
    faRedo
} from '@fortawesome/free-solid-svg-icons';
import {
    faAngleUp,
    faAngleDoubleUp,
    faAngleDown,
    faAngleDoubleDown,
    faStar as falStar,
    faPlus,
    faMinus,
    faTimes,
    faAnalytics,
    faBell as falBell,
    faSun,
    faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import {
    faSignInAlt,
    faChevronUp,
    faChevronDown,
    faHandSpock,
    faAsterisk,
    faSearch,
    faChartPie,
    faChartBar,
    faChartLine,
    faChartArea,
    faText,
    faImage,
    faEdit,
    faCandleHolder,
    faTree,
    faTrashAlt,
    faPlus as farPlus,
} from '@fortawesome/pro-regular-svg-icons';
/* // Font Awesome Icons */

import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons";

import favicon from '../shared/assets/favicon.png';

import { Home } from 'pages/Home';
import { Post } from 'pages/Post';

// Nav Pages
const Stocks = loadable(() => import("pages/Stocks"));
const Crypto = loadable(() => import("pages/Crypto"));
const Calendar = loadable(() => import("pages/Calendar"));
const Admin = loadable(() => import("pages/temp/Admin"));

import { Profile } from 'pages/Profile';
import { Symbol } from 'pages/Symbol';

import { Header } from 'components/layout/Header';
import { Header as ContentHeader } from 'components/layout/Content/Header';

import routes from 'routes';
import { RootState } from 'store';
import { SymbolMatchParams } from 'store/app/types/props';
import { AppState } from 'store/app/types/state';

import { lightTheme, darkTheme, ActiveTheme } from 'styles/Themes';
import { useAuth } from 'hooks';

import { isTrading } from 'helpers';
import { WatchlistModal } from 'components/display/modals/WatchlistModal';
import { Footer } from 'components/layout/Footer';
import { NOTIF_ERROR, NOTIF_SUCCESS } from 'components/utils/const';

//import css from './App.module.css';

function App(): JSX.Element {
    console.log('App render');

    const dispatch = useDispatch();

    const appLoaded = useRef<boolean>();
    const isReady = useSelector((state: RootState) => state.app.watchlist.isLoaded);

    const theme = useSelector((state: RootState) => state.settings.theme);
    const tradingState = useSelector((state: RootState) => state.stocks.isTrading);
    const notification = useSelector((state: { app: AppState }) => state.app.notification);

    const { isAuthenticated, isLoggedIn, isLoading, authInfo, user } = useAuth();

    const RouteAuth = (Component: JSX.Element): JSX.Element => {
        if (!isAuthenticated) {
            return <div>You must be logged in!</div>;
        }
        return Component;
    };

    const selectedTheme = theme === 'dark' ? darkTheme : lightTheme;

    /*
    const auth = new Auth();
    const isAuthenticated = isBrowser ? auth.isAuthenticated() : false;
    */

    // if user is logged in, set false.
    // else loading == true until we validate authentification.
    // to improve? state?
    /*
    const isLoading = !isServer ? isAuthenticated !== false && !user.isLoggedIn : true;
    */

    // add loading while we wait for user check;

    useEffect(() => {
        if (!appLoaded.current) {
            appLoaded.current = true;
        }

        if (isTrading() && tradingState === false) {
            dispatch({ type: 'TRADING_STATUS', payload: true });
        } else if (!isTrading() && tradingState === true) {
            dispatch({ type: 'TRADING_STATUS', payload: false });
        }

        /*
        if( isReady ) {
            // first loading. in the future make it so user can select default watchlist on load, and change here
            dispatch({ type: 'UPDATE_TICKERBAR_VALUE', payload: { isLoaded: true, id: 'TRENDING', name: 'Trending' } });
    
        }
        */
    }, []);

    useEffect(() => {
        // use type in state to show error or warning or info
        const Notif = () => (
            <div>
                <h1 style={{ fontSize: '1rem' }}>{notification.title}</h1>
                {notification.message ? <p>{notification.message}</p> : null}
            </div>
        );

        if( notification.title !== "" ) {
            // improve
            //useNotifications()?
            switch( notification.type ) {
                case NOTIF_ERROR: {
                    toast.error(<Notif />, {
                        position: 'top-right',
                        autoClose: notification.delay,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    break;
                }

                case NOTIF_SUCCESS: {
                    toast.success(<Notif />, {
                        position: 'top-right',
                        autoClose: notification.delay,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    break;
                }
            }

            dispatch({ type: "RESET_NOTIFICATION" });
        }
    }, [notification]);

    // temp
    const homeProps = {
        isLoading: isLoading
    };

    const headerProps = {
        theme: theme,
        fn: {
        },
    };

    registerIcons();

    return (
        // <Suspense fallback={<div>Loading</div>}>
        <ThemeProvider theme={selectedTheme}>
            
            <GlobalStyles />

            <Helmet
                defaultTitle="dostrik"
                titleTemplate="%s – dostrik"
                link={[{ rel: "icon", type: "image/png", href: favicon }]}
            />

            <Header {...headerProps} />

            <main> 
                <React.Fragment>
                    <ContentHeader isLoggedIn={isLoggedIn} />
                    <Container id="main-content">
                        <Switch>
                            <Route
                                exact
                                path={routes.home}
                                render={() => <Home {...homeProps} />}
                            />
                            
                            <Route exact path={routes.stocks} render={() => <Stocks />} />
                            <Route exact path={routes.crypto} render={() => <Crypto />} />
                            <Route exact path={routes.calendar} render={() => <Calendar />} />

                            <Route exact path={routes.post} render={() => <Post />} />
                            <Route
                                exact
                                path={routes.profile}
                                render={() => RouteAuth(<Profile />)}
                            />
                            <Route
                                exact
                                path={routes.admin}
                                render={() => RouteAuth(<Admin />)}
                            />
                            <Route
                                exact
                                path={routes.symbol}
                                render={(props: RouteComponentProps<SymbolMatchParams>) => (
                                    <Symbol theme={theme} {...props} />
                                )}
                            />
                            <Route render={() => '404!'} />
                        </Switch>
                    </Container>
                </React.Fragment>

                {
                    isReady ? 
                        <WatchlistModal show={user.info.firstLogin} /> : null
                }

                <ToastContainer />
            </main>

            <Footer />

        </ThemeProvider>

        // </Suspense>
    );
}

function registerIcons(): void {
    library.add(
        faPlay,
        faPause,
        faCog,
        faCircleNotch,
        faAngleUp,
        faAngleDoubleUp,
        faAngleDown,
        faAngleDoubleDown,
        faStar,
        falStar,
        faPlus,
        farPlus,
        faMinus,
        faPlus,
        faPlusSolid,
        faTimes,
        faSort,
        faSave,
        faCaretUp,
        faCaretDown,
        faGripVertical,
        faHome,
        faSignInAlt,
        faChevronUp,
        faChevronDown,
        faHandSpock,
        faAnalytics,
        faBell,
        falBell,
        faAsterisk,
        faMoon,
        faSun,
        faSearch,
        faInfoCircle,
        faChartPie,
        faChartBar,
        faChartLine,
        faChartArea,
        faText,
        faImage,
        faEdit,
        faCandleHolder,
        faTree,
        faTrashAlt,
        faFire,
        faRedo,
        faExclamationCircle
    );
}

/*
<h2>{t('router-headline')}</h2>
<ul>
    <li>
        <Link to="/">{t('nav.home')}</Link>
    </li>
    <li>
        <Link to="/page-1">{t('nav.page-1')}</Link>
    </li>
    <li>
        <Link to="/page-2">{t('nav.page-2')}</Link>
    </li>
    <li>
        <Link to="/symbol/TEST">Test Symbol</Link>
    </li>
</ul>
*/

export default App;
