import React, { memo, useEffect, useRef } from "react";
import useSSR from "use-ssr";
import AnimateOnChange from "react-animate-on-change";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePrevious } from "hooks";
import { Link } from "react-router-dom";
import { TickerBarListData } from "store/app/types";
import { LoadingTickerDisplay } from "./Loading";
import { getDisplayByTicker } from "helpers";
import { BEARISH, BULLISH } from "components/utils/const";
import { CaretIcon, StarIcon } from "components/icons";

type TickerProps = {
    data: TickerBarListData;
    isLoading: boolean;
    value?: string | number | null;
    showValue?: boolean;
}

function Ticker( props: TickerProps ) {
    //adjust for display change on select
    
    const { isBrowser, isServer, isNative } = useSSR();
    const { ticker, name, watching } = props.data;
    const display = getDisplayByTicker(ticker as string, name);
    const showValue = props.showValue !== false;

    const isMounted = useRef<boolean>();

    useEffect(() => {
        if( isMounted.current ) {
            isMounted.current = true;
        }
    }, []);

        // check if isLoading is necessary
        const isLoading = props.isLoading || props.data.price === null || props.data.price === undefined;

    const curValue = String( props.data.price );
    const prevValue = usePrevious( curValue );

        //const displayValue = !isLoading ? props.data.displayPrice : '';
        const displayValue = props.data.displayPrice;

    const displayChange = props.data.displayChange;

    // check, make sure works properly
    const animate = isServer ? false : ( curValue !== prevValue && prevValue !== "undefined" );

    // check if received prop is number or string
    //console.log( 'typeof props.data.value = '+ typeof props.data.value );

    const statusLast = prevValue ? ((curValue > prevValue) ? BULLISH : BEARISH) : "";
    const statusDay = props.data.status;
    const TickerIcon = () => watching ? <StarIcon /> : <></>;

    if( !showValue )
        return (
            <span className="tickerDisplay">
                <Link to={"/symbol/"+ticker}>
                    <TickerIcon /> {display}
                </Link>
            </span>
        );

    return (
        <span className="tickerDisplay">
            <Link to={"/symbol/"+ticker}>
                <TickerIcon /> {display}
            </Link>
            {
                isLoading ? (
                    <LoadingTickerDisplay ticker={ticker} />
                ) : (                    
                    <span className="valueDisplay">
                        <AnimateOnChange
                            key={ticker}
                            baseClassName="ticker"
                            animationClassName={`Ticker-${statusLast}--update`}
                            animate={animate}>
                            <span>{displayValue}</span>
                        </AnimateOnChange>
                        <span className={statusDay}>
                            &nbsp;<CaretIcon bullish={(statusDay === BULLISH)} />{displayChange}
                        </span>
                    </span>
                )
            }
        </span>
    );
}

export const Symbol = memo( Ticker, (p, n) => {
    //console.log( 'Symbol.tsx:54');
    //console.log( 'Check Symbol memo, prev/next value type');
    if( 
        ( p.value === n.value || p.value === "undefined" ) && 
        ( p.data.watching === n.data.watching ) && 
        ( !p.isLoading )
    ) {
        return true;
    } else {
        return false;
    }
});