import { Form } from "react-bootstrap";
import styled from "styled-components";


export const StyledForm = styled(Form)`
    position: relative;

    svg {
        color: ${ props => props.theme.header.search.border };
    }

    @media screen and (max-width: 991px) {
        display: none
    }
`;