import { Action, ITickerData } from '../types';
//import {  } from '../types/props';

export const ActionTypes = {
    TOGGLE_THEME: "TOGGLE_THEME",
    HANDLE_FEATURED_CHART: "HANDLE_FEATURED_CHART",

};

/* for reference
export const setLocale = (locale: Locale) => ({
    type: ActionTypes.SETLOCALE,
    payload: locale,
}); */


export const handleChart = ( show: boolean ) => ({
    type: ActionTypes.HANDLE_FEATURED_CHART,
    payload: show
});


export const Actions = {

    handleChart: handleChart
    
};