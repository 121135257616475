// GENERAL

export const BULLISH = "bullish";
export const BEARISH = "bearish";

export const SIDEBAR = "SIDEBAR";

// STOCKS

// Put directly in DB
export const SHOW_NAMES = [
    // Indexes
    "^IXIC", // Nasdaq
    "^DJI", // Down Jones
    "^GSPTSE", // TSX
    "^GSPC", // S&P 500
    "^RUT", // Russell 2000
    // Futures
    "GC=F", // Gold
    "CL=F", // Oil
    "NQ=F", // Nasdaq 100
    "ES=F", // E-Mini S&P 500
    "YM=F" // Mini Down Jones
];

export const FEATURED_LIST = [
    // Indexes
    "^IXIC", // Nasdaq
    "^DJI", // Down Jones
    "^GSPTSE", // TSX
    "^GSPC", // S&P 500
    "^RUT", // Russell 2000
    // Futures
    "GC=F", // Gold
    "CL=F", // Oil
    "NQ=F", // Nasdaq 100
    "ES=F", // E-Mini S&P 500
    "YM=F" // Mini Down Jones
];

// TAGS

export const INDUSTRY = "INDUSTRY";

// WATCHLIST

export const TRENDING = "TRENDING";
export const WATCHLIST = "WATCHLIST";

export const ADD = "ADD";
export const DELETE = "DELETE";

// NOTIF

export const NOTIF_ERROR = "ERROR";
export const NOTIF_SUCCESS = "SUCCESS"

// TICKERBAR

    // adjust for translation - check
    export const TICKERBAR = {
        MARKET: {
            OPEN: `Market Open`,
            CLOSED: `Market Closed`,
            LIVE: `Markets Open - Real Time Data`
        },
        BLOB: {
            OPEN: `red`,
            CLOSED: `grey`,
            LIVE: `green`
        }
    };

    // STATUS
    export const PLAYING = "PLAYING";
    export const PAUSED = "PAUSED";

    // TYPES
    export const INDEX = "INDEX";
    export const FUTURE = "FUTURE";

// NEWS

    export const NEWS_RELOAD_DELAY = 10;
    export const MAX_RELOAD_ATTEMPTS = 2;

    // News display
    export const HOME = "HOME";
    export const SYMBOL = "SYMBOL";

    // News type
    export const NEWS = "NEWS";
    export const BLOGS = "BLOGS";
    export const COMPANY_NEWS = "COMPANY_NEWS";

    // News dropdown categories

    export const ALL_NEWS = "ALL_NEWS";
    export const STOCK_NEWS = "STOCK_NEWS";
    export const CRYPTO_NEWS = "CRYPTO_NEWS";
    export const FIN_NEWS = "FIN_NEWS";

// CHART

    // Type
    export const DEFAULT = "DEFAULT";
    export const CANDLE = "CANDLE";
    export const BAR = "BAR";

    // Range
    export const ONE_DAY = "1D";
    export const FIVE_DAYS = "5D";
    export const ONE_MONTH = "1M";
    export const SIX_MONTHS = "6M";
    export const ONE_YEAR = "1Y";
    export const FIVE_YEARS = "5Y";
    export const MAX = "MAX";
