import { HeaderMenuItem } from 'components/layout/Header/styles';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

export const NotificationsDropdown = styled(Dropdown)<{fontSize?: string; padding?: string; }>`
    .dropdown-toggle {
        
        &[aria-expanded="true"] {
        }

        &:focus,
        &:active {
            outline: 0;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
    }
    
    .dropdown-menu {
        width: 250px;
        transform: translate(0px, -10px) !important;
        background: ${props => props.theme.header.dropdown.background };
        border-color:  ${props => props.theme.header.dropdown.background };

        a {
            color: ${props => props.theme.text };
            font-size: ${props => props.fontSize ? props.fontSize : `1rem` };
            padding: ${props => props.padding ? props.padding : `0.5rem 1rem` };

            &.active {
                background-color: ${props => props.theme.header.dropdown.active };
            }

            &:hover {
                background-color: ${props => props.theme.header.dropdown.hover };
            }
        }

        &:after {
            display: none;
            right: 17px;
            border-bottom-color: ${props => props.theme.header.dropdown.background };
        }
    }
    
    &.show {
        background: ${props => props.theme.header.dropdown.background };
    }
`;

export const DropdownItem = styled(NotificationsDropdown.Item)<{ $read: boolean }>`
    background-color: ${ props => props.$read === false ? props.theme.pixelPopLight : `transparent` };
    padding: 0.5rem 1rem;
    border-top: ${ props => props.theme.pixelPopLight };
    border-bottom: 1px solid ${ props => props.$read === false ? `transparent` : props.theme.pixelPopLight };
    position: relative;
`;

export const NotificationsMenu = styled(HeaderMenuItem)`
    margin-right: 0.5rem;
    position: relative;
`;

export const NotificationItem = styled.div`
    display: flex;
    align-items: center;
`;

    export const NotificationTitle = styled.div`
        font-weight: bold;
        font-family: ${ props => props.theme.fonts.varela };
    `;

    export const NotificationText = styled.div`
        font-size: 0.9rem;
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 130px; 
        white-space: nowrap;
    `;

    export const Icon = styled.div`
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: green;
        margin-right: 0.75rem;
    `;

    export const NotificationOptionsButton = styled.button`
        position: relative;
        z-index: 999;
        margin-left: auto;
        border-radius: 3px;

        &:hover {
            background: #FFFFFF;
        }
    `;

    export const NotificationOptionsMenu = styled.div<{ show: boolean }>`
        position: absolute;
        width: 170px;
        left: 98%;
        top: 1rem;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #f8f8f8;
        display: ${ props => props.show ? 'block' : 'none' };
        z-index: 999;

        ul {
            padding: 0.25rem 0;
            list-style: none;
            margin-bottom: 0;

            li {

            }
        }

        &:after {
                content: "";
                position: absolute;
                top: 7px;
                left: -7px;
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-right: 7px solid #FFFFFF;
        }
    `;

        export const AlertOptionsMenu = styled(NotificationOptionsMenu)`
        
        `;

        export const MenuOption = styled.button`
            width: 100%;
            padding: 0.35rem 0 0.35rem 0.75rem;
            text-align: left;
            font-size: 0.9rem;

            &:hover {
                background-color: #e9ecef;
            }
        `;

export const NotificationsAlert = styled.div<{ show: boolean }>`
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: #ff6753;
    position: absolute;
    right: 1px;
    top: 2px;
    border: 2px solid ${ props => props.theme.body };

    ${ props => !props.show ?
        `display: none;` : null
    }
`;