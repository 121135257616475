import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
    NewsContainer,
    NewsItem,
    NewsPrice,
    DefaultDropdown,
    BorderTitle,
    Flex,
    DefaultButton,
} from '../../styles';
import { Loading, LoadingNews } from './Loading';
import { getTime, numZeroesAfterPoint } from '../../helpers';
//import { } from 'store/app/types';
import { NewsListItemProps, NewsProps } from 'store/app/types/props';
import { ALL_NEWS, BEARISH, BULLISH, CRYPTO_NEWS, FIN_NEWS, STOCK_NEWS, COMPANY_NEWS, NEWS, NEWS_RELOAD_DELAY, MAX_RELOAD_ATTEMPTS } from 'components/utils/const';
import { AppState } from 'store/app/types/state';
import { INewsList } from 'store/app/types';
import { ErrorLoadingIcon } from 'components/icons';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ChangingProgressProvider from "components/providers/ChangingProgress";

export function NewsList(props: NewsProps) {

    const dispatch = useDispatch();
    const [ newsState, setNewsState ] = useState<{ reloaded: number }>({ reloaded: 0 });
    const news = useSelector( (state: { app: AppState }) => state.app.news );

    const error = news.error;
    const hasError = error.status;

    let newsList: INewsList[] = [];

    if( news.isLoaded ) {
        const tempNewsList = [ ...news.list ];
        newsList = tempNewsList.sort( (a, b) => {
            const timeA = getTime( a.source, a.pubDate );
            const timeB = getTime( b.source, b.pubDate );
            return timeB - timeA;
        });
    }

    useEffect( () => {
        if( !news.isLoaded && !news.isFetching && newsState.reloaded <= MAX_RELOAD_ATTEMPTS) {
            dispatch({ type: "LOAD_NEWS" });
        }
    }, [newsState.reloaded]);

    const handleReload = () => {
        if( newsState.reloaded < MAX_RELOAD_ATTEMPTS ) {
            setNewsState({ ...newsState, reloaded: newsState.reloaded+1 });
        }
    }

    const { type, display } = props;
    const max = props.max ? props.max : null;
    const reloadAttempt = newsState.reloaded < MAX_RELOAD_ATTEMPTS;
    const isLoaded = news.isLoaded && newsList.length !== 0;
    const isLoading = !news.isLoaded && news.isFetching;
    const count = (type === COMPANY_NEWS) ? 5 : 15;

    const DisplayTitle = () => <h2>Latest News</h2>;

    const progressStyles = buildStyles({
        trailColor: "#1e2f3c",
    });

    if( hasError )
        return (
            <React.Fragment>
                <BorderTitle>
                    <DisplayTitle />
                </BorderTitle>
                <Flex align="center" className="mt-4" direction="column">
                    {
                        reloadAttempt ? (
                            <div style={{ width: 65 }}>
                                <ChangingProgressProvider 
                                    values={[100, 0]}
                                    handleReload={handleReload}
                                    >
                                    {
                                        (percentage: number) => (
                                            <CircularProgressbarWithChildren 
                                                value={percentage} 
                                                counterClockwise={true}
                                                styles={{
                                                    ...progressStyles,
                                                    path: {
                                                        // Customize transition animation
                                                        transition: `stroke-dashoffset ${NEWS_RELOAD_DELAY}s linear 0s`
                                                    },
                                                }}
                                                >
                                                    <ErrorLoadingIcon />
                                            </CircularProgressbarWithChildren>
                                        )
                                    }
                                </ChangingProgressProvider>
                            </div>
                        ) : (
                            <div>
                                <ErrorLoadingIcon />
                            </div>
                        )
                    }
                    <p>{error.message}</p>
                </Flex>
            </React.Fragment>
        );

    if( isLoading )
        return (
            <React.Fragment>
                <BorderTitle>
                    <DisplayTitle />
                </BorderTitle>
                <LoadingNews type={type} nb={count} />
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <BorderTitle justify="space-between">
                    <DisplayTitle />
                <NewsFilter />
            </BorderTitle>

            <NewsContainer data-simplebar>
                {isLoaded ? (
                    <ul className="pl-0">
                        {newsList
                            .filter((item, i) => (max ? i < max : true))
                            .map((item, i) => (
                                <NewsListItem
                                    key={`${item.link}-${i}`}
                                    item={item}
                                    time={getTime(item.source, item.pubDate, item.isoDate)}
                                    includeData={type === COMPANY_NEWS}
                                    />
                            ))}
                    </ul>
                ) : (
                    <div>No news</div>
                )}
            </NewsContainer>

            <Flex justify="center">
                <DefaultButton onClick={() => alert("To do")}>Load more</DefaultButton>
            </Flex>
        </React.Fragment>
    );
}

export function BlogList(props: NewsProps) {

    const [ blogState, setBlogState ] = useState<{ reloaded: number }>({ reloaded: 0 });
    const dispatch = useDispatch();
    const blogs = useSelector( (state: { app: AppState }) => state.app.blogs );

    const error = blogs.error;
    const hasError = error.status;

    let blogList: INewsList[] = [];

    if( blogs.isLoaded ) {
        const tempBlogList = [ ...blogs.list ];
        blogList = tempBlogList.sort( (a, b) => {
            const timeA = getTime( a.source, a.pubDate );
            const timeB = getTime( b.source, b.pubDate );
            return timeB - timeA;
        });
    }

    useEffect( () => {
        if( !blogs.isLoaded && !blogs.isFetching && blogState.reloaded <= MAX_RELOAD_ATTEMPTS) {
            dispatch({ type: "LOAD_BLOGS" });
        }
    }, [blogState.reloaded]);

    const handleReload = () => {
        if( blogState.reloaded < MAX_RELOAD_ATTEMPTS ) {
            setBlogState({ ...blogState, reloaded: blogState.reloaded+1 });
        }
    }

    const { type, display } = props;
    const max = props.max ? props.max : null;
    const reloadAttempt = blogState.reloaded < MAX_RELOAD_ATTEMPTS;
    const isLoaded = blogs.isLoaded && blogList.length !== 0;
    const isLoading = !blogs.isLoaded && blogs.isFetching;
    const count = (type === COMPANY_NEWS) ? 5 : 15;

    const DisplayTitle = () => <h2>Recent Blogs</h2>;

    const progressStyles = buildStyles({
        trailColor: "#1e2f3c",
    });

    if( hasError && !isLoading )
        return (
            <React.Fragment>
                <BorderTitle>
                    <DisplayTitle />
                </BorderTitle>
                <Flex align="center" className="mt-4" direction="column">
                    {
                        reloadAttempt ? (
                            <div style={{ width: 65 }}>
                                <ChangingProgressProvider 
                                    values={[100, 0]}
                                    handleReload={handleReload}
                                    >
                                    {
                                        (percentage: number) => (
                                            <CircularProgressbarWithChildren 
                                                value={percentage} 
                                                counterClockwise={true}
                                                styles={{
                                                    ...progressStyles,
                                                    path: {
                                                        // Customize transition animation
                                                        transition: `stroke-dashoffset ${NEWS_RELOAD_DELAY}s linear 0s`
                                                    },
                                                }}
                                                >
                                                    <ErrorLoadingIcon />
                                            </CircularProgressbarWithChildren>
                                        )
                                    }
                                </ChangingProgressProvider>
                            </div>
                        ) : (
                            <div>
                                <ErrorLoadingIcon />
                            </div>
                        )
                    }
                    <p>{error.message}</p>
                </Flex>
            </React.Fragment>
        );

    if( isLoading )
        return (
            <React.Fragment>
                <BorderTitle direction="column">
                    <DisplayTitle />
                </BorderTitle>
                <LoadingNews type={type} nb={count} />
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <BorderTitle direction="column">
                <DisplayTitle />
            </BorderTitle>

            <NewsContainer data-simplebar>
                {isLoading ? (
                    <Loading />
                ) : isLoaded ? (
                    <ul className="pl-0">
                        {blogList
                            .filter((item, i) => (max ? i < max : true))
                            .map((item, i) => (
                                <NewsListItem
                                    key={`${item.link}-${i}`}
                                    item={item}
                                    time={getTime(item.source, item.pubDate, item.isoDate)}
                                    includeData={type === COMPANY_NEWS}
                                    />
                            ))}
                    </ul>
                ) : (
                    <div>No news</div>
                )}
            </NewsContainer>

            <Flex justify="center">
                <DefaultButton onClick={() => alert("To do")}>Load more</DefaultButton>
            </Flex>
        </React.Fragment>
    );
}


export function StockNewsList(props: NewsProps) {
    
    const { list, data, currentPrice, display, type, title } = props;

    const max = props.max ? props.max : null;
    const isLoaded = props.isLoaded && (list && list.length !== 0);
    const isLoading = !isLoaded;
    const count = (type === COMPANY_NEWS) ? 5 : 15;
    /*
    const titleProps = {
        ...includeFilter === true && { className: `mb-0 pl-2` },
        ...includeFilter === false && { className: `mt-1 mb-1 pl-2` }
    };
    */
    const DisplayTitle = () => <h2>{title}</h2>;

    if( isLoading )
        return (
            <React.Fragment>
                <BorderTitle direction="column">
                    <DisplayTitle />
                </BorderTitle>
                <LoadingNews type={type} nb={count} />
            </React.Fragment>
        );
        
    const priceData = {
        currentPrice: currentPrice,
        data: data,
    };

    return (
        <React.Fragment>
            <BorderTitle>
                <DisplayTitle />
            </BorderTitle>

            <NewsContainer data-simplebar>
                {isLoading ? (
                    <Loading />
                ) : isLoaded ? (
                    <ul className="pl-0">
                        {list
                            .filter((item, i) => (max ? i < max : true))
                            .map((item, i) => (
                                <NewsListItem
                                    key={`${item.link}-${i}`}
                                    item={item}
                                    time={getTime(item.source, item.pubDate, item.isoDate)}
                                    includeData={type === COMPANY_NEWS}
                                    {...data}
                                    />
                            ))}
                    </ul>
                ) : (
                    <div>No news</div>
                )}
            </NewsContainer>
        </React.Fragment>
    );
}

function NewsListItem(props: NewsListItemProps): JSX.Element {
    const item = props.item;
    const staticUrl = useSelector((state: { app: { staticUrl: string } }) => state.app.staticUrl);
    const nowDt = DateTime.local();
    const postDt = DateTime.fromMillis(props.time);
    const fullDate = postDt.toLocaleString(DateTime.DATETIME_MED);
    const displayTime =
        postDt.day === nowDt.day
            ? postDt.toLocaleString(DateTime.TIME_SIMPLE)
            : postDt.toLocaleString(DateTime.DATE_MED);

    const change = {
        status: "",
        display: "",
    };

    const getStatus = (closePrice: number, currentPrice: number) => {
        //const current = parseFloat(currentPrice);
        const diff = currentPrice - closePrice;
        return (Math.sign(diff) === 1) ? BULLISH : BEARISH;
    };

    const getDiff = (closePrice: number, currentPrice: number) => {
        //const current = parseFloat(currentPrice);
        const diff = currentPrice - closePrice;
        const fixed = numZeroesAfterPoint(Math.abs(diff)) + 2;
        const change = 100 * Math.abs(diff / ((currentPrice + closePrice) / 2));
        const plusMinus = Math.sign(diff) === 1 ? "+" : "-";
        return `${plusMinus}${String(change.toFixed(fixed))}%`;
    };

    if (props.includeData && props.data.intradayData) {
        // check if really today, or another day
        const ts = postDt.toSeconds();
        const tickerData = props.data.intradayData[0].data;

        if (tickerData !== "No data") {
            const mod = ts % 60;
            const postTs = (mod !== 0) ? ts - mod : ts;
            const findTs = tickerData.find((item: any) => item.time === postTs); // check type

            change.status = (findTs !== undefined) ? getStatus(findTs.close, props.currentPrice) : "";
            change.display = (findTs !== undefined) ? getDiff(findTs.close, props.currentPrice) : "";
        }
    }

    const logo = (source?: string) => {
        switch (source) {
            case "MarketWatch":
                return "mw.png";
            case 'BusinessInsider':
                return "bi.png";
            case 'ValueWalk':
                return "vw.png";
            case 'Investing':
                return "inv.png";
            default:
                return "chart.png";
        }
    };

    return (
        <NewsItem className="media text-muted d-flex align-items-center">
            <img
                src={`${staticUrl}public/images/icons/${logo(item.source)}`}
                srcSet={`${staticUrl}public/images/icons/${logo(item.source)} 2x`}
                alt=""
                style={{ height: "12px" }}
                className="mr-2 rounded"
                />
            <div className="media-body pt-2 pb-2 mb-0 small lh-125">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span
                        data-tooltip={fullDate}
                        style={{ whiteSpace: "nowrap" }}
                        suppressHydrationWarning
                        >
                        {displayTime}
                    </span>
                    <a
                        className="flex-grow-1 pl-2 pr-2 font-weight-normal"
                        href={item.link}
                        target="_blank"
                        >
                        {item.title}
                    </a>
                    {
                        // to do
                        change.display !== "" ? (
                            <NewsPrice status={change.status}>{change.display}</NewsPrice>
                        ) : null
                    }
                </div>
            </div>
        </NewsItem>
    );
}

function NewsFilter(): JSX.Element {
    return (
        <React.Fragment>
            <DefaultDropdown fontSize="0.9rem">
                <DefaultDropdown.Toggle>All news</DefaultDropdown.Toggle>
                <DefaultDropdown.Menu>
                    {
                        // temp :
                    }
                    <DefaultDropdown.Item key={ALL_NEWS} active={true}>All news</DefaultDropdown.Item>
                    <DefaultDropdown.Item key={STOCK_NEWS}>Stockmarkets</DefaultDropdown.Item>
                    <DefaultDropdown.Item key={CRYPTO_NEWS}>Crypto</DefaultDropdown.Item>
                    <DefaultDropdown.Item key={FIN_NEWS}>Financial</DefaultDropdown.Item>
                </DefaultDropdown.Menu>
            </DefaultDropdown>
        </React.Fragment>
    );
}
