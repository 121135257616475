
import { NOTIF_ERROR, NOTIF_SUCCESS } from 'components/utils/const';
import { Action, Locale, Notification } from '../types';

export const ActionTypes = {
    SETLOCALE: 'app/set-locale',

    APP_LOADED: "APP_LOADED",

    CHANGE_PAGE: "CHANGE_PAGE",

    // Clean up
    UPDATE_TICKER_DISPLAY: "UPDATE_TICKER_DISPLAY",

    UPDATE_TICKERBAR_VALUE: "UPDATE_TICKERBAR_VALUE",
    UPDATE_TICKERBAR_VALUE_SUCCESS: "UPDATE_TICKERBAR_VALUE_SUCCESS",
    UPDATE_TICKERBAR_STATUS: "UPDATE_TICKERBAR_STATUS",

    // used to animate ticker bar (if needed) once it's loaded
    TICKERBAR_LOADED: "TICKERBAR_LOADED",

    PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
    RESET_NOTIFICATION: "RESET_NOTIFICATION",

    LOAD_NEWS: "LOAD_NEWS",
    LOAD_NEWS_SUCCESS: "LOAD_NEWS_SUCCESS",
    LOAD_NEWS_FAIL: "LOAD_NEWS_FAIL",

    LOAD_BLOGS: "LOAD_BLOGS",
    LOAD_BLOGS_SUCCESS: "LOAD_BLOGS_SUCCESS",
    LOAD_BLOGS_FAIL: "LOAD_BLOGS_FAIL",
};


export const setLocale = (locale: Locale) => ({
    type: ActionTypes.SETLOCALE,
    payload: locale,
});

/* App Actions */

export const changePage = ( page: string, ticker: string = '' ): Action => ({
    type: ActionTypes.CHANGE_PAGE,
    payload: {
        page: page,
        symbol: ticker
    }
});

export const updateTickerBarStatus = ( status: boolean ): Action => ({
    type: ActionTypes.UPDATE_TICKERBAR_STATUS,
    payload: status
});

export const updateTickerBarValue = ( id: number | string, name: string ): Action => ({
    type: ActionTypes.UPDATE_TICKERBAR_VALUE,
    payload: {
        isLoaded: false,
        id: id,
        name: name,
    }
});

export const updateTickerDisplay = ( type: string, watchlist: string = "SIDEBAR" ) => ({
    type: ActionTypes.UPDATE_TICKER_DISPLAY,
    payload: {
        type: type,
        watchlist: watchlist
    }
});

export const dispatchSuccess = ({ title, message, delay, callback }: Notification ): Action => ({
    type: ActionTypes.PUSH_NOTIFICATION,
    payload: {
        title: title,
        message: message,
        type: NOTIF_SUCCESS,
        delay: delay,
        callback: callback
    }
});

export const dispatchError = ({ title, message, delay, callback }: Notification ): Action => ({
    type: ActionTypes.PUSH_NOTIFICATION,
    payload: {
        title: title,
        message: message,
        type: NOTIF_ERROR,
        delay: delay,
        callback: callback
    }
});

export const Actions = {
    setLocale: setLocale,
    changePage: changePage,
    updateTickerBarStatus: updateTickerBarStatus,
    updateTickerBarValue: updateTickerBarValue,
    //updateSelectedList: updateSelectedList,
    updateTickerDisplay: updateTickerDisplay,
    dispatchSuccess: dispatchSuccess,
    dispatchError: dispatchError,
};