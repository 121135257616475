import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlaceholderBox, PlaceholderButton } from '../styles';

type PlaceholderProps = { 
    children: any;
    onClick?: (event: React.MouseEvent) => void;
};

export function Placeholder( props: PlaceholderProps ): JSX.Element {

    const { children, onClick } = props;
    const opts = {
        ...onClick && { onClick: onClick }
    }

    return (
        <PlaceholderBox>

            {
                /*
                    <FontAwesomeIcon icon={[ 'fal', 'plus' ]} size="2x" />
                    <PlaceholderButton {...opts}>
                        <FontAwesomeIcon icon={[ 'far', 'edit' ]} />
                    </PlaceholderButton>
                */
            }


            {children}

        </PlaceholderBox>
    )
}