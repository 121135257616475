import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/*
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Features from 'shared/components/Features';
import { setLocale } from 'store/app/actions';
import { Locale } from 'store/app/types';
*/

import { Row, Col } from 'react-bootstrap';
import { Sidebar } from '../../components/layout/Sidebars';
import { LoggedOutMessage } from '../../components/display/LoggedOut';
import { NewsList, BlogList } from '../../components/display/News';
import { HomeProps } from 'store/app/types/props';
import { AppState, UserState } from 'store/app/types/state';
import { changePage } from 'store/app/actions/app';
import { getTime } from 'helpers';
import { Border } from 'styles';
import { BLOGS, HOME, NEWS, SIDEBAR } from 'components/utils/const';


export function Home( props: HomeProps ): JSX.Element {
    const dispatch = useDispatch();
    const page = useSelector( (state: { app: AppState }) => state.app.current.page );
    const user = useSelector( (state: { user: UserState }) => state.user );

    useEffect( () => {
        // make const for pages
        if( page !== "Home" ) {
            dispatch( changePage( "Home" ) );
        }

    }, []);

    return (
            <React.Fragment>
                <Row>
                    <Col lg={{span: 9, order: 1}} xs={{span: 12, order: 2}}>
                        <Row>
                            <Col md="6">
                                <Border padding="0.5rem">
                                    <NewsList 
                                        type={NEWS}
                                        display={HOME}
                                        max={20}
                                        />
                                </Border>
                            </Col>
                            <Col md="6">
                                <Border padding="0.5rem">
                                    <BlogList 
                                        type={BLOGS}
                                        display={HOME}
                                        max={20}
                                        />
                                </Border>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{span: 3, order: 1}} xs={{span: 12, order: 1}}>
                        <Sidebar 
                            isLoggedIn={user.isLoggedIn} 
                            component={SIDEBAR}
                            />
                    </Col>
                </Row>
            </React.Fragment>
    )
};

/*
const App: React.FC<any> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleLocaleChange = useCallback(
        (e: React.FormEvent<HTMLButtonElement>) => {
            dispatch(setLocale(e.currentTarget.value as Locale));
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <Features />
            <h2>{t('i18n-example')}</h2>
            <p>
                <button value="de_DE" onClick={handleLocaleChange}>
                    Deutsch
                </button>
                <button value="en_US" onClick={handleLocaleChange}>
                    English
                </button>
            </p>
        </React.Fragment>
    );
};

export default App;
*/