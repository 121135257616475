import "regenerator-runtime/runtime";
import { all, AllEffect, call, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { Action, IWatchlists, AxiosReturn, RSSNewsReturn, TickerListStateSelector, UserLogin } from '../types';
import { ActionTypes as AdminActionsTypes } from '../actions/admin';
import { ActionTypes as AppActionTypes, dispatchError } from '../actions/app';
import { ActionTypes as AuthActionTypes } from '../actions/auth';
import { ActionTypes as PostActionTypes } from '../actions/post';
import { ActionTypes as SearchActionTypes } from '../actions/search';
import { ActionTypes as SettingsActionTypes } from '../actions/settings';
import { ActionTypes as StocksActionTypes } from '../actions/stocks';
import { ActionTypes as UserActionTypes } from '../actions/user';
import { ActionTypes as WatchlistActionTypes } from '../actions/watchlist';
import { checkFetchStatus, checkTickerListLength, checkTickerListState, checkUserWatchlists, checkWatchlistStatus } from '../selectors';

import { Actions as AuthActions } from '../actions/auth';

import { getTimestamp, log } from '../../../helpers';

import * as saga from './';
import { getRandom } from "helpers";
import { AxiosError, AxiosResponse } from "axios";
import { FEATURED_LIST, INDUSTRY, TRENDING, WATCHLIST } from "components/utils/const";
import { faLineColumns } from "@fortawesome/pro-light-svg-icons";

// Error & Success

function* handleError( title: string, error: AxiosError | null, msg?: string ) {
    const message = error ? error.message : ( msg ? msg : "No message" );
    yield put( dispatchError({ title: title, message: message, delay: 5000 }));
};

function* handleSuccess( title: string, result: any, msg?: string ) { // check correct result type
    const message = result ? result : ( msg ? msg : "No message" );
    yield put( dispatchError({ title: title, message: message, delay: 5000 }));
};


const trendingInterval = 10; // minutes

function delay( duration: number ) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration)
  })
  return promise
}

// improve, START / STOP_POLLING events?
function* setTrendingInterval() {
  while (true) {
      yield delay( (trendingInterval*60)*1000 );
      console.log("Interval LOAD_TRENDING");
      yield put({ type: WatchlistActionTypes.LOAD_TRENDING, payload: { country: "US" }});
      yield handleSuccess( 'LOAD_TRENDING', null, "Success" );
  }
}

//

// App

function* pushNotification() {
  while( true ) {
    const notification: Action = yield take( AppActionTypes.PUSH_NOTIFICATION );
    console.log('rootSaga pushNotification()');
    console.log( notification );
  }
};


/* Search */

function* updateSearchQuery( action: Action ) {
  const query: string = action.payload.query
  const last: string = action.payload.last
  const length: number = query.length

  yield put({ type: SearchActionTypes.SEARCH_QUERY_UPDATE, payload: query });

  // if pasted doesn't work .. check
  if( length == 2 && query !== last ) { // si == 2 ET pas les mêmes 2 lettres que la dernière query!
    const { result, error } = yield call( saga.fetchQuery, query )
    if ( result ) {
      yield put({ type: SearchActionTypes.SEARCH_QUERY_SUCCESS, payload: { query: query, data: result.data } })
    } else {
      yield handleError( 'SEARCH_QUERY_FAIL', error );
    }
  }
}

// Settings

//function* updateSelectedList( action: Action ) {
function* updateTickerBarValue( action: Action ) {
  //while( true ) {
    //const action: Action = yield take( AppActionTypes.UPDATE_TICKERBAR_VALUE );

    console.log('rootSaga updateTickerBarValue()');

    const payload = { ...action.payload };
    const id = payload.id;

    const watchlist: TickerListStateSelector = yield select( checkTickerListState, [ id ] ); // check why it works in updateSelectedWatchlist

    const list = watchlist.list;
    const listNotLoaded = list.filter( item => !item.price );
    //const listNotSubbed = list.filter( item => !item.sub );
    const hasItemsNotLoaded = listNotLoaded.length !== 0;
    //const hasItemsNotSubbed = listNotSubbed.length !== 0;

    if( payload.id !== TRENDING )  { // temporary, if loaded on server side (for now, trending by default) loads getSparkCharts that requires token (not available on server, throws error)

      if( hasItemsNotLoaded ) {
          const items = listNotLoaded.map( item => item.symbol );
          yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS, payload: { tickers: items, type: WATCHLIST, watchlistId: id } });

          while( true ) {
              yield take( WatchlistActionTypes.GET_SPARK_CHARTS_SUCCESS );
              
          }
      } else {
          console.log("TICKERBAR_LOADED 2");
          yield put({ type: AppActionTypes.TICKERBAR_LOADED, payload: list.length });
      }
    } else {

        // Trending
        const trendingTickers = listNotLoaded.map( item => item.symbol );

        yield put({ type: WatchlistActionTypes.GET_TICKER_DATA, payload: { tickers: trendingTickers, type: TRENDING, watchlistId: id } });

        // Check if do that here or dispatch action?
        // Same code as loadTrendingData()
        while( true ) {
          const trendingData: Action = yield take( WatchlistActionTypes.GET_TICKER_DATA_SUCCESS );
          console.log("GET_TICKER_DATA_SUCCESS ??");
          yield all([
            put({ type: WatchlistActionTypes.LOAD_TRENDING_DATA_SUCCESS, payload: { country: "US", data: trendingData.payload } }),
            // dispatched twice?
            put({ type: AppActionTypes.TICKERBAR_LOADED, payload: list.length }),
            put({ type: WatchlistActionTypes.WS_SUBSCRIBE, payload: trendingData.payload })
          ]);
        }
    }
  //}
}


function* tickerBarLoaded( action: Action ) {
  //while( true ) {
    //const action: Action = yield take( AppActionTypes.TICKERBAR_LOADED );
    console.log('rootSaga tickerBarLoaded()');
    //const length: number = yield select( checkTickerListLength );
    const length: number = action.payload;
    console.log("tickerBarLoaded");
    if( length > 7 ) {
        yield delay( 3000 );
        yield put({ type: AppActionTypes.UPDATE_TICKERBAR_STATUS, payload: true });
    }
  //}
}


function* updateSelectedWatchlist( action: Action ) {
  console.log('rootSaga updateSelectedWatchlist()');
  const payload = { ...action.payload };
  const key = payload.key;
  //const name = payload.name;
  const watchlists: IWatchlists[] = yield select( checkWatchlistStatus, key );

  if( watchlists.length !== 0 ) {
    const watchlist = watchlists[0];
    const isLoaded = watchlist.isLoaded;
    const tickers = watchlist.list.map( item => item.symbol );
    //if( !isLoaded ) {
      yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS, payload: { tickers: tickers, type: WATCHLIST, watchlistId: key } });

      while( true ) {
          yield take( WatchlistActionTypes.GET_SPARK_CHARTS_SUCCESS );
          yield put({ type: WatchlistActionTypes.UPDATE_SELECTED_WATCHLIST_SUCCESS, payload: { ...payload } });
      }
    //}
  }
}


// Stocks

function* getStockList() {
  console.log('rootSaga getStockList()');
  const { result, error } = yield call( saga.fetchStocks )
  if ( result ) {
    yield put({ type: StocksActionTypes.GET_ALL_STOCKS_SUCCESS, payload: result.data.dostrik })
  } else {
    yield handleError( 'GET_ALL_STOCKS_FAIL', error );
  }
}


// change to websocket?
function* getOverviewData( action: Action ) {
  console.log('rootSaga getOverviewData()');
  const ticker = action.payload;

    yield put({ type: StocksActionTypes.FETCH_STATUS, payload: true });

    const toFetch = [saga.fetchIntradayData, saga.fetchCompanyData, saga.fetchCompanyCalendar, saga.fetchRSSNews];
    const promises = toFetch.map( fetch => call( fetch, ticker ) );
    const results: { result?: any, error?: any }[] = yield all( promises );

    const [ intraday, company, calendar, news ] = results;

    if( intraday.result ) {
      yield put({ type: StocksActionTypes.GET_INTRADAY_DATA_SUCCESS, payload: intraday.result.data });
    } else {
      yield handleError( 'getOverviewData intraday fail', intraday.error );
    }

    if( company.result ) {
      if( company.result.data.message !== "EMPTY" ) {
        yield put({ type: StocksActionTypes.GET_COMPANY_DATA_SUCCESS, payload: { data: company.result.data } });
      } else {
        yield handleError( 'Company doesn\'t exist', company.error );
      }
    } else {
      yield handleError( 'getOverviewData company data fail', company.error );
    }

    if( calendar.result ) {
      yield put({ type: StocksActionTypes.GET_COMPANY_CALENDAR_SUCCESS, payload: { ticker: ticker, data: calendar.result.data } });
    } else {
      yield handleError( 'getOverviewData calendar fail', calendar.error );
    }

    if( news.result ) {
      yield put({ type: StocksActionTypes.GET_COMPANY_NEWS_SUCCESS, payload: { ticker: ticker, data: news.result } });
    } else {
      yield handleError( 'getOverviewData news fail', news.error );
    }
  
}

function* getRangeData( payload: any ) {
  console.log('rootSaga getRangeData()');
  const { result, error } = yield call( saga.fetchRangeData, payload.data );

  if ( result ) {
    yield put({ type: StocksActionTypes.GET_RANGE_DATA_SUCCESS, payload: { data: result.data, range: payload.data.range } });
  } else {
    yield handleError( 'GET_RANGE_DATA_FAIL', error );
  }
}

// change to websocket?
function* getIntradayData( payload: any ) {
  console.log('rootSaga getIntradayData()');
  const ticker = payload.ticker;
  yield put({ type: StocksActionTypes.FETCH_STATUS, payload: true });
  const { result, error } = yield call( saga.fetchIntradayData, ticker );

  if ( result ) {
    yield put({ type: StocksActionTypes.GET_INTRADAY_DATA_SUCCESS, payload: result.data })
  } else {
    yield handleError( 'GET_INTRADAY_DATA_FAIL', error );
  }
}

function* getCompanyFinancials( action: any ) {
  console.log('rootSaga getCompanyFinancials()');
  const symbol = action.payload;
  const { result, error } = yield call( saga.fetchFinancials, symbol )
  if ( result ) {
    if( result.data.message === 'EMPTY' ) {
      console.log('No financials');
      yield put({ type: StocksActionTypes.GET_COMPANY_FINANCIALS_FAIL, payload: { symbol: symbol, payload: "No data" } });
    } else if( result.data.message === 'SUCCESS' ) {
      yield put({ type: StocksActionTypes.GET_COMPANY_FINANCIALS_SUCCESS, payload: { symbol: symbol, data: result.data.data } });
    }
  } else {
    yield handleError( 'GET_COMPANY_FINANCIALS_FAIL', error );
  }
}

function* getSymbolsByTag( data: any ) {
  const tag = data.payload.tag;
  const loadData = data.payload.loadData;
  const type = data.payload.type;
  const symbol = data.payload.symbol; // if loading in same industry, current symbol to exclude from result list

  const { result, error } = yield call( saga.fetchStocksByTag, { tag: tag } );

  if ( result ) {
    // If we specify that we want data
    if( loadData && result.data.length !== 0 ) {
      switch( type ) {

        case INDUSTRY: {
          const companyList = result.data[0].companies;
          const reducedList = companyList.length > 6 ? getRandom( companyList, 6 ) : companyList;
          const tickers = reducedList.filter( (company: any) => company.ticker !== symbol ).map( (company: any) => company.ticker );

          if( tickers.length !== 0 ) {
            yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS, payload: { tickers: tickers, type: INDUSTRY } });
          }
          break;
        }

        default: {
          yield handleError( "GET_SYMBOLS_BY_TAG_FAIL", null, "No type specified" );
        }

      }
    } else { // If we only want the tickers without data
      yield put({ type: StocksActionTypes.GET_SYMBOLS_BY_TAG_SUCCESS, payload: { tag: data.payload, data: result.data } });
    }
  } else {
    console.table( error );
    yield put({ type: StocksActionTypes.GET_SYMBOLS_BY_TAG_FAIL, payload: { message: error.message } });
    yield handleError( 'GET_SYMBOLS_BY_TAG_FAIL', error );
  }
}

function* getCryptoChart( data: any ) {
  console.log('rootSaga getCryptoChart()');
  const ticker = data.payload.ticker;
  const currency = data.payload.currency;
  const range = data.payload.range;
  const type = data.payload.type;
  const { result, error } = yield call( saga.fetchCryptoChart, { ticker: ticker, currency: currency, range: range, type: type } );

  if ( result ) {
    yield put({ type: StocksActionTypes.GET_CRYPTO_CHART_SUCCESS, payload: { ticker: ticker, data: result.data } });
  } else {
    yield handleError( 'GET_CRYPTO_CHART_FAIL', error );
  }
}


// User

/*
function* userLogin( action: Action ) {
  const userPayload: UserLogin = action.payload;
  const username = userPayload.username;
  if( username ) {
    const { result, error } = yield call( saga.fetchUser, userPayload );
    if ( result ) {
      const res = result.data
      if( res.status === 'OK' ) {
          // manage more than one watchlist?
          // watchlist priority? order?
          const watchlists: IWatchlists[] = res.watchlists;
          yield all([
            //put({ type: WatchlistActionTypes.LOAD_TRENDING, payload: { country: 'US' } }),
            put({ type: WatchlistActionTypes.LOAD_ALL_WATCHLISTS, payload: watchlists }),
            put({ type: UserActionTypes.LOGIN_SUCCESS, payload: res.user })
          ]);
      } else {
        yield put({ type: UserActionTypes.LOGIN_FAIL, payload: result.message });
        yield handleError( 'LOGIN_FAIL 1', null, 'Wrong info' );
      }
    } else {
      yield handleError( 'LOGIN_FAIL 2', error );
    }
  } else {
    yield handleError( 'LOGIN_FAIL 3', null, '?' );

  }
}
*/


// Auth

function* authUser( action: Action ) {  
  const { type, email, password, username, firstLogin } = action.payload;
  let result;
  let error;

  switch( type ) {
    case 'SIGNIN': {
      let { result, error } = yield call( saga.authUserData, { type: type, email: email, password: password } );

      if( result ) {
        console.log('authUser Signin Results:');
        console.log( result );
        yield put({ type: AuthActionTypes.AUTH_USER });
        yield put({ type: UserActionTypes.GET_USER_INFO, payload: { ...result.info, firstLogin: firstLogin === true } });
      } else {
        if( error ) {
          const errorMsg = error.description || error.message || 'Unspecified error';
          console.log( errorMsg );
          //return dispatch(authError(errorMsg));
        }
      }
      break;
    }

    case 'SIGNUP': {
      const { signUpResult, signUpError } = yield call( saga.authUserData, { type: type, email: email, password: password, username: username } );

      if( signUpResult ) {
        let { result, error } = yield call( saga.addUser, { username: username, email: email } );

        if( result ) {
          yield put({ type: UserActionTypes.REGISTER_SUCCESS });
          yield put({ type: AuthActionTypes.AUTH_IN_PROGRESS, payload: { type: 'SIGNIN', email: email, password: password, username: username, firstLogin: true } });
        } else if( error ) {
          console.log( error );
        }
      } else {
        if( signUpError ) {
          const errorMsg = signUpError.description || signUpError.message || 'Unspecified error';
          console.log( errorMsg );
          //return dispatch(authError(errorMsg));
        }
      }
      break;
    }

    default: {
      console.log('Error authUser');
    }
  }
  
}

function* getUserInfo() {

  while( true ) {
    const action: Action = yield take( UserActionTypes.GET_USER_INFO );
    
    console.log('rootSaga getUserInfo()');
    console.log( action );
  
    const firstLogin = action.payload.firstLogin === true;
    const { result, error } = yield call( saga.fetchUserInfo, { email: action.payload.email } );
  
    if ( result ) {
      const res = result.data
      if( res.status === 'OK' ) {
          // watchlist priority? order?
          const watchlists: IWatchlists[] = res.watchlists;
          const userInfo = {
            id: res.user.id,
            username: res.user.username,
            email: action.payload.email,
            avatar: action.payload.picture, // temp
            lastLogin: getTimestamp(action.payload.updated_at),
            firstLogin: firstLogin,
            verified: action.payload.email_verified
          };
  
          yield all([
            //put({ type: WatchlistActionTypes.LOAD_TRENDING, payload: { country: 'US' } }),
            put({ type: WatchlistActionTypes.LOAD_ALL_WATCHLISTS, payload: watchlists }),
            put({ type: UserActionTypes.LOGIN_SUCCESS, payload: userInfo })
          ]);
      } else {
        yield put({ type: UserActionTypes.LOGIN_FAIL, payload: result.message });
        yield handleError( 'LOGIN_FAIL 1', null, 'Wrong info' );
      }
    }
      
    if( error ) {
      const errorMsgUser = error.description || error.message || 'Unspecified error';
      console.log( errorMsgUser );
    }
  }
  
}

// Watchlist


// add proper type
function* updateWatchlist( action: Action ) {
  const { result, error } = yield call( saga.fetchUpdateWatchlist, action.payload );
  if ( result && result.data.message === 'SUCCESS' ) {
    yield put({ type: WatchlistActionTypes.UPDATE_WATCHLIST_SUCCESS, payload: { watchlistId: action.payload.watchlistId, tickerId: action.payload.tickerId, ticker: action.payload.ticker, price: action.payload.price, type: action.payload.type } });
    yield put({ type: WatchlistActionTypes.UPDATE_TRENDING, payload: action.payload.ticker });

    // add to websocket subscriptions
  } else {
    yield handleError( 'UPDATE_WATCHLIST_FAIL', error );
  }
}

function* loadUserWatchlists() {

  while( true ) {
    const action: Action = yield take(UserActionTypes.SET_USER_INFO);
    
    const email = action.payload.email;
    const { result, error } = yield call( saga.fetchUserInfo, { email: email } );
  
    if ( result ) {
      const res = result.data
      if( res.status === 'OK' ) {
          // watchlist priority? order?
          const watchlists: IWatchlists[] = res.watchlists;
          yield all([
            //put({ type: WatchlistActionTypes.LOAD_TRENDING, payload: { country: 'US' } }),
            put({ type: WatchlistActionTypes.LOAD_ALL_WATCHLISTS, payload: watchlists })
          ]);
      } else {
        //yield put({ type: UserActionTypes.LOGIN_FAIL, payload: result.message });
        yield handleError( 'Load user watchlists error', null, result.message );
      }
    }
      
    if( error ) {
      const errorMsgUser = error.description || error.message || 'Unspecified error';
      yield put({ type: WatchlistActionTypes.LOAD_ALL_WATCHLISTS_FAIL, payload: errorMsgUser });
      yield handleError( 'LOAD_ALL_WATCHLISTS_FAIL', errorMsgUser );
    }
  }
}

function* loadAllWatchlists() {
  while( true ) {
    const action: Action = yield take( WatchlistActionTypes.LOAD_ALL_WATCHLISTS );
    console.log('rootSaga loadAllWatchlists()');
    console.log(action);
    const watchlists: IWatchlists[] = action.payload;
    const selectedWatchlist = watchlists[0];
    const watchlistTickers = selectedWatchlist.list.map( item => item.symbol ); // id, symbol
  
    yield put({ type: WatchlistActionTypes.LOAD_ALL_WATCHLISTS_SUCCESS, payload: watchlists });
    yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS, payload: { tickers: watchlistTickers, type: 'WATCHLIST', watchlistId: selectedWatchlist.id } });
  
    while( true ) {
      const watchlistDataAction: Action = yield take( WatchlistActionTypes.LOAD_WATCHLIST_DATA_SUCCESS );
      //yield put({ type: WatchlistActionTypes.WS_SUBSCRIBE, payload: watchlistDataAction.payload.data });
    }
  }
}

function* getSparkCharts() {
  console.log('rootSaga getSparkCharts()');

  while( true ) {
    const action: Action = yield take(WatchlistActionTypes.GET_SPARK_CHARTS);
    const { tickers, type, watchlistId }: { tickers: string[], type: string, watchlistId: string | number } = action.payload;
    const { result, error } = yield call( saga.fetchSparkChartsData, { tickers: tickers.join(), type: type });

    if( result ) {
      const resultType = result.type;
      switch( resultType ) {
        case 'WATCHLIST':
          yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS_SUCCESS, payload: result.data });
          yield put({ type: WatchlistActionTypes.LOAD_WATCHLIST_DATA_SUCCESS, payload: { data: result.data, watchlistId: watchlistId } });
          break;
        case 'INDUSTRY':
          yield put({ type: StocksActionTypes.GET_SYMBOLS_BY_TAG_SUCCESS, payload: { tag: "", data: result.data }});
          break;
        case 'FEATURED':
          yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS_SUCCESS, payload: result.data });
          yield put({ type: WatchlistActionTypes.LOAD_FEATURED_DATA_SUCCESS, payload: result.data });
          break;
        default:
          yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS_SUCCESS, payload: result.data });
      }

    } else {
      yield handleError( 'GET_RANGE_DATA_FAIL', null, 'Failed to load '+error.type+' spark chart, check console.log' );
      console.table( error );
    }
  }
  
}

function* getTickerData() {
  while( true ) {
    console.log('rootSaga getTickerData()');
    const action: Action = yield take( WatchlistActionTypes.GET_TICKER_DATA );

    const { tickers, type, watchlistId }: { tickers: string[], type: string, watchlistId?: string | number } = action.payload;
    const { result, error } = yield call( saga.fetchTickerData, { tickers: tickers.join(), type: type });
  
    if( result ) {
      yield put({ type: WatchlistActionTypes.GET_TICKER_DATA_SUCCESS, payload: result.data });
    } else {
      console.log('getTickerData error');
      yield handleError( 'GET_TICKER_DATA_FAIL', error );
    }
  }
}


function* loadFeatured() {
  // to check.
  // calls data twice
    // check if comment is fixed?

    console.log('rootSaga loadFeatured()');

  const list = FEATURED_LIST.join(",");

  const { result, error } = yield call( saga.fetchFeaturedList, { symbols: list } );
  
  if( result ) {
    const featuredSymbols = result.data.map( (item: { id: number, ticker: string }) => item.ticker );
    const featuredItems = result.data.map( (item: { id: number, ticker: string }) => ({ id: item.id, symbol: item.ticker }));

      // ###########################
      // why FETCH_SPARK_CHARTS? already have data with fetchFeaturedCall. check
      yield put({ type: WatchlistActionTypes.GET_SPARK_CHARTS, payload: { tickers: featuredSymbols, type: "FEATURED" } });
      // ###########################

      yield put({ type: WatchlistActionTypes.LOAD_FEATURED_SUCCESS, payload: featuredItems });
      while( true ) {
        const featuredData: Action = yield take( WatchlistActionTypes.LOAD_FEATURED_DATA_SUCCESS );
        yield put({ type: WatchlistActionTypes.WS_SUBSCRIBE, payload: featuredData.payload });
      }
  } else {
    yield handleError( "LOAD_FEATURED_FAIL", error );
  }
}

function* loadTrending() {
  console.log('rootSaga loadTrending() test');

  while( true ) {
    console.log('rootSaga loadTrending()');

    const action: Action = yield take( WatchlistActionTypes.LOAD_TRENDING );
    
    const country = action.payload.country;

    const { result, error } = yield call( saga.fetchTrendingList, { country: country });

    if( result && result.data ) {
        const trendingTickers: string[] = result.data.map( (item: { id: number, symbol: string }) => item.symbol );
        yield all([
          put({ type: WatchlistActionTypes.LOAD_TRENDING_SUCCESS, payload: { country: country, data: trendingTickers } }),
          put({ type: WatchlistActionTypes.LOAD_TRENDING_DATA, payload: { country: country, tickers: trendingTickers } }),
          //put({ type: WatchlistActionTypes.GET_TICKER_DATA, payload: { tickers: trendingSymbols, type: 'TRENDING', watchlistId: country } }),
        ]);
  
        console.log("TEST :)");
  
        // LOAD_TRENDING_DATA calls GET_TICKER_DATA, then if success, GET_TICKER_DATA_SUCCESS is dispatched and we catch it here
  
        // still runs even if race in loadTrendingData times out? check...
  
        // temp, remove so data fetch is done in LOAD_TRENDING_DATA
        
  
          //yield put({ type: WatchlistActionTypes.LOAD_TRENDING_DATA_SUCCESS, payload: { country: country } });
          
    } else {
      // fix  error appears twice. to replicate, reduce timeout
      yield handleError( 'LOAD_TRENDING_FAIL', error );
    }
  }
  
}

// called on app load, because trending list fetched on server side. see if works correctly / keep using this way or need to optimize
// maybe remove LOAD_TRENDING_DATA from other function and call this?
// check country, add country to state
export function* loadTrendingData() {

  console.log( "rootSaga loadTrendingData()" );

  while( true ) {

    const action: Action = yield take(WatchlistActionTypes.LOAD_TRENDING_DATA);
    const tickers: string = action.payload.tickers;
    const country: "US" | "CA" = action.payload.country;

    // change "trending" / any other type to const - check
    //const { result, error } = yield call( saga.fetchTickerData, { tickers: tickers, type: "trending" }); // type = temp?

    // test timeout
    // 0.5sec to simulate timeout error;
    const trendingDataTimeout = 5; // match with axios timeout (global const?) or remove in saga?

    console.log( "rootSaga loadTrendingData() 2" );

    const { stocks, timeout } = yield race({
      stocks: call( saga.fetchTickerData, { tickers: tickers, type: "trending" }),
      timeout: delay(trendingDataTimeout * 1000)
    });

    console.log( "rootSaga loadTrendingData() 3" );
    console.log(stocks);
    console.log(timeout);

    if( timeout ) {
      // add action dispatch in handle error?
      // dispatches twice? check
      yield handleError( "LOAD_TRENDING_DATA_FAIL", null, "Timeout" );
      yield put({ type: "LOAD_TRENDING_DATA_FAIL", payload: { error: { type: "Timeout", message: "Timeout exceeded" }, country: country } });
      return;

    }

    if( stocks.result ) {

      console.log('LOAD_TRENDING_DATA_SUCCESS 2');
      yield put({ type: WatchlistActionTypes.GET_TICKER_DATA_SUCCESS, payload: stocks.result.data });
      yield put({ type: WatchlistActionTypes.LOAD_TRENDING_DATA_SUCCESS, payload: { country: country } });
      //yield put({ type: WatchlistActionTypes.WS_SUBSCRIBE, payload: trendingData.payload })

    } else if( stocks.error ) {

      console.log( stocks.error );
      yield handleError( 'LOAD_TRENDING_DATA_FAIL', stocks.error );
      yield put({ type: "LOAD_TRENDING_DATA_FAIL", payload: { error: { type: "Network", message: stocks.error.message }, country: country } });

    }
  }

}



// Home News
// ##############################################################
// Currently timeout only works client side, add to server side!
// ##############################################################

function* getNewsList() {
  // Test Timeout
  
  console.log( "rootSaga getNewsList()" );
  const newsTimeout = 5;

  while(true) {

    yield take(AppActionTypes.LOAD_NEWS);

    const { news, timeout } = yield race({
      news: call( saga.fetchNewsList, 1 ),
      timeout: delay(newsTimeout * 1000)
    });
  
    if( timeout ) {
      // add action dispatch in handle error?
      yield handleError( "LOAD_NEWS_FAIL", null, "Timeout" );
      yield put({ type: "LOAD_NEWS_FAIL", payload: { error: { type: "Timeout", message: "Timeout exceeded" } } });
      
    } else {
  
      if( news.result ) {
  
        yield put({ type: AppActionTypes.LOAD_NEWS_SUCCESS, payload: { source: news.result.copyright, list: news.result.items } });
  
      } else if( news.error ) {
  
        console.log( news.error );
        yield handleError( 'LOAD_NEWS_FAIL', news.error );
        yield put({ type: "LOAD_NEWS_FAIL", payload: { error: { type: "Network", message: news.error.message } } });
  
      }
  
    }

  }

  /*

  const { result, error } = yield call( saga.fetchNewsList, 1 );
  if ( result ) {
    yield put({ type: AppActionTypes.LOAD_NEWS_SUCCESS, payload: { source: result.copyright, list: result.items } });
  }

  if( error ) {
    yield handleError( 'LOAD_NEWS_FAIL', error );
  }
  */
}

function* getBlogList() {
  console.log( "rootSaga getBlogList()" );
  const blogsTimeout = 5;

  while(true) {

    yield take(AppActionTypes.LOAD_BLOGS);

    // Test Timeout
    const { blogs, timeout } = yield race({
      blogs:  call( saga.fetchNewsList, 2 ),
      timeout: delay(blogsTimeout * 1000)
    });

    if( timeout ) {
      // add action dispatch in handle error?
      yield handleError( "LOAD_BLOGS_FAIL", null, "Timeout" );
      yield put({ type: "LOAD_BLOGS_FAIL", payload: { error: { type: "Timeout", message: "Timeout exceeded" } } });
      
    } else {

      if( blogs.result ) {

        yield put({ type: AppActionTypes.LOAD_BLOGS_SUCCESS, payload: { source: blogs.result.copyright, list: blogs.result.items } });

      } else if( blogs.error ) {

        console.error( blogs.error );
        yield handleError( 'LOAD_BLOGS_FAIL', blogs.error );
        yield put({ type: "LOAD_BLOGS_FAIL", payload: { error: { type: "Network", message: blogs.error.message } } });

      }

    }
}

  /*
  const { result, error } = yield call( saga.fetchNewsList, 2 );
  if ( result ) {
    yield put({ type: AppActionTypes.LOAD_BLOGS_SUCCESS, payload: { source: result.copyright, list: result.items } });
  }

  if( error ) {
    yield handleError( 'LOAD_BLOGS_FAIL', error );
  }
  */
}

/* Post */
function* loadColorPalette({ type, color }) {
  const { result, error } = yield call( saga.fetchColorPalette, { type, color} );
    if ( result ) {
      yield put({ type: PostActionTypes.SET_COLOR_PALETTE, payload: result.data.result });
    }

    if( error ) {
      yield handleError( 'GET_COLOR_PALETTE_FAIL', error );
    }
}

function* getColorPalette() {

  while(true) {
    const action: Action = yield take(PostActionTypes.GET_COLOR_PALETTE);
    yield fork( loadColorPalette, action.payload );
  }
}



// add proper type
function* getStats() {
  const { result, error } = yield call( saga.fetchStats );
  if ( result ) {
    yield put({ type: AdminActionsTypes.LOAD_STATS_SUCCESS, payload: result.data.dostrik });

    // add to websocket subscriptions
  } else {
    yield handleError( AdminActionsTypes.LOAD_STATS_FAIL, error );
  }
}



/*  ################################################
    ################################################  */

function* watchAdmin() {
  yield takeLatest( AdminActionsTypes.LOAD_STATS, getStats );
}

function* watchPost() {
  // switch back to takeLatest, no necessary on page load.
  yield fork( getColorPalette );
}

function* watchNews() {
  yield fork( getNewsList );
  yield fork( getBlogList );
}

function* watchSearch() {
  yield takeEvery( SearchActionTypes.SEARCH_QUERY, updateSearchQuery );
}

function* watchSettings() {
  //yield fork( updateTickerBarValue );
  yield takeLatest( AppActionTypes.UPDATE_TICKERBAR_VALUE, updateTickerBarValue );
  yield takeLatest( AppActionTypes.TICKERBAR_LOADED, tickerBarLoaded );
}

function* watchStocks() {
  yield fork( loadTrending );
  yield fork( loadTrendingData );

  yield fork( getSparkCharts );
  yield fork( getTickerData );

  yield fork( loadAllWatchlists );

  yield takeLatest( WatchlistActionTypes.UPDATE_SELECTED_WATCHLIST, updateSelectedWatchlist );

  yield takeLatest( StocksActionTypes.GET_OVERVIEW_DATA, getOverviewData );
  yield takeLatest( StocksActionTypes.GET_SYMBOLS_BY_TAG, getSymbolsByTag );
  yield takeLatest( StocksActionTypes.GET_RANGE_DATA, getRangeData );

  // check if ok

  yield takeLatest( WatchlistActionTypes.UPDATE_WATCHLIST, updateWatchlist );
  yield takeEvery( StocksActionTypes.GET_COMPANY_FINANCIALS, getCompanyFinancials );

  /*
  yield takeEvery( StocksActionTypes.GET_ALL_STOCKS, getStockList );
  yield takeEvery( StocksActionTypes.GET_OVERVIEW_DATA, getOverviewData );
  yield takeEvery( StocksActionTypes.GET_INTRADAY_DATA, getIntradayData );
  yield takeEvery( StocksActionTypes.GET_CRYPTO_CHART, getCryptoChart );
  
  yield takeEvery( WatchlistActionTypes.UPDATE_WATCHLIST, updateWatchlist );
  
  yield takeLatest( WatchlistActionTypes.LOAD_FEATURED, loadFeatured );
  */
}

function* watchUser() {
  //yield takeLatest( UserActionTypes.LOGIN_ATTEMPT, userLogin );
  yield fork( getUserInfo );
  yield fork( loadUserWatchlists );
}

function* watchNotification() {
  yield fork( pushNotification );
}

function* watchAuth() {
  yield takeLatest( AuthActionTypes.AUTH_IN_PROGRESS, authUser );
}

export function* rootSaga() {
  yield all([
      fork(watchAdmin),
      fork(watchAuth),
      // done
      fork(watchNews),
      // started
      fork(watchStocks),
      fork(watchNotification),
      fork(watchSettings),
      fork(watchUser),
      fork(watchSearch),

      /* to do
      fork(watchPost),
      fork(watchStocks),
      fork(setTrendingInterval)
      */
  ]);
};

function* init() {
  yield fork( loadTrending );
  yield fork( loadTrendingData );
  yield fork( getSparkCharts );
  yield fork( getTickerData );
  yield fork( loadAllWatchlists );
}

// Test to fix duplicate running sagas. root called by server side rendering
// duplicate rendering when saving app and hot reload, maybe not necessary
export function* root() {
  yield fork(init);
};