import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Dropdown } from 'react-bootstrap';
import { Actions as SettingsActions } from "store/app/actions/settings";

import {
    FeaturedList,
    FeaturedItem,
    Icon,
    Info,
    InfoRow,
    Change,
    Symbol,
    Price,
    StyledDropdown,
    FeaturedOptions,
    FeaturedLink,
    HideMedium,
    ShowMedium,
    Border,
} from 'styles';
import { LoadingFeatured } from 'components/display/Loading';

const LightweightChart = loadable(() => import('components/charts/LightweightChart'));

import { RootState } from 'store';
import { checkFeaturedListState } from 'store/app/selectors';
import { ITickerData, StockStatus } from 'store/app/types';
import { formatDecimals } from 'helpers';
import { SettingsIcon, StatusIcon } from 'components/icons';

export function Featured(): JSX.Element {
    const dispatch = useDispatch();

    //const [showOptions, setShowOptions] = useState<boolean>(false);
    const [slideIndex, setSlideIndex] = useState<number>(0);

    const user = useSelector((state: RootState) => state.user);
    const featured = useSelector(checkFeaturedListState);
    const { settings, isFetching, isLoaded, list } = featured;
    const slidesToShow = 7;

    /*
    useEffect(() => {
        if (user.isLoggedIn && !featured.isLoaded && !featured.isFetching) {
            console.log('Load featured');
            dispatch({ type: 'LOAD_FEATURED' });
        }
    }, []);
    */

    const handleSlick = (oldIndex: number, newIndex: number) => {
        console.log( oldIndex );
        console.log( newIndex );
        // handle screen width? currently for full screen, so 5 slides;
        setSlideIndex( newIndex );
    };

    var sliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: slidesToShow,
        //variableWidth: true,
        beforeChange: handleSlick,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const handleChange = (key: "HIDE" | "SHOW") => {
        // hide / show featured chart
        dispatch(SettingsActions.handleChart( key === "SHOW" ));
    };

    return (
        <FeaturedList>
            <FeaturedOptions>
                <StyledDropdown onSelect={handleChange} size="0.8rem" padding="3px 6px">
                    <Dropdown.Toggle className="btn-dostrik optnTicker">
                        {
                            // check - to improve (icon) 
                        }
                        <SettingsIcon />

                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            settings.showChart ? (
                                <Dropdown.Item key="HIDE" eventKey="HIDE">
                                    Hide Chart
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item key="SHOW" eventKey="SHOW">
                                    Show Chart
                                </Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </StyledDropdown>
            </FeaturedOptions>

            <React.Fragment>
                {
                    // TEMP, ONLY FOR DEMO
                        // ^ check why this comment exists 
                    !isLoaded ? (
                        <LoadingFeatured nb={slidesToShow} />
                    ) : (
                        <Slider {...sliderSettings}>
                            {list.map((item, index) => {
                                const priceDisplay = item.price ? `$${formatDecimals(item.price)}` : null;
                                const changePercent = item.changePercent;
                                //const changePercent = parseFloat(item.changePercent);
                                const changePrice = formatDecimals(item.changePrice);
                                //const changePercentDisplay = changePercent ? changePercent+"%" : "";
                                const changePercentDisplay = formatDecimals(changePercent) + '%';
                                //const statusDisplay = changePercent ? ( Math.sign( changePercent ) === 1 ? "bullish" : "bearish" ) : "";
                                const statusDisplay = item.status;
                                //const showStatusIcon = item.data && item.data.length <= 20;

                                const fitContent = item.data && item.data.length >= 38; // check right number / test setting bar spacing
                                const showLeftBorder = index !== slideIndex;
                                const showRightBorder = index !== (slideIndex+(slidesToShow-1));

                                return (
                                    <FeaturedItem key={`featured-${item.ticker}`}>

                                        <FeaturedLink
                                            to={'/symbol/' + item.ticker}
                                            $showLeftBorder={showLeftBorder}
                                            $showRightBorder={showRightBorder}
                                            >
                                            
                                            <FeaturedStatus
                                                key={`status-${item.ticker}`}
                                                item={item}
                                                currentPrice={item.price}
                                                status="bullish"
                                                show={settings.showChart}
                                                //showStatusIcon={showStatusIcon}
                                                fitContent={fitContent}
                                                />

                                            <Info>
                                                <InfoRow>
                                                    <Symbol>{item.name}</Symbol>
                                                    <Change className={statusDisplay}>
                                                        {changePrice}
                                                    </Change>
                                                </InfoRow>
                                                <InfoRow>
                                                    <Price>{priceDisplay}</Price>
                                                    <Change className={statusDisplay}>
                                                        {changePercentDisplay}
                                                    </Change>
                                                </InfoRow>
                                            </Info>
                                        </FeaturedLink>
                                    </FeaturedItem>
                                );
                            })}
                        </Slider>
                    )
                }
            </React.Fragment>
        </FeaturedList>
    );
}

const FeaturedStatus = (props: {
    item: ITickerData;
    status: StockStatus;
    show: boolean;
    fitContent: boolean;
    currentPrice: number | null;
}) => {
    const { item, status, show, fitContent, currentPrice } = props;

    if (!show) return <></>;

    if( currentPrice === null || item.previousClose === null )
        return <div>Error</div>;

    // check type
    return (
        <React.Fragment>

            <ShowMedium className="mb-2">
                <LightweightChart
                    ticker={item.ticker as string}
                    previousClosePrice={item.previousClose}
                    currentPrice={currentPrice}
                    fitContent={fitContent}
                    height={60}
                    data={item.data.map((value) => value)}
                    />
            </ShowMedium>
           
            {
                // show status icon if setting in state. to do
                // show status icon if responsive? to do
                /*
                    <StatusIcon
                        status={props.status}
                        size="2x"
                        className="featured-status-icon"
                        />

                */
            }

            <HideMedium>
                <StatusIcon
                    status={props.status}
                    size="2x"
                    className="featured-status-icon"
                    />
            </HideMedium>

        </React.Fragment>
    );
};
