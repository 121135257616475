import { Actions as AppActions } from './app';
import { Actions as SearchActions } from './search';
import { Actions as SettingsActions } from './settings';
import { Actions as StocksActions } from './stocks';
import { Actions as UserActions } from './user';
import { Actions as WatchlistActions } from './watchlist';

export const Actions = {
    ...AppActions,
    ...SearchActions,
    ...SettingsActions,
    ...StocksActions,
    ...UserActions,
    ...WatchlistActions
};