import React from 'react';
import { CalendarProps } from "store/app/types/props";
import { BorderTitle, Flex } from 'styles';
import { Loading } from './Loading';

export default function Calendar( props: CalendarProps ): JSX.Element {
    const { list, isLoaded } = props;
    const isEmpty = list.length === 0;
    let i: number = 1;

    if( !isLoaded )
        return (
            <React.Fragment>
                <BorderTitle>
                    <h2>Calendar</h2>
                </BorderTitle>
                <Flex justify="center">
                    <Loading />
                </Flex>
            </React.Fragment>
        );

    if( isEmpty )
        return (
            <React.Fragment>
                <BorderTitle>
                    <h2>Calendar</h2>
                </BorderTitle>
                <Flex justify="center">
                    <div>No data</div>
                </Flex>
            </React.Fragment>
        );


    const nextReportDate = list[0].reportDate;
    const nextReportDateEstimate = list[0].estimate;
    let nextReportDates = list.filter( (item, index) => index !== 0 ).map( item => <li key={"CAL_"+item.symbol+"_"+item.reportDate}>{item.reportDate}</li> );

    return (
        <React.Fragment>
            <BorderTitle>
                <h2>Calendar</h2>
            </BorderTitle>
            <Flex direction="column">
                <React.Fragment>
                    <div>Next report date: {nextReportDate}</div>
                    <div>Estimate: {nextReportDateEstimate}</div>
                    <span>___________</span>
                    <ul>
                        {nextReportDates}
                    </ul>
                </React.Fragment>
            </Flex>
        </React.Fragment>
    )
}