import * as React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { configureStore } from '../shared/store';
import { HelmetProvider } from 'react-helmet-async';
import IntlProvider from '../shared/i18n/IntlProvider';
import createHistory from '../shared/store/history';
import App from '../shared/App';

//import { ActiveTheme, darkTheme, lightTheme } from '../shared/styles/Themes'; // check if can use ActiveTheme instead of if/else with dark/light theme
//import 'bootstrap/scss/bootstrap.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'simplebar/dist/simplebar.css';
import 'react-toggle/style.css';
import '../shared/index.scss';
import { rootSaga } from 'store/app/sagas/rootSaga';

// Create/use the store
// history MUST be passed here if you want syncing between server on initial route
const store =
    window.store ||
    configureStore({
        initialState: window.__PRELOADED_STATE__,
    });

const state = store.getState();
const history = createHistory();
//const selectedTheme = state.settings.theme;
//const theme = selectedTheme === 'dark' ? darkTheme : lightTheme;

store.runSaga(rootSaga);

hydrate(
    <Provider store={store}>
        <Router history={history}>
            <IntlProvider>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </IntlProvider>
        </Router>
    </Provider>,
    document.getElementById('app')
);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    if (!window.store) {
        window.store = store;
    }
}
