import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notice, SparkItem, LoggedOutOverlay } from 'styles';

export function LoggedOutMessage(props: {url: string}): JSX.Element {
    return (
        <Row className="py-4">
            <Col lg={{ span: 7, offset: 1 }}>
                <h2>What is dostrik?</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ut cursus lacus. In in nibh dolor. Phasellus ultrices varius lectus, quis dictum est porta vel. Sed sollicitudin nisi eget libero ornare, id varius nunc auctor. Aenean ac nisl a mauris malesuada tincidunt. Nulla rutrum in odio vitae posuere. Duis mauris orci, fermentum ac odio vel, venenatis tristique est. Fusce suscipit mi eu mi tincidunt.
                </p>
                <p>La lacinia lectus congue. Maecenas interdum augue non tempor tincidunt. Vivamus blandit lacus accumsan volutpat rutrum. Proin maximus orci gravida neque elementum, eu facilisis sapien venenatis.</p>
            </Col>
            <Col lg="3" className="text-center">
                <img src={props.url+"public/images/logo192.png"} srcSet={props.url+"public/images/logo192.png 2x"} style={{width: '192px'}} />
            </Col>
        </Row>
    );
}

export function WatchlistLoggedOut(): JSX.Element {
    return (
        <div className="watchlist loggedOut">
            <Notice>
                <FontAwesomeIcon icon={['far','sign-in-alt']} /> 
                Log in to view your watchlists
            </Notice>
            <LoggedOutOverlay>
                <RandomSparkItem quantity={2} />
            </LoggedOutOverlay>
        </div>
    );
}

function RandomSparkItem(props: { quantity?: number }) {
    const nb = props.quantity ? props.quantity : 1;
    const tSpark = [];
    for( let i = 0; i<nb; i++) {
        const status = (Math.random()<0.5) ? 'bullish' : 'bearish';
        tSpark.push({ ticker: 'AAA', price: 123.45, percent: 12.3, status: status });
    }

    return ( 
        <React.Fragment>
            {
                tSpark.map( (item, i) => (
                    <SparkItem key={"RAND_SPARK_"+i}>
                        <div className="tickerDisplay">
                            <a href="#">{item.ticker}</a>
                        </div>
                        <div>
                            <svg className={"sparkline "+item.status} width="75" height="30" strokeWidth="2">
                                <path className="sparkline--fill" d="M4 26 L 4 26 L 6.68 16.92 L 9.36 14.27 L 12.040000000000001 13.61 L 14.72 13.37 L 17.4 10.83 L 20.080000000000002 12.64 L 22.76 7.42 L 25.44 6.28 L 28.12 5.14 L 30.8 8.56 L 33.480000000000004 8.07 L 36.160000000000004 4.39 L 38.84 4.49 L 41.52 4 L 44.2 7.59 L 46.88 7.26 L 49.56 12.15 L 52.24 15.08 L 54.92 15.41 L 57.6 15.77 L 60.28 16.87 L 62.96 17.36 L 65.64 18.34 L 68.32000000000001 20.48 L 71 18.67 V 30 L 4 30 Z" stroke="none"></path>
                                <path className="sparkline--line" d="M4 26 L 4 26 L 6.68 16.92 L 9.36 14.27 L 12.040000000000001 13.61 L 14.72 13.37 L 17.4 10.83 L 20.080000000000002 12.64 L 22.76 7.42 L 25.44 6.28 L 28.12 5.14 L 30.8 8.56 L 33.480000000000004 8.07 L 36.160000000000004 4.39 L 38.84 4.49 L 41.52 4 L 44.2 7.59 L 46.88 7.26 L 49.56 12.15 L 52.24 15.08 L 54.92 15.41 L 57.6 15.77 L 60.28 16.87 L 62.96 17.36 L 65.64 18.34 L 68.32000000000001 20.48 L 71 18.67" fill="none"></path>
                            </svg>
                        </div>
                        <div className="valueDisplay">
                            <span>{item.price}</span>
                            <span>{item.percent}</span>
                        </div>
                    </SparkItem>
                    )
                )
            }
        </React.Fragment>
    );
}