import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../display/Loading';
import { Symbol } from '../../display/Symbol';

// import Marquee from "react-fast-marquee";
import Malarquee from 'react-malarquee';
import { TickerBarListProps } from 'store/app/types/props';
import { checkTickerListState, checkUserState } from 'store/app/selectors';
import { TickerBarListData } from 'store/app/types';
import { formatDecimals } from 'helpers';
import { DostrikHeaderButton, Flex } from 'styles';
import { ReloadIcon } from 'components/icons';
import { TickerBarError } from './styles';

export function TickerBarList(props: TickerBarListProps): JSX.Element {
    // old comments, check :
    // no logic in component, change for "checkTickerListState" that supplies currently selected list
    //const watchlist = useSelector( state => checkWatchlistState( state ) );
    const dispatch = useDispatch();

    let isEmpty = false;
    let list: TickerBarListData[] = [];

    const user = useSelector(checkUserState);
    const tickerList = useSelector(checkTickerListState);

    const isLoggedIn = user.isLoggedIn;
    const selected = tickerList.selectedList ? tickerList.selectedList.id : 0;

    const isLoadedList = tickerList.isLoadedList;
    const isLoadedData = tickerList.isLoadedData;
    const displayValue = tickerList.displayValue;
    const hasError = tickerList.hasError;

    const fill = tickerList.list.length > 6;

    if (isLoadedList) {
        if (tickerList.list.length !== 0) {
            list = tickerList.list.map((item) => {
                const value = formatDecimals(item.price);
                const changePercent = `${(item.changePercent !== undefined) ? formatDecimals(item.changePercent) : ``}`;
                const watching = item.watching === true;
                //const status = changePercent ? ( Math.sign( changePercent ) === 1 ? 'bullish' : 'bearish' ) : '';
                const status = item.status;
                const displayPrice = `$${value}`;
                const displayPercent =  `${changePercent}%`;

                return {
                    ticker: item.symbol,
                    name: item.name,
                    watching: watching,
                    price: value,
                    displayPrice: displayPrice,
                    displayPercent: displayPercent,
                    // change for percent OR price
                    displayChange: displayPercent,
                    status: status,
                };
            });
        } else {
            isEmpty = true;
        }
    }

    console.log("Render Malarquee");

    // if dispatch LOAD_TRENDING_DATA, it dispatches two UPDATE_TICKERBAR_STATUS actions, check
    //const handleReload = () => dispatch({ type: "LOAD_TRENDING_DATA", payload: { country: "US", tickers: list.map(item => item.ticker) } });

    // if dispatch LOAD_TRENDING, get country from state / settings
    const handleReload = () => dispatch({ type: "LOAD_TRENDING", payload: { country: "US" }});

    // check if useful?
    !isLoadedList && !isEmpty && isLoggedIn && <Loading size="small" className="pl-3" />;

    // check if working
    isEmpty && <span className="pl-30">Watchlist is empty</span>;

    const containerClasses = `marquee${ hasError ? ` pr-100` : `` }`;

    return (
      <React.Fragment>
          {
            hasError ? (
                <TickerBarError>

                  <DostrikHeaderButton onClick={handleReload}>
                      <span>Failed to load data</span>&nbsp;
                      <ReloadIcon />
                  </DostrikHeaderButton>
                </TickerBarError> 
            ) : null
          }
          <Malarquee 
              key={props.rand} 
              hoverToPause={true} 
              rate={props.play ? 40 : 0} 
              fill={fill} 
              className={containerClasses}
              >
              <Flex grow={"1"} overflow={"hidden"}>
                  {list.map((item) => {
                      return (
                          <Symbol
                              key={item.ticker}
                              data={item}
                              isLoading={!isLoadedData}
                              value={item.price}
                              showValue={!hasError}
                            />
                      );
                  })}
              </Flex>
          </Malarquee>
      </React.Fragment>
    );

    /*
    if ( !isLoadedList && !isEmpty && isLoggedIn ) {
      return <Loading size="small" className="pl-3" />
    } else {
      if( isEmpty ) {
        return <span className="pl-30">Watchlist is empty</span>
      } else {
        return ( 
          <Malarquee hoverToPause={true} rate={ props.play ? 40 : 0 } fill={fill}>
            <div className="pl-30" style={{ display: 'flex', width:'100%' }}>
              { 
                list.map( (item) => <Symbol key={item.ticker} data={item} isLoading={!isLoadedData} value={item.price} /> ) 
              }
            </div>  
          </Malarquee>
        )
      }
    }
    */
}
