import { Action } from '../types';
//import {  } from '../types/props';

export const ActionTypes = {
    GET_OVERVIEW_DATA: "GET_OVERVIEW_DATA",
    GET_OVERVIEW_DATA_SUCCESS: "GET_OVERVIEW_DATA_SUCCESS",
    GET_OVERVIEW_DATA_FAIL: "GET_OVERVIEW_DATA_FAIL",
    
    GET_RANGE_DATA: "GET_RANGE_DATA",
    GET_RANGE_DATA_SUCCESS: "GET_RANGE_DATA_SUCCESS",
    GET_RANGE_DATA_FAIL: "GET_RANGE_DATA_FAIL",

    GET_COMPANY_INFO: "GET_COMPANY_INFO",
    GET_COMPANY_INFO_SUCCESS: "GET_COMPANY_INFO_SUCCESS",
    GET_COMPANY_INFO_FAIL: "GET_COMPANY_INFO_FAIL",

    GET_COMPANY_STATS_SUCCESS: "GET_COMPANY_STATS_SUCCESS",
    GET_COMPANY_STATS_FAIL: "GET_COMPANY_STATS_FAIL",

    GET_COMPANY_DATA: "GET_COMPANY_DATA",
    GET_COMPANY_DATA_SUCCESS: "GET_COMPANY_DATA_SUCCESS",
    GET_COMPANY_DATA_FAIL: "GET_COMPANY_DATA_FAIL",

    GET_COMPANY_CALENDAR_SUCCESS: "GET_COMPANY_CALENDAR_SUCCESS",
    GET_COMPANY_CALENDAR_FAIL: "GET_COMPANY_CALENDAR_FAIL",

    GET_SYMBOLS_BY_TAG: "GET_SYMBOLS_BY_TAG",
    GET_SYMBOLS_BY_TAG_SUCCESS: "GET_SYMBOLS_BY_TAG_SUCCESS",
    GET_SYMBOLS_BY_TAG_FAIL: "GET_SYMBOLS_BY_TAG_FAIL",

    FETCH_STATUS: "FETCH_STATUS",

    GET_ALL_STOCKS: "GET_ALL_STOCKS",
    GET_ALL_STOCKS_SUCCESS: "GET_ALL_STOCKS_SUCCESS",
    GET_ALL_STOCKS_FAIL: "GET_ALL_STOCKS_FAIL",

    GET_INTRADAY_DATA: "GET_INTRADAY_DATA",
    GET_INTRADAY_DATA_SUCCESS: "GET_INTRADAY_DATA_SUCCESS",
    GET_INTRADAY_DATA_FAIL: "GET_INTRADAY_DATA_FAIL",

    GET_COMPANY_NEWS_SUCCESS: "GET_COMPANY_NEWS_SUCCESS",
    GET_COMPANY_NEWS_FAIL: "GET_COMPANY_NEWS_SUCCESS",

    GET_COMPANY_FINANCIALS: "GET_COMPANY_FINANCIALS",
    GET_COMPANY_FINANCIALS_SUCCESS: "GET_COMPANY_FINANCIALS_SUCCESS",
    GET_COMPANY_FINANCIALS_FAIL: "GET_COMPANY_FINANCIALS_FAIL",

    GET_CHART_DATA_SUCCESS: "GET_CHART_DATA_SUCCESS",
    GET_CHART_DATA_FAIL: "GET_CHART_DATA_FAIL",

    GET_CRYPTO_CHART: "GET_CRYPTO_CHART",
    GET_CRYPTO_CHART_SUCCESS: "GET_CRYPTO_CHART_SUCCESS",
    GET_CRYPTO_CHART_FAIL: "GET_CRYPTO_CHART_FAIL",

    WS_SUBSCRIBE_CRYPTO: "WS_SUBSCRIBE_CRYPTO",
    WS_UNSUBSCRIBE_CRYPTO: "WS_UNSUBSCRIBE_CRYPTO",

    TRADING_STATUS: "TRADING_STATUS",
};


// CHECK IF ALL ACTIONS STILL USED

export const getAllStocks = (): Action => ({
    type: ActionTypes.GET_ALL_STOCKS
});

// Replace ticker for payload... add return type
export const getIntradayData = ( ticker: string ) => ({
    type: ActionTypes.GET_INTRADAY_DATA,
    ticker: ticker
});

export const getSymbolsByTag = ( tag: string, load: boolean, type: string | null = null, symbol: string | null = null ): Action => ({
    type: ActionTypes.GET_SYMBOLS_BY_TAG,
    payload: {
        tag: tag,
        loadData: load,
        type: type,
        symbol: symbol
    }
});

// Replace ticker for payload... add return type
export const getCompanyData = ( ticker: string ) => ({
    type: ActionTypes.GET_COMPANY_DATA,
    ticker: ticker
});

// Replace ticker for payload... add return type
export const getOverviewData = ( ticker: string ): Action => ({
    type: ActionTypes.GET_OVERVIEW_DATA,
    payload: ticker
});

// Replace data for payload... add return type
export const getRangeData = ( ticker: string, range: string ) => ({
    type: ActionTypes.GET_RANGE_DATA,
    data: {
        ticker: ticker,
        range: range
    }
});

export const loadCryptoChart = ( ticker: string, currency: string, range: string, type: string = 'DEFAULT' ): Action => ({
    type: ActionTypes.GET_CRYPTO_CHART,
    payload: {
        ticker: ticker,
        currency: currency,
        range: range,
        type: type
    }
});

export const Actions = {
    getOverviewData: getOverviewData,
    getRangeData: getRangeData,
    getSymbolsByTag: getSymbolsByTag,
    
};