import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DostrikHeaderButton, Flex, MarketStatusBlob, StyledDropdown } from '../../../styles';
import { TickerBarList } from './list';
import { Actions as AppActions } from 'store/app/actions/app';
import { Actions as WatchlistActions } from 'store/app/actions/watchlist';
import { MarketStatusProps, TickerBarProps } from 'store/app/types/props';
import { AppState, StocksState, WatchlistState } from 'store/app/types/state';
import { TickerBarContainer, TickerBarDisplayContainer } from '../Header/styles';
import { checkTickerListState } from 'store/app/selectors';
import { Loading } from 'components/display/Loading';
import { FUTURE, INDEX, PAUSED, PLAYING, TICKERBAR, TRENDING } from 'components/utils/const';
import { GearIcon, PauseIcon, PlayIcon } from 'components/icons';

/* Ticker Bar */

export function TickerBar(props: TickerBarProps): JSX.Element {
    const isLoggedIn = props.isLoggedIn;
    const isLive = useSelector(
        (state: { watchlist: WatchlistState }): boolean => state.watchlist.isLive
    );
    const isTrading = useSelector(
        (state: { stocks: StocksState }): boolean => state.stocks.isTrading
    );
    const appState = useSelector((state: { app: AppState }): AppState => state.app);
    const watchlistState = useSelector(
        (state: { watchlist: WatchlistState }): WatchlistState => state.watchlist
    );

    return (
        <TickerBarContainer isLoggedIn={isLoggedIn}>
            <TickerBarSettings isLoggedIn={isLoggedIn} app={appState} watchlist={watchlistState} />
            <Flex style={{ overflow: "hidden" }}>
                <MarketStatus isLive={isLive} isTrading={isTrading} />
                <TickerBarDisplayContainer>
                    <TickerBarDisplay isLoggedIn={isLoggedIn} app={appState} watchlist={watchlistState} />
                </TickerBarDisplayContainer>
            </Flex>
        </TickerBarContainer>
    );
}

/* // */

/* TickerBarSettings */

function TickerBarSettings(props: { isLoggedIn: boolean; app: AppState; watchlist: WatchlistState; }): JSX.Element {
    const dispatch = useDispatch();
    const isLoggedIn = props.isLoggedIn;
    const selectedList = props.app.ticker.selected;
    const tickerBarStatus = props.app.ticker.status;
    const watchlists = props.watchlist.watchlists.data;
    const showTip = selectedList.name.length > 8;

    let ActionButton;
    let isPlaying: boolean;

    switch (tickerBarStatus) {
        case PLAYING:
            ActionButton = () => <PauseIcon />;
            isPlaying = true;
            break;
        default:
            ActionButton = () => <PlayIcon />;
            isPlaying = false;
    }


    const handleDisplayChange = (eventKey: any, event: any) => dispatch(AppActions.updateTickerDisplay(eventKey, 'HEADER'));

    const handleClick = (event: React.MouseEvent) => {
        console.log("dispatch updateTickerBarStatus TickerBar.tsx")
        dispatch(AppActions.updateTickerBarStatus(!isPlaying));
    };

    const listWatchlists = (selectedId: number | string): Array<JSX.Element> =>
        watchlists.map(list => (
            <Dropdown.Item key={list.id} eventKey={`WATCHLIST_${list.id}`} active={selectedId === list.id}>
                {list.name}
            </Dropdown.Item>
        ));

    const handleSelectedListChange = (eventKey: any, event: any) => {
        // check type event ...
        const listId = eventKey.indexOf('_') !== -1 ? parseInt(eventKey.split('_')[1]) : eventKey;
        const listName = event.target.innerText;
        //dispatch( Actions.updateSelectedList( list, name, stateTickerBarStatus, 'TICKER_LIST' ) );
        dispatch(AppActions.updateTickerBarValue(listId, listName));
    };

    if (!isLoggedIn) {
        return (
            // make component - check
            <div className="tickerbar-settings">
                <StyledDropdown size="0.8rem" padding="3px 6px">
                    <Dropdown.Toggle className="btn-dostrik optnTicker w-80">
                        Trending
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item disabled>Login to view your watchlists</Dropdown.Item>
                    </Dropdown.Menu>
                </StyledDropdown>

                { // need div around? check
                }
                <div>
                    <DostrikHeaderButton className="ml-2" onClick={handleClick}>
                        <ActionButton />
                    </DostrikHeaderButton>
                </div>
            </div>
        );
    }

    return (
        // make component - check
        <div className="tickerbar-settings">
            <StyledDropdown onSelect={handleSelectedListChange} size="0.8rem" padding="3px 6px">
                <Dropdown.Toggle 
                    // check use component instead of class?
                    className="btn-dostrik optnTicker w-80"
                    data-tip
                    data-for="current-list"
                    { ...!showTip && { "data-tip-disable": true }}
                    >
                    {selectedList.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>

                    <Dropdown.Item key={TRENDING} eventKey={TRENDING} active={selectedList.id === TRENDING}>
                        Trending
                    </Dropdown.Item>
                    <Dropdown.Item key={INDEX} eventKey={INDEX}>
                        Indexes
                    </Dropdown.Item>
                    <Dropdown.Item key={FUTURE} eventKey={FUTURE}>
                        Futures
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    { listWatchlists(selectedList.id) }

                </Dropdown.Menu>
            </StyledDropdown>

            <StyledDropdown onSelect={handleDisplayChange} size="0.8rem" padding="3px 6px" className="ml-2">
                <Dropdown.Toggle className="btn-dostrik optnTicker">
                    <GearIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item key="4" eventKey="PRICE">
                        Price Only
                    </Dropdown.Item>
                    <Dropdown.Item key="5" eventKey="PRICE_CHANGE">
                        Price Change
                    </Dropdown.Item>
                    <Dropdown.Item key="6" eventKey="PERCENT_CHANGE" active>
                        Percent Change
                    </Dropdown.Item>
                </Dropdown.Menu>
            </StyledDropdown>

            <div>
                <DostrikHeaderButton className="ml-2" onClick={handleClick}>
                    <ActionButton />
                </DostrikHeaderButton>
            </div>

            
            { // group tooltips? check
            }
            <ReactTooltip id="current-list" type="light" effect="solid" place="bottom">
                    <span>{selectedList.name}</span>
            </ReactTooltip>
        </div>
    );
}

/* // */

/* MarketStatus */

function MarketStatus(props: MarketStatusProps): JSX.Element {
    const { isLive, isTrading } = props;
    
    const isMounted = useRef<boolean>(false);
    const color = isLive ? TICKERBAR.BLOB.LIVE : ( isTrading ? TICKERBAR.BLOB.OPEN : TICKERBAR.BLOB.CLOSED );
    const tip = isLive ? TICKERBAR.MARKET.LIVE : ( isTrading ? TICKERBAR.MARKET.OPEN : TICKERBAR.MARKET.CLOSED );

    useEffect(() => {
        isMounted.current = true;
    }, []);

    return (
        <React.Fragment>
            {isMounted.current ? (
                <ReactTooltip id="market-status" type="light" effect="solid" place="bottom">
                    <span>{tip}</span>
                </ReactTooltip>
            ) : null}

            <MarketStatusBlob
                isTrading={props.isTrading}
                color={color}
                data-tip
                data-for="market-status"
            />
        </React.Fragment>
    );
}

/* // */

/* TickerBarDisplay */

function TickerBarDisplay(props: { isLoggedIn: boolean; app: AppState; watchlist: WatchlistState; }): JSX.Element {
    const dispatch = useDispatch();
    const refKey = useRef<boolean | undefined>();
    const [key, setKey] = useState<number>(17);
    const [isPaused, setIsPaused] = useState<boolean>(true);

    const status = props.app.ticker.status;
    const isLoaded = props.app.ticker.isLoaded;

    const tickerList = useSelector(checkTickerListState);
    const selected = tickerList.selectedList ? tickerList.selectedList.id : 0;

    let hoverTimeout: ReturnType<typeof setTimeout>;

    const pseudoRand = (): number => Math.floor(Math.random() * 10000);
    const randomize = (): number => {
        const rand = pseudoRand();
        return rand !== key ? rand : pseudoRand();
    };

    // If error/fail to fetch on page load, dispatches 2 TICKERBAR_LOADED when switching list. check
    // See loadTrendingData in rootSaga, set timeout low to replicate
    useEffect(() => {
        console.log( tickerList );
        if( tickerList.isLoadedData ) {
            console.log("TICKERBAR_LOADED 1");
            dispatch({ type: "TICKERBAR_LOADED", payload: tickerList.list.length });
        }
    }, [tickerList.isLoadedData]);

    useEffect(() => {
        console.log('Use Effect selected: ' + selected);
        /*
        if (tickerList.isLoadedList && !tickerList.isLoadedData) {
            const tickersToLoad = tickerList.list.map((item) => item.symbol).join();
            // get country from state / selector? - check 
            dispatch( WatchlistActions.loadTrendingData("US", tickersToLoad) );
        }
        */

        if (!refKey.current) {
            refKey.current = true;
        } else {
            setKey(randomize());
        }
    }, [selected]);

    useEffect(() => {
        switch (status) {
            case PLAYING:
                return setIsPaused(false);
            case PAUSED:
                return setIsPaused(true);
        }
    }, [status]);

    function handleHover(val: boolean) {
        if (status === PLAYING) {
            if (val === true) {
                hoverTimeout = setTimeout(() => setIsPaused(true), 350);
            } else {
                isPaused ? setIsPaused(false) : clearTimeout(hoverTimeout);
            }
        }
    }

    // see if necessary - check

    if( !tickerList.isLoadedList )
        return <Loading size="small" className="ml-3 d-flex align-items-center" />;


    return (
        <div
            id="featured-list"
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            >
            <TickerBarList
                rand={key}
                delay={1}
                speed={65}
                play={!isPaused}
                pauseOnHover={true}
                pauseOnClick={true}
                // not used anymore, added in css instead. check
                gradientWidth={20}
                gradientColor={[16, 25, 31]}
            />
        </div>
    );
}
