import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FixedSizeList, areEqual } from 'react-window';
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable, DraggableProvided } from 'react-beautiful-dnd';
import {
    Flex,
    SparkItem,
    Handle,
    Notice,
    Border,
    TickerListHeader,
    EditWatchlistButton,
    TickerDisplay,
    WatchlistDropdown,
    SortWatchlistDropdown,
    DefaultButton,
} from 'styles';

/*
import update from 'immutability-helper';
import SymbolChart from './utils/SymbolChart'
import { Sparkline } from '../charts/Sparkline';
import { calcStatus, bullOrBear, priceChange, percentChange } from '../helpers';
import { ref } from "technical-indicator";
import { search } from '../../redux/actions'
*/

import { Loading } from './Loading';
import { checkWatchlistState, checkUserState } from 'store/app/selectors';
import { AppState, WatchlistState } from 'store/app/types/state';
import { WatchlistProps } from 'store/app/types/props';
import { IWatchlists, WatchlistData, WatchlistDataList } from 'store/app/types';
import { WatchlistLoggedOut } from './LoggedOut';
import { Actions } from 'store/app/actions/watchlist';
import { AddIcon, FireIcon, GripIcon, StatusIcon } from 'components/icons';
import { formatDecimals } from 'helpers';
import { StatusDisplay } from 'components/utils';
import { SIDEBAR } from 'components/utils/const';

export function Watchlist(props: WatchlistProps): JSX.Element {
    const dispatch = useDispatch();
    const user = useSelector(checkUserState);
    const watchlistState = useSelector(checkWatchlistState);
    const userWatchlists = useSelector((state: { watchlist: WatchlistState }) => state.watchlist.watchlists.data);
    const isSelectedWatchlistLoaded = useSelector((state: { app: AppState }) => state.app.watchlist.isLoaded);

    const handleClick = (event: React.MouseEvent) => {
        console.log('click');
    };

    const handleSelectedListChange = (eventKey: any, event: any) => {
        // check type event ...
        const name = event.target.innerText;
        const list = eventKey.indexOf('_') !== -1 ? parseInt(eventKey.split('_')[1]) : eventKey;
        dispatch(Actions.updateSelectedWatchlist(list, name));
    };

    const listLoaded = watchlistState.isLoadedList;
    const dataLoaded = watchlistState.isLoadedData;
    const isEmpty = watchlistState.isEmpty;
    const isLoaded = listLoaded && dataLoaded;
    const isLoggedIn = user.isLoggedIn;
    const displayValue = watchlistState.displayValue;
    const tempList = watchlistState.watchlist.list; // to sort before?
    

    // put sorting in selector, based on state?
    tempList.sort((a, b) => {
        if( a.changePercent !== null && b.changePercent !== null ) {
            return b.changePercent - a.changePercent;
        }
        return 1; // temp..
    });
    

    const list: WatchlistDataList[] = listLoaded ? (
        watchlistState.watchlist.list.map((item) => {
              const ticker = item.ticker ? item.ticker : `No data`;
              const priceDisplay = item.price ? `$${formatDecimals(item.price)}` : ``;
              const changePercent = item.changePercent;
              const changePercentDisplay = changePercent ? `${formatDecimals(changePercent)}%` : ``;
              //const statusDisplay = changePercent ? ( Math.sign( changePercent ) === 1 ? 'bullish' : 'bearish' ) : '';
              const statusDisplay = item.status;
              const isTrending = item.isTrending && item.isTrending === true;
              // improve
              const icon = isTrending ? <FireIcon /> : null;

              return {
                  id: item.id,
                  ticker: ticker,
                  display: priceDisplay,
                  changePercent: changePercentDisplay,
                  status: statusDisplay,
                  icon: icon,
                  data: item.data,
              };
          })
    ) : [];

    const watchlist: WatchlistData = {
        id: watchlistState.watchlist.id,
        name: watchlistState.watchlist.name,
        list: list,
    };

    const component = props.component;
    const height = props.height ? props.height : 400;
    var opts = {};
    // keep opts for example, probably not necessary because of not using mode anymore..
    //opts['className'] = "edit";

    if (!isLoggedIn) {
        return (
            <Border className="sidebar mb-3">
                {user.isLoading ? (
                    <Loading className="pt-3 pb-3" />
                ) : (
                    <React.Fragment>
                        <WatchlistHeader
                            isLoading={false}
                            isLoggedIn={isLoggedIn}
                            id={watchlist.id}
                            name={watchlist.name}
                            handleClick={() => console.log('Not logged in')}
                            handleSelectedListChange={() => console.log('Not logged in')}
                            userWatchlists={userWatchlists}
                            />
                        <WatchlistLoggedOut />
                    </React.Fragment>
                )}
            </Border>
        );
    }

    if (isLoggedIn && !isLoaded && !isEmpty) {
        return (
            <Border className="sidebar mb-3">
                <WatchlistHeader
                    isLoading={true}
                    isLoggedIn={isLoggedIn}
                    id={watchlist.id}
                    name={watchlist.name}
                    handleClick={() => console.log('Loading...')}
                    handleSelectedListChange={() => console.log('Loading...')}
                    userWatchlists={userWatchlists}
                    />
                    <Loading className="pb-3" />
            </Border>
        );
    }

    switch (component) {
        case SIDEBAR: {
            return (
                <Border className="sidebar mb-3">
                    <WatchlistHeader
                        isLoading={false}
                        isLoggedIn={isLoggedIn}
                        id={watchlist.id}
                        name={watchlist.name}
                        handleClick={handleClick}
                        handleSelectedListChange={handleSelectedListChange}
                        userWatchlists={userWatchlists}
                    />
                    {isEmpty ? (
                        <div className="watchlist" {...opts}>
                            <Notice>Watchlist is empty</Notice>
                        </div>
                    ) : (
                        <div className="watchlist" {...opts}>
                            {!isSelectedWatchlistLoaded ? (
                                <Loading className="mt-5 mb-3" />
                            ) : (
                                <List 
                                    watchlist={watchlist} 
                                    dataLoaded={false} 
                                    height={height} 
                                    />
                            )}
                        </div>
                    )}
                </Border>
            );
        }

        case 'SMALL_SIDEBAR': {
            return (
                <div>
                    <p>{watchlist.name}</p>
                    <List 
                        watchlist={watchlist} 
                        dataLoaded={false} 
                        height={height} 
                        />
                </div>
            );
        }

        default: {
            return <div>Default Watchlist. To do</div>;
        }
    }
}

type WatchlistHeaderProps = {
    isLoggedIn: boolean;
    isLoading: boolean;
    id: number | null;
    name: string | null;
    userWatchlists: IWatchlists[];
    handleClick: (event: React.MouseEvent) => void;
    handleSelectedListChange: (eventKey: any, event: any) => void;
};

function WatchlistHeader(props: WatchlistHeaderProps): JSX.Element {

    const titleRef = useRef<HTMLElement>();
    const sortRef = useRef<HTMLElement>();
    const titleWidth = (titleRef && titleRef.current) ? (titleRef.current.clientWidth) : `auto`;

    const UserWatchlists = (): JSX.Element => (
        <React.Fragment>
            {props.userWatchlists.map((list) => (
                <WatchlistDropdown.Item
                    key={list.id}
                    eventKey={'WATCHLIST_' + list.id}
                    active={list.id === props.id}
                    >
                    {list.name}
                </WatchlistDropdown.Item>
            ))}
        </React.Fragment>
    );

    const EditWatchlist = React.forwardRef(({ children, onClick }, ref) => (
        <EditWatchlistButton onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}>
            {children}      
        </EditWatchlistButton>
    ));

    return (
        <TickerListHeader ref={titleRef as any}>
            {props.isLoggedIn ? (
                <WatchlistDropdown onSelect={props.handleSelectedListChange}>
                    <WatchlistDropdown.Toggle>{props.name}</WatchlistDropdown.Toggle>
                    <WatchlistDropdown.Menu style={{ width: titleWidth }}>
                        <UserWatchlists />
                    </WatchlistDropdown.Menu>
                </WatchlistDropdown>
            ) : (
                <h2>Watchlist</h2>
            )}
            {props.isLoggedIn ? (
                // put in its own component?
                <React.Fragment>
                    <Flex>
                        <div>
                            <DefaultButton fontSize={"1rem"} padding={"0.375rem 0.75rem"}>
                                <AddIcon />
                            </DefaultButton>
                        </div>

                        <SortWatchlistDropdown onSelect={props.handleSelectedListChange}
                            >
                            {
                                //<EditWatchlistButton />
                            }
                            <SortWatchlistDropdown.Toggle>
                                <FontAwesomeIcon icon={["fas", "sort"]} />
                            </SortWatchlistDropdown.Toggle>

                            <SortWatchlistDropdown.Menu>
                                <SortWatchlistDropdown.Item>Order 1</SortWatchlistDropdown.Item>
                                <SortWatchlistDropdown.Item>Order 2</SortWatchlistDropdown.Item>
                                <SortWatchlistDropdown.Item>Order 3</SortWatchlistDropdown.Item>
                            </SortWatchlistDropdown.Menu>

                        </SortWatchlistDropdown>
                    </Flex>
                </React.Fragment>
            ) : null}
        </TickerListHeader>
    );
}

function List(props: {
    watchlist: WatchlistData;
    dataLoaded: boolean;
    height: number;
    order?: boolean;
    mode?: string;
}): JSX.Element {
    const mode = props.mode;
    const dataLoaded = props.dataLoaded;
    const height = props.height;

    const [stocks, setStocks] = useState<WatchlistDataList[]>(props.watchlist.list);

    const refList = useRef<boolean>();
    const refScroll = useRef<HTMLElement>();

    useEffect(() => {
        if (refList.current) {
            setStocks(props.watchlist.list);
        }
        refList.current = true;
    }, [props.watchlist.list]);

    function onDragEnd(result: any) {

        if (!result.destination)
            return;

        if (result.source.index === result.destination.index)
            return;

        const newItems = reorder([...stocks], result.source.index, result.destination.index);

        setStocks(newItems);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <ReactTooltip id="item-id" type="light" effect="solid" place="right" />
            <Droppable
                droppableId="droppable"
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                    <DragItem
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        style={{ display: 'flex' }}
                        item={stocks[rubric.source.index]}
                    />
                )}
            >
                {(provided) => {
                    return (
                        <FixedSizeList
                            height={height}
                            itemCount={stocks.length}
                            itemSize={45}
                            width="100%"
                            outerRef={provided.innerRef}
                            innerRef={refScroll}
                            itemData={stocks}
                        >
                            {Row}
                        </FixedSizeList>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
}

// Recommended react-window performance optimisation: memoize the row render function
// Things are still pretty fast without this, but I am a sucker for making things faster
const Row = React.memo(
    function Row(props: { data: any; index: any; style: any }) {
        const { data: stocks, index, style } = props;
        const item = stocks[index];

        return (
            <Draggable draggableId={item.id.toString()} index={index} key={item.ticker}>
                {(provided) => <DragItem provided={provided} item={item} style={style} />}
            </Draggable>
        );
    },
    function (prev, next) {
        const pData = prev.data;
        const nData = next.data;
        const pIndex = prev.index;
        const nIndex = next.index;
        if (pData[pIndex].display === nData[nIndex].display) {
            return true;
        } else {
            return false;
        }
    }
);

type DragItemProps = {
    provided: DraggableProvided;
    item: WatchlistDataList;
    style: any;
    isDragging?: any;
    order?: boolean;
};

function DragItem({ provided, item, style, isDragging, order }: DragItemProps) {
    const icon = item.icon;
    const hasData = item.data && item.data.length !== 0;
    const showStatusIcon = item.data && item.data.length < 10;
    
    // 🔥

    return (
        <SparkItem
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={getStyle({ provided, style, isDragging })}
            className={`${isDragging ? 'is-dragging' : ''}`}
            >
            <TickerDisplay showStatusIcon={showStatusIcon}>
                <Link 
                    to={'/symbol/' + item.ticker} 
                    data-tip={item.id} 
                    data-for="item-id"
                    >
                    {item.ticker}
                </Link>
                {icon}
            </TickerDisplay>

            <StatusDisplay
                data={item.data}
                status={item.status}
                hasData={hasData}
                showStatusIcon={showStatusIcon}
                />

            <div className="valueDisplay">{/* to do <ValueDisplay /> */}
                <span>{item.display}</span>
                {item.changePercent ? (
                    <span className={item.status}>{item.changePercent}</span>
                ) : null}
            </div>

            <Handle {...provided.dragHandleProps}>
                <GripIcon />
            </Handle>
        </SparkItem>
    );
}

// a little function to help us with reordering the result
const reorder = (
    list: WatchlistDataList[],
    startIndex: number,
    endIndex: number
): WatchlistDataList[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function getStyle({
    provided,
    style,
    isDragging,
}: {
    provided: DraggableProvided;
    style: any;
    isDragging?: boolean;
}) {
    // If you don't want any spacing between your items
    // then you could just return this.
    // I do a little bit of magic to have some nice visual space
    // between the row items
    const combined = {
        ...style,
        ...provided.draggableProps.style,
    };

    return combined;
}
