import React, { Component } from 'react';
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as AuthActions from 'store/app/actions/auth';
import * as EmailValidator from 'email-validator';
import { LoadingLogin } from 'components/display/Loading';
import { Row, Col, Button } from 'react-bootstrap';
import { DostrikForm, DostrikButton, Flex, DefaultButton } from 'styles';

class Signup extends Component {

  componentWillMount() {
    //this.props.signoutUser();
  }

  componentWillUpdate(nextProps, nextState) {
    const { error, forgotMsg } = nextProps;
    if (error) {
      nextProps.cleardown();
    } else if (forgotMsg) {
      nextProps.cleardown();
    }
  }

  renderField(field) {
      const { meta: { touched, error } } = field;
      const classNameFormGroup = `form-group ${touched && error ? "has-error" : ""}`;
      const classNameFormControl = `form-control ${touched && error ? "has-error" : ""}`;
      return (
        <div className={classNameFormGroup}>
          <label className="col-xs-3 control-label">{field.label}</label>
          <div className="col-xs-6">
            <input className={classNameFormControl} type="text" {...field.input} />
            <div className="text-help">
              {touched ? error : ""}
            </div>
          </div>
        </div>
      );
  }

  renderPasswordField(field) {
      const { meta: { touched, error } } = field;
      const classNameFormGroup = `form-group ${touched && error ? "has-error" : ""}`;
      const classNameFormControl = `form-control ${touched && error ? "has-error" : ""}`;
      return (
        <div className={classNameFormGroup}>
          <label className="col-xs-3 control-label">{field.label}</label>
          <div className="col-xs-6">
            <input className={classNameFormControl} type="password" {...field.input} />
            <div className="text-help">
              {touched ? error : ""}
            </div>
          </div>
        </div>
      );
  }

  onSubmit(values) {
    const { username, email, password } = values;
    this.props.dispatch( AuthActions.signupUser({ username, email, password }) );
  }

  getSignupButton() {
    const disabled = {
      ...this.props.loading && { disabled: true }
    };
    return <DefaultButton padding="0.5rem 1rem" {...disabled}>Sign up</DefaultButton>;
  }

  getCancelButton() {
    return <DefaultButton className="btn-sm no-bg ml-2">Cancel</DefaultButton>;
  }

  isLoading() {
    return this.props.loading;
  }

  render() {

    const { handleSubmit } = this.props;

    return (
        <div className="col-xs-10 col-xs-offset-1">
          <DostrikForm onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Row>
              <Col lg="6">
                <Field
                    label="Username"
                    name="username"
                    component={this.renderField}
                    />
              </Col>
              <Col lg="6">
                <Field
                    label="Email"
                    name="email"
                    component={this.renderField}
                    />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Field
                    label="Password"
                    name="password"
                    component={this.renderPasswordField}
                    />
              </Col>
              <Col lg="6">
                <Field
                    label="Confirm password"
                    name="passwordConfirm"
                    component={this.renderPasswordField}
                    />
              </Col>
            </Row>
            <Row>
              <Col>
                <Flex className="form-group">
                    {this.getSignupButton()}
                    {this.getCancelButton()}
                </Flex>
              </Col>
            </Row>
        </DostrikForm>
        <LoadingLogin loading={this.isLoading()} />
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.email || !EmailValidator.validate(values.email)) {
    errors.email = "Enter an email";
  }

  if (!values.username) {
    errors.username = "Enter a valid username";
  }
  if (!values.password) {
    errors.password = "Enter a password";
  }
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = "Passwords must match";
  }
  return errors;
}

function mapStateToProps(state) {
  const { error, timestamp, forgotMsg, loading } = state.auth;
  return {
    error,
    timestamp,
    forgotMsg,
    loading
  };
}

export default reduxForm({
  validate,
  form: "SignupForm"
})(connect(mapStateToProps, AuthActions)(Signup));