import React from 'react';
import { useSelector } from 'react-redux';
//import { checkCurrentPage } from '../../redux/selectors';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { ReactComponent as LogoLight } from '../../../assets/logo-light.svg';
import { ReactComponent as LogoDark } from '../../../assets/logo-dark.svg';

import { Header as SiteHeader, UserHeader, NavItem, SiteHeaderBackground, FeaturedBackground } from './styles';
import { IconButton } from '../../../styles';

import { TickerBar } from '../../layout/TickerBar';
import { SearchForm } from '../../forms/Search';
import { User } from '../../display/User';
import { checkCurrentPage } from 'store/app/selectors';
import { HeaderProps } from 'store/app/types/props';
import { StocksState, UserState, WatchlistState } from 'store/app/types/state';

import Toggle from 'react-toggle';
import { useTranslation } from 'react-i18next';
import { Featured } from 'components/display/Featured';

//import loadable from '@loadable/component';
//import logoDark from '../../assets/images/logo-light.svg';
//import logoLight from '../../assets/images/logo-dark.svg';
//import { TickerControls } from '../TickerControls';
//import TickerBar from '../TickerBar';
//import SearchForm from '../forms/Search';
//import User from '../User';


export const Header = (props: HeaderProps) => {
    console.log('Header');

    const theme = props.theme;

    const { t } = useTranslation();

    const currentPage = useSelector(checkCurrentPage);
    const isLoggedIn = useSelector((state: { user: UserState }): boolean => state.user.isLoggedIn);

    return (
        <SiteHeader isLoggedIn={isLoggedIn}>
            <SiteHeaderBackground>
                <Container>
                    <Navbar expand="md">
                        <Link className="nav-link" id="logo" to="/">
                            {
                                (theme === "dark") ?
                                    <LogoLight style={{ width: "100px" }} /> :
                                    <LogoDark style={{ width: "100px" }} />
                            }
                        </Link>

                        <Navbar.Collapse id="dstrkNav" className="order-sm-2 order-md-1">
                            <Nav className="mr-auto">
                                <NavItem selected={currentPage === "Stocks"}>
                                    <Link to="/stocks">{t("nav.stocks")}</Link>
                                </NavItem>
                                <NavItem selected={currentPage === "Crypto"}>
                                    <Link to="/crypto">{t('nav.crypto')}</Link>
                                </NavItem>
                                <NavItem selected={currentPage === "Calendar"}>
                                    <Link to="/calendar">{t('nav.calendar')}</Link>
                                </NavItem>
                            </Nav>
                        </Navbar.Collapse>

                        <div className="ml-auto order-sm-1 order-md-2">
                            <UserHeader className="nav-item">
                                <User />
                                <Navbar.Toggle aria-controls="dstrkNav" />
                            </UserHeader>
                        </div>

                        <SearchForm />
                        
                    </Navbar>

                    <TickerBar isLoggedIn={isLoggedIn} />

                </Container>
            </SiteHeaderBackground>
        </SiteHeader>
    );
};
