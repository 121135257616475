
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginForm } from 'components/forms/Login';
import { ModalClose } from 'components/utils';
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Border, DostrikModal, Name, RemoveButton, Ticker, TickerList } from 'styles';
import { Flex } from 'styles';
import { SearchForm } from 'components/forms/Search';
import styled from 'styled-components';
import { WatchlistState } from 'store/app/types/state';
import { Actions } from 'store/app/actions/watchlist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function WatchlistModal( props: { show: boolean } ) {
    const dispatch = useDispatch();
    const [ watchlistName, setWatchlistName ] = useState<string>('My Watchlist');
    const [ showModal, setShowModal ] = useState<boolean>( props.show );
    const watchlist = useSelector( (state: { watchlist: WatchlistState }) => state.watchlist.watchlists.temp );
    const tickersCount = watchlist.length; 
    const tickersCountDisplay = String(tickersCount)+"/15"; 

    const onHide = (hide: boolean) => {
        if( !hide ) {
            return alert('Must complete or skip');
        }
        return setShowModal( false );
    }

    const updateWatchlist = (id: number, ticker: string, name: string, action: string) => {
        if( id === undefined || id === 0 ) {
            alert( 'Error with this ticker. (No ID found)' );
            return;
        }

        if( action === 'ADD' ) {
            if( watchlist.some( item => item.id === id ) ) {
                alert('You already selected this ticker');
                return;
            }
    
            dispatch( Actions.addTickerToTempWatchlist( id, ticker, name ) );
        } else if( action === 'REMOVE' ) {
            if( !watchlist.some( item => item.id === id ) ) {
                alert('That ticker is not in the watchlist');
                return;
            }
    
            dispatch( Actions.removeTickerFromTempWatchlist( id ) );

        }
    };

    const submitWatchlist = () => {
        if( watchlist.length === 0 ) {
            alert('Watchlist empty, add stocks or skip.');
            return;
        }

        // Verify watchlist name
        // Manage errors other than alerts
        // Manage hiding modal
    
        // When submitting, post watchlist to DB and get ID before updating state
        dispatch( Actions.submitTempWatchlist( watchlistName ) );
        onHide(true);
    };

    return (
        <DostrikModal
            show={showModal}
            onHide={() => onHide(false)}
            size="md"
            aria-labelledby="contained-modal-title"
            >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <h2 style={{fontSize:'1.75rem'}} className="mt-2">Create your first watchlist</h2>

                            <p>To start off, add some tickers to your first watchlist.</p>

                            <Divider mb="1rem" />

                            <div style={{position: 'relative'}}>

                                <Flex direction="column">
                                    <label>Watchlist name</label>
                                    <input type="text" defaultValue={watchlistName} autoComplete="off" className="mb-2 w-300" onChange={(event) => setWatchlistName(event.target.value) } />
                                </Flex>

                                <div className="mt-2 mb-2">

                                    {
                                        tickersCount < 15 ? (
                                            <SearchForm updateWatchlist={updateWatchlist} component="MODAL" />
                                        ) : (
                                            <div>Watchlist limit reached</div>
                                        )
                                    }

                                    <Border className="mt-2 mb-2" padding="0.25rem 1rem">
                                        {
                                            watchlist.length === 0 ? (
                                                <Flex align="center" style={{padding: '0.5rem', fontSize: 'smaller'}}>
                                                    <FontAwesomeIcon icon={['fal', 'info-circle']} size="2x" />
                                                    <span style={{paddingLeft: '0.75rem'}}>
                                                        Use the input above to search for the stocks you want to add.
                                                    </span>
                                                </Flex>
                                            ) : (
                                                    <TickerList>
                                                        { 
                                                            watchlist.map( item => (
                                                                <li key={item.ticker}>
                                                                    <Ticker>{item.ticker}</Ticker> 
                                                                    <Name>{item.name}</Name>
                                                                    <ActionButton onClick={() => updateWatchlist(item.id, item.ticker, item.name, 'REMOVE') }>
                                                                        <FontAwesomeIcon icon={['fal', 'minus']} />
                                                                    </ActionButton>
                                                                </li> 
                                                            ))
                                                        }
                                                    </TickerList>
                                            )  
                                        }
                                    </Border>

                                </div>
                                
                            </div>

                            <Flex>
                                <span style={{fontSize: "1.7rem",fontFamily: "Varela Round, sans-serif"}}>{tickersCountDisplay}</span>
                                <Button onClick={() => onHide(true)} className="ml-auto">Skip</Button>
                                <Button onClick={() => submitWatchlist()} className="userSubmit">Done</Button>
                            </Flex>

                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
      </DostrikModal>
    );
}

const Divider = styled.div<{mt?: string, mb?: string}>`
    height: 2px;
    margin-top: ${ props => props.mt ? props.mt : '0.5rem' };
    margin-bottom: ${ props => props.mb ? props.mb : '0.5rem' };
    background-color: ${ props => props.theme.pixelPopDark };
    border-bottom: 1px solid ${ props => props.theme.pixelPopLight };
`;
