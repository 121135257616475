import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StockStatus } from 'store/app/types';
import { IconName } from '@fortawesome/fontawesome-common-types';

export const FireIcon = (props: { color?: string }) => {
    const style = {
        paddingLeft: '0.5rem',
        ...(props.color && { color: props.color }),
    };

    return (
        <span style={style}>
            <FontAwesomeIcon icon={['fas', 'fire']} />
        </span>
    );
};

export const ArrowIcon = (props: { status: StockStatus }) => {
    const isBullish = props.status === 'bullish';
    const icon = isBullish ? 'caret-up' : 'caret-down';

    return <FontAwesomeIcon icon={['fas', icon]} size="1x" />;
};

export const StatusIcon = (props: {
    status: StockStatus;
    size?: '1x' | '2x' | '3x';
    className?: string;
}) => {
    const isBullish = props.status === 'bullish';
    const icon = isBullish ? 'chevron-up' : 'chevron-down';
    const size = props.size ? props.size : `1x`;
    const className = props.className ? props.className : ``;

    return (
        <span className={`${props.status} ${className}`}>
            <FontAwesomeIcon icon={['far', icon]} size={size} />
        </span>
    );
};

// check
// use const or <Icon>?

type IconType = "fas" | "fal" | "far" | "fad";

export const Icon = ( type: IconType, icon: IconName, size: "1x" | "2x" | "3x" = "1x") => (
    <FontAwesomeIcon icon={[type, icon]} size={size} />
);

export const PlayIcon = () => <FontAwesomeIcon icon="play" />;
export const PauseIcon = () => <FontAwesomeIcon icon="pause" />;

export const GripIcon = () => <FontAwesomeIcon icon={["fas", "grip-vertical"]} />;
export const StarIcon = () => <FontAwesomeIcon icon={["fas", "star"]} />;
export const GearIcon = () => <FontAwesomeIcon icon={["fas", "cog"]} />;
export const CaretIcon = (props: { bullish: boolean }) => <FontAwesomeIcon icon={["fas", props.bullish ? "caret-up" : "caret-down"]} />;
export const ReloadIcon = () => <FontAwesomeIcon icon={["fas", "redo"]} />;
export const ErrorLoadingIcon = () => <FontAwesomeIcon icon={["fad", "exclamation-circle"]} size="2x" />;
export const AddIcon = () => <FontAwesomeIcon icon={["fal", "plus"]} />;

// find way to get size either as 1x/2x/3x or font size.
export const SettingsIcon = () => <GearIcon />;
