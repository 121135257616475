
import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'helpers/gridstack/dist/gridstack.min.css'
import { GridItemHTMLElement, GridStack, GridStackNode } from 'helpers/gridstack';
import 'helpers/gridstack/dist/h5/gridstack-dd-native';
import { Loading } from 'components/display/Loading';
import { Panel } from '../Panel';
import { DashboardPanel, DashboardState } from 'store/app/types';
import { Actions } from 'store/app/actions/post';
import { ThemeType } from 'styles/Themes';

type GridProps = {
    isEditing: boolean;
    dashboardState: DashboardState;
    setShowModal: (show: boolean) => void;
    removeFromDashboard: (id: string) => void;
    theme: ThemeType;
}

export function Grid({ isEditing, dashboardState, setShowModal, removeFromDashboard, theme }: GridProps) { // check type

    const dispatch = useDispatch();

    const loadRef = useRef();
    
    //const { isBrowser, isServer } = useSSR();

    const items = dashboardState.items;

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const refs = useRef({}) as any;
    const gridRef = useRef() as React.MutableRefObject<GridStack>;

    if (Object.keys(refs.current).length !== items.length) {

        Object.keys(refs.current).forEach( (item) => {
            if( refs.current[item].current === null ) {
                delete refs.current[item];
            }
        });

        items.forEach(( item: DashboardPanel ) => {
            refs.current[item.id] = refs.current[item.id] || React.createRef()
        });

    }

    const updateItem = ( item: GridStackNode ) => {
        const newData = item;
        const updatedItems = [...dashboardState.items].map( (item) => {
            if( item.id === newData.id ) {
                return { ...item, x: newData.x, y: newData.y, size: { w: newData.w, h: newData.h } };
            }
            return item;
        });

        return updatedItems;       
    }

    useEffect(() => {

        setShowModal(false);

        gridRef.current = gridRef.current || GridStack.init({
                float: false,
                disableDrag: false,
                disableResize: false,
                column: 12,
                minRow: 1, // don't let it collapse when empty
                cellHeight: '7rem'
            }, '.controlled' );
    
        const grid: GridStack = gridRef.current;

    
        grid.batchUpdate();
        grid.removeAll(false);
    
        items.forEach(( item: DashboardPanel ) => grid.makeWidget(refs.current[item.id].current) );
    
        grid.commit();

        setIsLoading(false);
    
        gridRef.current.on('added', function(event: Event, items: GridStackNode | GridItemHTMLElement | GridStackNode[] | undefined) {
            console.log('added');
            console.log( items );
        });

        gridRef.current.on('resizestop', function(event, el: any) { // "any" type to avoid errors.
            console.log( 'resizeStop' );
            
            if( el.gridstackNode !== undefined ) {
                // get one value
                const elWidth = el.getAttribute('gs-w');
                //const width = typeof elWidth === "string" ? parseInt(elWidth) : 0;

                // or all values...
                const node: GridStackNode = el.gridstackNode; // {x, y, width, height, id, ....}
    
                const updatedItems = updateItem( node );

                dispatch( Actions.setDashboard({...dashboardState, items: updatedItems }) ); // check type
            }

        });

    }, [items]);

    useEffect(() => {

        if( gridRef.current ) {
            if( isEditing ) {
                gridRef.current.enable();
            } else {
                console.log( gridRef.current.save( true, true ) );
                gridRef.current.disable();
            }
        }

    }, [isEditing]);

    const opts = {
        ...isLoading && { 
            style: { 
                'display': 'none' 
            }
        }
    };

    return (
        <React.Fragment>
            {
                isLoading ?
                    <Loading /> : null
            }

            <div className={`grid-stack controlled`} {...opts}>
                {
                    items.map( (panel: DashboardPanel, i: number) => {

                        const height = refs.current[panel.id].current ? refs.current[panel.id].current.clientHeight : 0;
                        const width = refs.current[panel.id].current ? refs.current[panel.id].current.clientWidth : 0;
                        return (
                            <div 
                                key={panel.id}  
                                gs-w={panel.size.w} 
                                gs-h={panel.size.h}
                                gs-id={panel.id} 
                                gs-type={panel.type}
                                ref={refs.current[panel.id]} 
                                className={'grid-stack-item'}
                                >

                                <div className="grid-stack-item-content">
                                    <Panel 
                                        panel={panel}
                                        theme={theme} 
                                        height={height} 
                                        width={width} 
                                        isEditing={isEditing}
                                        removeFromDashboard={removeFromDashboard}
                                        />
                                </div>

                            </div>
                        );
                    })
                }
            </div>

        </React.Fragment>
    )
};