import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import admin from './app/reducers/admin';
import app from './app/reducers/app';
import auth from './app/reducers/auth';
import post from './app/reducers/post';
import watchlist from './app/reducers/watchlist';
import stocks from './app/reducers/stocks';
import search from './app/reducers/search';
import settings from './app/reducers/settings';
import user from './app/reducers/user';

const createRootReducer = () =>
    combineReducers({
        form,
        admin,
        app,
        auth,
        post,
        search,
        settings,
        stocks,
        user,
        watchlist,
    });

export default createRootReducer;