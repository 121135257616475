import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Modal, Button, Dropdown } from 'react-bootstrap';
import { checkUserState } from '../../store/app/selectors';
import { login } from '../../store/app/actions/user';
import { UserLogin, UserModalPayload } from '../../store/app/types';

// import { Border } from '../../styles';
//import { LoginForm, RegisterForm } from '../forms/Login';

import LoginForm from 'components/forms/ReduxLogin';
import SignupForm from 'components/forms/ReduxRegister';
import { SigninModalProps, SignupModalProps } from 'store/app/types/props';
import { HeaderMenuItem, LoggedInAvatar, LoggedOutAvatar } from 'components/layout/Header/styles';
import { AppState, AuthState } from 'store/app/types/state';

import { Loading, LoadingLogin } from './Loading';
import { DefaultButton, DostrikButton, DostrikModal, Flex } from 'styles';
import { ModalClose } from 'components/utils';
import { Notifications } from './Notifications';
import { Logout } from 'components/auth/Logout';
import { AvatarSVG } from "components/svg";

export function User(): JSX.Element {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector( checkUserState );
    const staticUrl = useSelector( (state: { app: AppState }) => state.app.staticUrl );
    const formLoading = useSelector( (state: { auth: AuthState }) => state.auth.loading );
    const [ signinModalVisible, setSigninModalVisible ] = useState<boolean>(false); // put in useUser
    const [ signupModalVisible, setSignupModalVisible ] = useState<boolean>(false);
    
    // Check if still necessary (because of Auth0) :

        const [ userLogin, setUserLogin ] = useState<UserLogin>({ username: null, password: null });

        const handleLogin = ( event: React.FormEvent ): void => {
            event.preventDefault();
            dispatch( login( userLogin ) );
        };
        
        const handleRegister = ( event: React.FormEvent ): void => {
            event.preventDefault();
            dispatch( login( userLogin ) );
        };

        const updateState = ( key: string, value: string ): void => setUserLogin({ ...userLogin, [key]: value });

    const payload: UserModalPayload = {
        user: user,
        staticUrl: staticUrl,
        isLoading: formLoading || (user.isLoading || user.register.isLoading),
        dispatch: dispatch,
        // Check if still necessary (because of Auth0) :
            updateState: updateState,
            handleLogin: handleLogin,
            handleRegister: handleRegister
    };

    const isLoggedIn = user.isLoggedIn
    const isLoading = user.isLoading
    const username = user.info.username

    //const avatar = isLoggedIn ? user.info.username : 'avatar';
    //const avatar = 'avatar';

    const avatarSrc = (isLoggedIn && user.info.avatar) ? user.info.avatar : staticUrl+'public/images/avatars/avatar.png';
    
    const UserDisplay = () => <img src={avatarSrc} srcSet={avatarSrc+" 2x"} width="38" height="38" style={{borderRadius: "19px"}} />;

    const onClickSignin = !isLoading && { onClick: (): void => setSigninModalVisible(true) };
    const onClickSignup = (): void => {
        setSigninModalVisible(false);
        setSignupModalVisible(true);
    }

    const AvatarToggle = React.forwardRef(({ children, onClick }, ref) => (
        <LoggedInAvatar onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}>
            {children}      
        </LoggedInAvatar>
    ));

    // <LoggedOutAvatar onClick={(): void => setModalShow(true)}></LoggedOutAvatar>
    // <UserModal show={modalShow} onHide={(): void => setModalShow(false)} payload={payload} />

    if( !isLoggedIn ) {
        return (
            <React.Fragment>
                <LoggedOutAvatar {...onClickSignin}>
                    { isLoading ? <Loading className="mr-2" /> : <AvatarSVG /> }
                </LoggedOutAvatar>

                <SigninModal show={signinModalVisible} onHide={(): void => setSigninModalVisible(false)} payload={payload} onClickSignup={onClickSignup} />
                <SignupModal show={signupModalVisible} onHide={(): void => setSignupModalVisible(false)} payload={payload} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>

            <Notifications />

            <Dropdown id="dropdown-avatar">
                <Dropdown.Toggle as={AvatarToggle}>
                    <UserDisplay />
                </Dropdown.Toggle>
            
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">My Profile</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/post">Post</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/admin">Admin (temp)</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as="div">
                        <Logout type="LINK" />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            
        </React.Fragment>
    );
}

function SigninModal( props: SigninModalProps ) {
    return (
        <DostrikModal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title"
            >
            <Modal.Body>
                <Container>
                    <ModalClose close={props.onHide} />
                    <Row>
                        <Col md="6" className="loginBox">
                            <div className="p-3">
                                <h2>Login</h2>
                                <LoginForm />
                                {
                                    // <LoginForm payload={props.payload} />
                                }
                            </div>
                            
                        </Col>
                        <Col md={{span: 6}} className="registerBox">
                            <div className="p-3">
                                <h2 className="text-center">No account yet?</h2>
                                <Flex direction="column" align="center">
                                    <img src={props.payload.staticUrl+"public/images/logo192.png"} srcSet={props.payload.staticUrl+"public/images/logo192.png 2x"} className="mt-3" style={{width: '96px'}} />   
                                    <DefaultButton fontSize="1rem" padding="0.5rem 1rem" className="mt-3" onClick={props.onClickSignup}>Create an account</DefaultButton>
                                </Flex>
                            </div>
                        </Col>
                    </Row>
                    <LoadingLogin loading={props.payload.isLoading} />
                </Container>
            </Modal.Body>
      </DostrikModal>
    );
}

function SignupModal( props: SignupModalProps ) {
    return (
        <DostrikModal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title"
            >
            <Modal.Body>
                <ModalClose close={props.onHide} />
                <Container>
                    <Row>
                        <Col className="registerBox">
                            <div className="p-3">
                                <h2>Create an account</h2>
                                <SignupForm />
                                {
                                    // <RegisterForm payload={props.payload} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <LoadingLogin loading={props.payload.isLoading} />
            </Modal.Body>
      </DostrikModal>
    );
}