import { ID } from 'helpers';
import { Action } from '../types';
//import {  } from '../types/props';

export const ActionTypes = {
    LOAD_STATS: "LOAD_STATS",
    LOAD_STATS_SUCCESS: "LOAD_STATS_SUCCESS",
    LOAD_STATS_FAIL: "LOAD_STATS_FAIL",

    ADD_TICKER: "ADD_TICKER",
    ADD_TICKER_SUCCESS: "ADD_TICKER_SUCCESS",
    
    REMOVE_TICKER: "REMOVE_TICKER",

};

/* for reference
export const setLocale = (locale: Locale) => ({
    type: ActionTypes.SETLOCALE,
    payload: locale,
}); */


export const loadStats = () => ({
    type: ActionTypes.LOAD_STATS
});

export const addTickerToUpdateList = (id: number, ticker: string, name: string): Action => ({
    type: ActionTypes.ADD_TICKER,
    payload: {
        id: id,
        ticker: ticker,
        name: name
    }
});

export const removeTickerFromUpdateList = (id: number, ticker: string, name: string): Action => ({
    type: ActionTypes.REMOVE_TICKER,
    payload: {
        id: id,
        ticker: ticker,
        name: name
    }
});


export const Actions = {
    loadStats: loadStats,
    addTickerToUpdateList: addTickerToUpdateList,
    removeTickerFromUpdateList: removeTickerFromUpdateList,

};