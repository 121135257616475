import { produce } from 'immer';
import { SearchState } from '../types/state';
import { ActionTypes } from '../actions/search';
import { Action, SearchQueryResult } from '../types';
import { ErrorInit } from '../init';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<SearchState>({
    lastFetch: '',
    searchQuery: '',
    searchResults: [],
    searchResultsLoaded: false,
    error: ErrorInit
});
  
export default ( state: SearchState = initialState, action: Action ): SearchState =>
    produce(state, (draft) => {
        switch( action.type ) {                
            case ActionTypes.SEARCH_QUERY_UPDATE: {
              const searchQuery: string = action.payload;

              draft.searchQuery = searchQuery;
              return draft;
            }
      
            case ActionTypes.SEARCH_QUERY_SUCCESS: {
              
              const searchQueryResult: SearchQueryResult = action.payload;
              // check if error reinit is necessary here
              // draft.error = ErrorInit; // reinit error
              draft = { ...draft, searchResults: searchQueryResult.data, searchResultsLoaded: true, lastFetch: searchQueryResult.query };
              return draft;
            }
    
            case ActionTypes.SEARCH_QUERY_FAIL: {
              return draft;
            }
          
            case ActionTypes.SEARCH_RESET: {
              draft = { ...initialState };
              return draft;
            }
              
            default: {
              return draft;
            }
        }
    });