//import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from './rootReducer';
import { rootSaga } from "./app/sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

type StoreParams = {
    initialState?: { [key: string]: any };
    middleware?: any[];
};

const rootReducer = createRootReducer();

export type RootState = ReturnType<typeof rootReducer>;

export const configureStore = ({ initialState, middleware = [] }: StoreParams) => {

    const devtools =
        typeof window !== 'undefined' &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

    const composeEnhancers = devtools || compose;

    const store = createStore(
        rootReducer,
        initialState,
        //composeEnhancers(applyMiddleware(...[sagaMiddleware].concat(...middleware)))
        composeEnhancers(applyMiddleware( ...[sagaMiddleware].concat(...middleware) ))
    );

    //sagaMiddleware.run( rootSaga );

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./rootReducer', () =>
                store.replaceReducer(require('./rootReducer').default)
            );
        }
    }


    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END); // put const

    return store;
};

export default configureStore;
