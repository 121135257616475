import "regenerator-runtime/runtime";
import axios, { AxiosResponse } from 'axios';
 
import Auth from '../actions/auth/auth';
  
import Parser from 'rss-parser';
import { ISODate } from '../../../helpers';
import { AxiosReturn, ItemSource, RSSNewsReturn, TIntraday, UserLogin } from "../types";

/*
https://query2.finance.yahoo.com/v10/finance/quoteSummary/AAPL?formatted=true&crumb=8ldhetOu7RJ&lang=en-US&region=US&modules=defaultKeyStatistics%2CfinancialData%2CcalendarEvents&corsDomain=finance.yahoo.com
https://query2.finance.yahoo.com/v10/finance/quoteSummary/AAPL?formatted=true&crumb=8ldhetOu7RJ&lang=en-US&region=US&modules=recommendationTrend%2CfinancialData%2CcalendarEvents&corsDomain=finance.yahoo.com
https://stocksentiment.io/
https://www.reddit.com/user/CabbageMasher
https://api.swaggystocks.com/wsb/sentiment/top?limit=500
https://api.swaggystocks.com/wsb/sentiment/ticker
*/


//const dostrik = 'http://localhost:8080/'
const dostrik = 'https://api.dostrik.net/'
const auth = new Auth();

const headers = (withAuth: boolean = false): { 'Content-Type': string, 'authorization'?: string } => ({
    'Content-Type': 'application/json; charset=utf-8',
    ...withAuth && { 'authorization': 'Bearer '+auth.getSession().access }
});

// User
export function fetchUser( user: UserLogin ): AxiosReturn {
    const path = 'user/login';
    return axios.post( dostrik+path, user, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}


export function addUser({ username, email }: { username: string, email: string }): AxiosReturn {
    const path = 'v2/user/signup';
    return axios.post( dostrik+path, { username: username, email: email }, { headers: headers(true) } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}

// v2 login

export function fetchUserInfo( user: { email: string } ): AxiosReturn {
    const path = 'v2/user/login';
    return axios.post( dostrik+path, user, { headers: headers(true) } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function authUserData({ type, email, password, username }: { type: string, email: string, password: string, username?: string }) {
  //return auth.signin(email, password)
  switch( type ) {
    case 'SIGNIN': {
      return auth.signin(email, password)
        .then((user) => ({ result: { message: 'SUCCESS', info: user }}))
        .catch(error => ({ error }));
    }
    case 'SIGNUP': {
      if( username ) {
        return auth.signup(username, email, password)
          .then((user) => ({ signUpResult: { message: 'SUCCESS', info: user }}))
          .catch(signUpError => ({ signUpError }));
      }
    }
  }
}

// Search
export function fetchQuery( query: string, type: string = 'AUTOCOMPLETE' ): AxiosReturn {
    //const url = dostrik+'stocks/search'
    let url
    let params = {}

    switch( type ) {
        case 'SEARCH':
            url = 'https://www.alphavantage.co/query';
            params = {
                function: 'SYMBOL_SEARCH',
                keywords: query,
                apikey: '7BXWGIBZEDBYQWZT'
            };
            break;
        default:
            url = dostrik+'stocks/search';
            params = {
                //function: 'SYMBOL_SEARCH',
                q: query,
                apikey: ''
            };
    }

    return axios.get( url, { params: params } )
      .then(result => ({ result }))
      .catch(error => ({ error }));

    /*
    const regexp = /\{([A-Z_]+)\}/g;
    var str = 'https://www.alphavantage.co/query?function={ACTION}&keywords={QUERY}&apikey={API_KEY}';
    */
    
    //return axios.get( options.url, { params: { q: query } } )
}

// Watchlist
export function fetchUpdateWatchlist( data: { watchlistId: number, tickerId: number, date: string, type: string } ): AxiosReturn {
    const path = 'user/watchlist/' + ( data.type === 'ADD' ? 'add' : 'delete' );
    // change for post
    return axios.post( dostrik+path, { watchlistId: data.watchlistId, tickerId: data.tickerId, date: ISODate() }, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
  
// Company (Stocks)
export function fetchStocks(): AxiosReturn {
    //const url = dostrik+'stocks/search'
    const url = dostrik+'stocks/all';
    return axios.get( url, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function fetchFinancials( ticker: string ): AxiosReturn {
    //const url = dostrik+'stocks/search'
    const url = dostrik+'company/lookup/data?ticker='+ticker+'&type=ALL';
    return axios.get( url, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function fetchStocksByTag( data: { tag: string } ): AxiosReturn {
    //const url = dostrik+'stocks/search'
    const encodedTag = encodeURI( data.tag );
    const url = dostrik+'v2/stocks/tag/tickers?tag='+encodedTag;
    return axios.get( url, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function fetchStocksBySymbol( data: { symbol: string } ): AxiosReturn {
    const symbol = data.symbol;
    const url = dostrik+'v2/stocks/tag/tickers?ticker='+symbol;
    return axios.get( url, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function fetchCompanyData( ticker: string ): AxiosReturn {
    const url = dostrik+'company/lookup/info?ticker='+ticker+'&userId=0';
    return axios.get( url, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function fetchCompanyCalendar( ticker: string ): AxiosReturn {
    return axios.get( dostrik+'company/lookup/calendar?ticker='+ticker, { headers: headers() } )
      .then( result => ({ result }))
      .catch( error => ({ error }));
}
  
export function fetchIntradayData( ticker: string ): AxiosReturn {
      const url = dostrik+'v2/stocks/lookup?ticker='+ticker+'&type=INTRADAY';
      //return axios.get( url, { headers: headers(true) } )
      return axios.get( url, { headers: headers() } )
        .then(result => ({ result }))
        .catch(error => ({ error }));
}
  
export function fetchRangeData( payload: { ticker: string, range: string } ): AxiosReturn {
      const url = dostrik+'v2/stocks/lookup?ticker='+payload.ticker+'&range='+payload.range+'&type=HISTORICAL';
      return axios.get( url, { headers: headers() } )
        .then(result => ({ result }))
        .catch(error => ({ error }));
}
  
export function fetchSparkChartsData({ tickers, type }: { tickers: string, type: string }): AxiosReturn {
    const url = dostrik+'v2/stocks/spark?batch='+tickers+'&interval=15m';
    return axios.get( url, { headers: headers(true) } )
      .then(result => ({ result: { ...result, type: type } }))
      .catch(error => ({ error: { ...error, type: type } }));
}
  
export function fetchTickerData({ tickers, type }: { tickers: string, type: string }): AxiosReturn {
    const url = dostrik+'v2/stocks/tickers?batch='+tickers;
    //return axios.get( url, { headers: headers(true) } )
    return axios.get( url, { headers: headers(), timeout: 2000 } )
      .then(result => ({ result: { ...result, type: type } }))
      .catch(error => ({ error }));
}
  
export function fetchCryptoChart( payload: { ticker: string, currency: string, range: string, type: string } ): AxiosReturn {
    const ticker = (payload.ticker+'-'+payload.currency).toUpperCase();
    const url = dostrik+'v2/crypto/chart?ticker='+ticker+'&range='+payload.range+'&type='+payload.type;
    return axios.get( url, { headers: headers() } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}
  
export function fetchTrendingList( payload: { country: string } ): AxiosReturn {
    const country = payload.country;
    // add canada select. by default US.
    return axios.get( dostrik+'v1/get?trending=true&country='+country+'&json=true' )
      .then( result => ({ result }))
      .catch( error => ({ error }));
}
  
export function fetchFeaturedList( payload: { symbols: string } ): AxiosReturn {
    const symbols = payload.symbols;
    console.log(dostrik+'v2/stocks/spark?batch='+symbols+'&interval=15m');
    return axios.get( dostrik+'v2/stocks/spark?batch='+symbols+'&interval=15m', { headers: headers() } )
      .then( result => ({ result }))
      .catch( error => ({ error }));
}

// Stats, temporary
export function fetchStats(): AxiosReturn {
    const url = dostrik+'stocks/stats';
    //return axios.get( url, { headers: headers(true) } )
    return axios.get( url, { headers: headers(), timeout: 3000 } )
      .then(result => ({ result }))
      .catch(error => ({ error }));
}

// Post

export function fetchColorPalette( payload: { type: string, color?: [number, number, number] } ): AxiosReturn {
  const type = payload.type;
  const color = payload.color;

  /*
  type = list, random, suggest
  */
  let url = "http://colormind.io/api/";
  let model = "default"; // random
  let input;

  switch( type ) {
    case "list":
        url = "http://colormind.io/list/";
        model = "";
        break;
      case "suggest":
        url = "http://colormind.io/api/";
        input = { "input": [ color, "N", "N", "N", "N"] };
        break;
      default:
        url = "http://colormind.io/api/";
        model = "default";
  };

  const data = {
    model : "default",
    ...(input !== undefined) && { ...input },
	  //input : [[44,43,44],[90,83,82],"N","N","N"]
  }


  const postData = type !== "list" ? JSON.stringify(data) : null;

  console.log( postData );
  
  return axios.post( url, postData )
    .then(result => ({ result }))
    .catch(error => ({ error }));
}
  
  // News
export function fetchRSSNews( ticker: string ): RSSNewsReturn {
    const parser: Parser<ItemSource> = new Parser({
      timeout: 2000,
      customFields: {
        item: ['source','copyright'],
      }
    });

    return parser.parseURL( dostrik+'v1/rss.php?symbol='+ticker )
      .then( result => ({ result }))
      .catch( error => ({ error }));
}
  
export function fetchNewsList( type: number ): RSSNewsReturn {
    let url = dostrik+'v1/rss.php?headlines=true';
    switch( type ) {
      case 1:
        url = dostrik+'v1/rss.php?headlines=true';
        break;
      case 2:
        url = dostrik+'v1/rss.php?blogs=true';
        break;
    }
    const parser: Parser<ItemSource> = new Parser({
      timeout: 5000,
      customFields: {
        item: ['source','copyright'],
      }
    });

    return parser.parseURL( url )
      .then( result => ({ result }))
      .catch( error => ({ error }));
}