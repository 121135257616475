import { produce } from 'immer';
import { AppState } from '../types/state';
import { ActionTypes } from '../actions/app';
import { ActionTypes as WatchlistActionTypes } from '../actions/watchlist';
import { Action, INewsList, SelectedListUpdate } from '../types';
import { ErrorInit, NotifInit } from '../init';
import { PAUSED, PLAYING } from 'components/utils/const';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<AppState>({
    appUrl: 'http://app.dostrik.net/',
    staticUrl: 'https://dostrik.net/',
    locale: 'en_US',
    notification: NotifInit, // make its own reducer
    isLoaded: false,
    current: {
      page: 'Home', // Home, Cryptos, Stocks, ... better way to define? included in array?
      symbol: ''
    },
    ticker: {
      isLoaded: false,
      status: 'PAUSED',
      display: 'PRICE',
      selected: {
        id: "TRENDING",
        name: "Trending",
        length: 0
      }
    },
    watchlist: {
      isLoaded: false,
      display: 'PRICE',
      selected: {
        id: '',
        name: ''
      }
    },
    trending: {
      isLoaded: false
    },
    featured: {
      isLoaded: false,
    },
    news: {
        isFetching: false,
        isLoaded: false,
        list: [],
        error: ErrorInit
    }, // add categories?
    blogs: {
        isFetching: false,
        isLoaded: false,
        list: [],
        error: ErrorInit
    }, // add categories?
});

export default (state: AppState = initialState, action: Action): AppState =>
    produce(state, (draft) => {
        switch( action.type ) {
            case ActionTypes.APP_LOADED : {
                draft.isLoaded = true;

                return draft;
            }

            case ActionTypes.SETLOCALE: {
                draft.locale = action.payload;
                return draft;
            }
              
            case ActionTypes.CHANGE_PAGE: {
                draft.current = { 
                    page: action.payload.page, 
                    symbol: action.payload.symbol 
                };
                
                return draft;
            }

            case ActionTypes.PUSH_NOTIFICATION: {
                draft.notification = { 
                    title: action.payload.title, 
                    message: action.payload.message, 
                    type: action.payload.type, 
                    delay: action.payload.delay, 
                    callback: action.payload.callback 
                };
                
                return draft;
            }

            case ActionTypes.RESET_NOTIFICATION: {
                draft.notification = { ...NotifInit };
                return draft;
            }

            /*
            ######################################################
            ################  Frontpage News/Blogs  ##############
            ###################################################### */

            case ActionTypes.LOAD_NEWS: {
                draft.news = { ...draft.news, isLoaded: false, isFetching: true };
                return draft;
            }

            case ActionTypes.LOAD_NEWS_SUCCESS: {
                // remove list?
                const newsList: INewsList[] = action.payload.list;
                // error?
                //const newsSource = action.payload.source;
                draft.news = { ...draft.news, isLoaded: true, isFetching: false, list: newsList };
                return draft;
            }
            
            case ActionTypes.LOAD_NEWS_FAIL: {
                const newsFailError = action.payload.error;

                draft.news = {
                    ...draft.news,
                    isFetching: false,
                    error: {
                        status: true,
                        type: newsFailError.type,
                        message: newsFailError.message
                    }
                }
                return draft;
            }

            case ActionTypes.LOAD_BLOGS: {
                draft.blogs = { ...draft.blogs, isLoaded: false, isFetching: true };
                return draft;
            }

            case ActionTypes.LOAD_BLOGS_SUCCESS: {
                // remove list?
                const blogList: INewsList[] = action.payload.list;
                draft.blogs = { ...draft.blogs, isLoaded: true, isFetching: false, list: blogList };
                return draft;
            }
            
            case ActionTypes.LOAD_BLOGS_FAIL: {
                const blogsFailError = action.payload.error;

                draft.blogs = {
                    ...draft.news,
                    isFetching: false,
                    error: {
                        status: true,
                        type: blogsFailError.type,
                        message: blogsFailError.message
                    }
                }
            }

            

            /*
            ######################################################
            #####################  Tickerbar  ####################
            ###################################################### */

            case ActionTypes.UPDATE_TICKERBAR_STATUS: {
                switch( action.payload ) {
                    case true:
                        draft.ticker = { ...draft.ticker, status: PLAYING };
                        break;
                    case false:
                        draft.ticker = { ...draft.ticker, status: PAUSED };
                        break;
                }

                return draft;
            }

            case ActionTypes.UPDATE_TICKERBAR_VALUE: {
                const updatePayload = action.payload;

                draft.ticker = {
                    ...draft.ticker,
                    isLoaded: updatePayload.isLoaded,
                    status: PAUSED,
                    selected: {
                        id: updatePayload.id,
                        name: updatePayload.name,
                        length: updatePayload.length,
                    }
                };

                return draft;
            }

            case ActionTypes.TICKERBAR_LOADED: {
                draft.ticker.isLoaded = true;

                return draft;
            }

            /*
            ######################################################
            ######################  Watchlist  ###################
            ###################################################### */

            case WatchlistActionTypes.LOAD_ALL_WATCHLISTS_SUCCESS: {
                const tWatchlists = action.payload;
                const isEmpty = tWatchlists.length === 0;

                if( !isEmpty ) {
                    draft.watchlist.selected.id = tWatchlists[0].id;
                    draft.watchlist.selected.name = tWatchlists[0].name;
                }
                
                return draft;
            }

            case WatchlistActionTypes.LOAD_WATCHLIST_DATA_SUCCESS: {
                draft.watchlist.isLoaded = true;

                return draft;
            }

            case WatchlistActionTypes.UPDATE_SELECTED_WATCHLIST: {
                draft.watchlist.isLoaded = false;

            return draft;
            }

            case WatchlistActionTypes.UPDATE_SELECTED_WATCHLIST_SUCCESS: {
                draft.watchlist.isLoaded = true;
                draft.watchlist.selected.id = action.payload.key;
                draft.watchlist.selected.name = action.payload.name;

                return draft;
            }

            case WatchlistActionTypes.LOAD_TRENDING_SUCCESS: {
                //draft.trending.isLoaded = true;

                return draft;
            }

            // check
            case WatchlistActionTypes.LOAD_TRENDING_DATA: {
                console.log("   LOAD_TRENDING_DATA reducer");

                return draft;
            }

            case WatchlistActionTypes.LOAD_TRENDING_DATA_SUCCESS: {
                draft.trending.isLoaded = true;

                return draft;
            }

            default: {
                return draft;
            }
        }
    });
