import styled from "styled-components";

export const TickerBarError = styled.div`
    display: flex;
    flex-basis: 0;
    align-items: center;
    margin: 0 1.75rem 0 1rem;
    padding-left: 0 !important;

    button {
        font-size: 0.7rem;
        border-radius: 0.25rem;
        color: ${ props => props.theme.bearish};
        font-family: ${ props => props.theme.fonts.varela };
        border: 1px solid ${ props => props.theme.bearish};

        &:hover {
            color: ${ props => props.theme.bearish};
        }
    }

    svg {
        margin-left: 0.25rem;
    }
`;