import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Auth from 'store/app/actions/auth/auth';
import useSSR from 'use-ssr';

export function useVisible( initialIsVisible: boolean ) {
    const [ isVisible, setIsVisible ] = useState<boolean>( initialIsVisible );
    const ref = useRef<HTMLElement | undefined>();

    const handleClickOutside = ( event: MouseEvent )  => {
        const el = event.target as HTMLElement;
        if (ref.current && !ref.current.contains( el )) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isVisible, setIsVisible };
}

export function usePrevious( value: string ): string {
    const ref = useRef<number>();
    useEffect(() => {
      ref.current = parseFloat( value );
    });
    return String(ref.current);
}

export function useAuth() {
    const auth = new Auth();
    const dispatch = useDispatch();
    const user = useSelector( (state: RootState) => state.user );
    const { isBrowser, isServer, isNative } = useSSR();

    const isAuthenticated = isBrowser ? auth.isAuthenticated() : false;
    const isLoading = !isServer ? ( isAuthenticated !== false && !user.isLoggedIn ) : true;
    const isLoggedIn = user.isLoggedIn;
    const authInfo = isBrowser ? auth.getUserInfo() : null;


    useEffect(() => {

        // check if best way to check is user is logged out
        if( isBrowser 
            && !isLoading 
            && !isAuthenticated 
            && !isLoggedIn 
            ) {
            dispatch({ type: 'SET_LOADING', payload: false });
        }

        if( isBrowser && isAuthenticated ) {
            // Has local storage
            // Renew authentication after X time of last login.
            const userInfo = authInfo;

            /*
            auth.fetchUserInfo()
                .then( (info) => console.log( info ) );
                */
            
            if( user.info.id === null ) {
                console.log('User State empty, dispatch');
                dispatch({ type: 'SET_USER_INFO', payload: userInfo });
            }

            if( !auth.isLoaded() ) {
                console.log('Local storage empty, set user info');
                auth.setUserInfo( user.info );
            }

        }

    }, [isLoggedIn]);

    return {
        isAuthenticated: isAuthenticated,
        isLoggedIn: isLoggedIn,
        isLoading: isLoading,
        authInfo: authInfo,
        user: user,
    }
}