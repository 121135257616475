import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimateOnChange from 'react-animate-on-change';

import { checkUserState, checkUserWatchlists } from '../../store/app/selectors';
import { Actions } from '../../store/app/actions';

import {
    TickerHeaderContainer,
    IconButton,
    PriceContainer,
    LoadingText,
    Flex,
    DostrikModal,
    DefaultButton,
} from '../../styles';
import { usePrevious } from '../../hooks';
import {
    AddToWatchlistProps,
    ManageAlertsProps,
    TickerHeaderPriceProps,
    TickerHeaderProps,
    WatchlistModalProps,
} from 'store/app/types/props';
import { Container, Col, Row, Modal, Button } from 'react-bootstrap';
import { ModalClose } from 'components/utils';
import { formatDecimals } from 'helpers';
import { ArrowIcon } from 'components/icons';
import { SHOW_NAMES } from 'components/utils/const';

export default function TickerHeader(props: TickerHeaderProps): JSX.Element {
    const dispatch = useDispatch();
    const payload = props;
    const ticker = payload.ticker;

    const checkUser = useSelector(checkUserState);
    const watchlists = useSelector(checkUserWatchlists);

    const [modalShow, setModalShow] = useState<boolean>(false);

    const watchlistsLoaded = watchlists.length !== 0;

    const companyId = payload.companyId;
    const name = payload.name ? payload.name : payload.securityName;
    const isTrading = payload.isTrading;
    const isWatching = payload.isWatching;
    const currentPrice = payload.currentPrice;
    const displayPrice = payload.displayPrice !== null ? payload.displayPrice : 'sis';
    const prevPrice = displayPrice !== null ? usePrevious(displayPrice) : '';
    const currency = payload.currency ? '$' + payload.currency : '';

    const previousClosePrice = payload.previousClosePrice;
    // manage if 0, no class
    const dayChange = payload.dayChange !== null ? parseFloat(payload.dayChange) : 0;
    const dayChangeDisplay = `${formatDecimals(Math.abs(dayChange))}%`;
    const isBullish = Math.sign(dayChange) === 1;
    const statusDay = isBullish ? 'bullish' : 'bearish';
    const dayChangeIcon = dayChange ? <ArrowIcon status={statusDay} /> : null;

    const intradayData = payload.intradayData.data;
    const priceDate =
        (typeof intradayData !== 'string') ? (
            DateTime.fromMillis(intradayData[intradayData.length - 1].time * 1000).toLocaleString( DateTime.DATETIME_MED_WITH_WEEKDAY )
        ) : ``;

    useEffect(() => {
        if (modalShow && isWatching) {
            setModalShow(false);
        }
    }, [isWatching]);

    const addToWatchlist = (watchlist: number, id: number, price: string) => {
        if (!checkUser.isLoggedIn) {
            alert('Must be logged in');
        } else {
            dispatch(Actions.addTickerToWatchList(watchlist, id, ticker, price));
        }
    };

    const removeFromWatchlist = (id: number, price: string /* price not necessary? */) => {
        if (!checkUser.isLoggedIn) {
            alert('Must be logged in');
        } else {
            dispatch(Actions.removeTickerFromWatchlist(id, ticker, checkUser.info.id));
        }
    };

    return (
        <TickerHeaderContainer>
            <div className="infoTicker">
                <React.Fragment>

                    <DisplayName
                        ticker={ticker}
                        name={name}
                        />

                    <div className="currentPrice">
                        <span className="title" suppressHydrationWarning>
                            {isTrading ? 'Current' : priceDate} {currency}
                        </span>
                        <PriceContainer>
                            <Price
                                statusLast={statusDay}
                                currentPrice={currentPrice}
                                displayPrice={displayPrice}
                                prevPrice={prevPrice}
                            />
                            {dayChange ? (
                                <span className={`change ${statusDay}`}>
                                    {dayChangeIcon} {dayChangeDisplay}
                                </span>
                            ) : null}
                        </PriceContainer>
                    </div>
                    {previousClosePrice !== 'No data' ? (
                        <div className="previousClosePrice">
                            <span className="title">Previous Close</span>
                            <span className="price">{previousClosePrice}</span>
                        </div>
                    ) : null}
                </React.Fragment>
                <ManageAlerts
                    companyId={companyId}
                    hasAlert={false}
                    createAlert={addToWatchlist}
                    removeAlert={removeFromWatchlist}
                />
                <AddToWatchlist
                    companyId={companyId}
                    isWatching={isWatching}
                    showModal={() => setModalShow(true)}
                />
                {checkUser.isLoggedIn && watchlistsLoaded ? (
                    <WatchlistModal
                        watchlists={watchlists}
                        companyId={companyId}
                        isWatching={isWatching}
                        currentPrice={currentPrice}
                        show={modalShow}
                        onHide={(): void => setModalShow(false)}
                        addToWatchlist={addToWatchlist}
                        removeFromWatchlist={removeFromWatchlist}
                    />
                ) : null}
            </div>
        </TickerHeaderContainer>
    );
}

// add use previous to use like useVisible
function Price(props: TickerHeaderPriceProps): JSX.Element {
    const currentPrice = props.currentPrice;
    const isLoading = currentPrice === null;
    const prevPrice = props.prevPrice ? parseFloat(props.prevPrice) : null;
    const animate = prevPrice && !isLoading ? currentPrice !== prevPrice : false;
    const statusNow =
        prevPrice && !isLoading ? (currentPrice > prevPrice ? 'bullish' : 'bearish') : '';

    return (
        <React.Fragment>
            {!isLoading ? <span className="currencySign">$</span> : null}
            <AnimateOnChange
                baseClassName={'price'}
                animationClassName={'Ticker-' + statusNow + '--update'}
                animate={animate}
            >
                {isLoading ? <LoadingText /> : props.displayPrice}
            </AnimateOnChange>
        </React.Fragment>
    );
}

function AddToWatchlist(props: AddToWatchlistProps): JSX.Element {
    const handleHover: MouseEventHandler<SVGSVGElement> = (event): void => {
        switch (event.type) {
            case 'mouseenter':
                return setHover(true);
            case 'mouseleave':
                //ReactTooltip.hide()
                return setHover(false);
        }
    };
    const [hover, setHover] = useState<boolean>(false);
    const isWatching = props.isWatching;
    const icon = !isWatching ? (hover ? 'fas' : 'fal') : hover ? 'fal' : 'fas';

    return (
        <div className="ml-2 addToWatchlist">
            <IconButton display="watching" selected={isWatching} onClick={props.showModal}>
                <FontAwesomeIcon
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                    icon={[icon, 'star']}
                    size="2x"
                />
            </IconButton>
        </div>
    );
}

function ManageAlerts(props: ManageAlertsProps): JSX.Element {
    const handleHover: MouseEventHandler<SVGSVGElement> = (event): void => {
        switch (event.type) {
            case 'mouseenter':
                return setHover(true);
            case 'mouseleave':
                //ReactTooltip.hide()
                return setHover(false);
        }
    };
    const [hover, setHover] = useState(false);
    const hasAlert = props.hasAlert;
    const icon = !hasAlert ? (hover ? 'fas' : 'fal') : hover ? 'fal' : 'fas';

    return (
        <div className="ml-auto mr-2 addToWatchlist">
            <IconButton display="alert" selected={hasAlert} onClick={() => alert('To do')}>
                <FontAwesomeIcon
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                    icon={[icon, 'bell']}
                    size="2x"
                />
            </IconButton>
        </div>
    );
}

function WatchlistModal(props: WatchlistModalProps) {
    const watchlists = props.watchlists;

    const [selected, setSelected] = useState<number>(watchlists[0].id);

    // manage if already in watchlist, remove or add to another watchlist.
    // create reusable "Create watchlist" component?
    const handleSubmit = (event: React.SyntheticEvent) => {
        console.log(selected);
        event.preventDefault();
        props.addToWatchlist(selected, props.companyId, props.currentPrice);
        // () => !isWatching ? props.addToWatchlist( props.companyId, props.currentPrice ) : props.removeFromWatchlist( props.companyId, props.currentPrice )
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelected(parseInt(event.target.value));
    };

    return (
        <DostrikModal
            show={props.show}
            onHide={props.onHide}
            size="sm"
            aria-labelledby="contained-modal-title"
        >
            <Modal.Body>
                <Container>
                    <ModalClose close={props.onHide} />
                    <Row>
                        <Col className="loginBox">
                            <div className="p-3">
                                <h2>Select Watchlist</h2>
                                <form className="watchlist-form" onSubmit={handleSubmit}>
                                    <Flex>
                                        <select aria-label="Watchlists" onChange={handleChange}>
                                            {watchlists.map((watchlist) => (
                                                <option key={watchlist.id} value={watchlist.id}>
                                                    {watchlist.name}
                                                </option>
                                            ))}
                                        </select>
                                        <DefaultButton className="ml-3" fontSize="1rem" type="submit">
                                            Add
                                        </DefaultButton>
                                    </Flex>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </DostrikModal>
    );
};

const DisplayName = ( props: { ticker: string, name: string }) => {
    const { ticker, name } = props;

    // maybe optimize to avoid repeat? check

    if( SHOW_NAMES.includes( ticker ) ) {
        return (
            <div className="companyInfo">
                <span className="companyName">{ticker}</span>
                <h1>{name}</h1>
            </div>
        );
    }

    return (
        <div className="companyInfo">
            <span className="companyName">{name}</span>
            <h1>{ticker}</h1>
        </div>
    );
};