import { SignoutButton } from 'components/layout/Header/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Auth from 'store/app/actions/auth/auth';
import { logout } from 'store/app/actions/user';
//import { SignoutButton } from 'components/layout/Header/styles';
import { DefaultButton } from 'styles';

export function Logout(props: { type: "BUTTON" | "LINK" }): JSX.Element {

    const auth = new Auth();
    const dispatch = useDispatch();
    const history = useHistory();

    const { type } = props;

    const Logout = () => {
        dispatch( logout() );
        auth.signout();
        history.push('/');
    }

    if( type === "BUTTON" )
        return <DefaultButton onClick={Logout}>Logout</DefaultButton>;
    
        
    return <SignoutButton onClick={Logout}>Logout</SignoutButton>;

}