import React, { useEffect } from 'react';
import i18next from 'i18next';
//import i18nextXHRBackend from 'i18next-xhr-backend';
import HttpApi from 'i18next-http-backend';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLocale } from '../store/app/selectors';

//import deDE from './locales/de_DE/translation.json';
import enUS from './locales/en_US/translation.json';
import frCA from './locales/fr_CA/translation.json';

if (__BROWSER__) {
    i18next.use(HttpApi);
    // old code, if error, use nextXHRBackend (but is deprecated)
    // i18next.use(i18nextXHRBackend);
}

// i18next.use(__BROWSER__ ? i18nextXHRBackend : {}).init({
i18next.init({
    backend: {
        // for all available options read the backend's repository readme file
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
        // Must be false until Suspense is supported on the server side
        useSuspense: false,
        wait: true,
    },
    debug: process.env.NODE_ENV === 'development' && __BROWSER__,
    fallbackLng: 'en_US',
    fallbackNS: ['translation'],
    // This option is necessary to tell i18next to try loading missing resources via
    // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
    partialBundledLanguages: true,
    resources: {
        en_US: { translation: enUS },
        fr_CA: { translation: frCA },
    },
    parseMissingKeyHandler: (missing: any) => {
        if (process.env.NODE_ENV === 'development' && __BROWSER__) {
            console.warn('Missing translation:', missing);
        }
        return missing;
    },
});

i18next.languages = ['en_US', 'fr_CA'];

const I18N: React.FC<any> = ({ children }) => {
    const locale = useSelector(getLocale); 
    console.log("Locale: "+locale);
    
    useEffect(() => {
        i18next.changeLanguage(locale);
    }, [locale]);

    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};

export default React.memo(I18N);
