import { Action } from '../types';
//import {  } from '../types/props';

export const ActionTypes = {
    SEARCH_QUERY: "SEARCH_QUERY",
    SEARCH_QUERY_UPDATE: "SEARCH_QUERY_UPDATE",
    SEARCH_QUERY_SUCCESS: "SEARCH_QUERY_SUCCESS",
    SEARCH_QUERY_FAIL: "SEARCH_QUERY_FAIL",
    SEARCH_RESET: "SEARCH_RESET"
};

/* For reference
export const login = ( user: TUserLoginProp ) => ({
    type: ActionTypes.LOGIN_ATTEMPT,
    payload: user
}); */

export const search = ( query: string, last: string ): Action => {
    if( query === '' ) {
        return { type: ActionTypes.SEARCH_RESET }
    } else {
        return {
            type: ActionTypes.SEARCH_QUERY,
            payload: {
                query: query,
                last: last
            }
        }
    }
}

export const Actions = {
    search: search,
};