import React from "react";
import styled from "styled-components";

export const Footer = styled.div`
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid ${ props => props.theme.pixelPopLight };
    background-color: ${ props => props.theme.footer.background };

    h5 {
        font-family: ${ props => props.theme.fonts.varela };
    }
`;