import { ID } from 'helpers';
import { 
    Error, 
    Notification, // app
    Tag, Financials, // stocks
    UserType,
    ITickerData
} from './types';

// Global

export const ErrorInit: Error = {
    status: false,
    type: "",
    message: ""
};

// App

export const NotifInit: Notification = {
    title: "",
    message: "",
    type: "",
    delay: 5000,
    callback: (): void => { console.log('..') }
};

// Post


    // put in init file
export const SeriesItemInit = {
    id: 0,
    color: "#FFFFFF",
    name: "",
    value: "",
    annotation: {
        display: false,
        title: "",
        image: "",
        color: ""
    }
};

export const SeriesInit = {
    id: `s_`+ID(),
    color: "#FF9900",
    name: "",
    nextItemId: 1,
    items: [SeriesItemInit]
};

// Settings


// Stocks

export const TagsInit: Tag = {
    isLoaded: false, 
    isFetching: false, 
    tickers: [], 
    selected: '', 
    error: ErrorInit
}

export const FinInit: Financials = { 
    isLoaded: false, 
    isFetching: false,
    balance: [], 
    cash: [], 
    earnings: [], 
    income: [], 
};


export const TickerDataInit: ITickerData = {
    id: 0,
    symbol: "",
    ticker: "",
    name: "",
    type: "",
    price: null,
    data: [],
    status: "",
    changePercent: null,
    changePrice: null,
    previousClose: null,
    previousDate: "",
    isLoaded: false,
    sub: false,
    watching: false
};

// User

export const UserInit: UserType = {
    id: null,
    username: null,
    email: null,
    lastLogin: null,
    firstLogin: false,
    verified: false,
};

// Crypto List

export const CryptoListInit = [{
    name: ('Bitcoin').toLowerCase(),
    ticker: 'BTC',
    currencies: {
        usd: 0,
        cad: 0,
        eur: 0
    },
    data: []
  },
  {
    name: ('Ethereum').toLowerCase(),
    ticker: 'ETH',
    currencies: {
        usd: 0,
        cad: 0,
        eur: 0
    },
    data: []
}];