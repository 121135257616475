import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Border, DostrikButton, DostrikModal, Flex } from 'styles';
import { PostState } from 'store/app/types/state';
import { Actions } from 'store/app/actions/post';
import { DashboardBackground, DashboardState } from 'store/app/types';
import { PreviewImage } from 'pages/Post/styles';

type DashboardImageModalProps = { 
    show: boolean, 
    hide: ( save: boolean ) => false | void;
};

export default function DashboardImageModal( props: DashboardImageModalProps ): JSX.Element {

    const dispatch = useDispatch();

    const dashboardState = useSelector( (state: { post: PostState }) => state.post.dashboard );
    const imageBackground = dashboardState.settings.logo.background;

    const [ imageState, setImageState ] = useState<{ image: string, previewImage: string, background: DashboardBackground }>({ image: dashboardState.image, previewImage: dashboardState.image, background: imageBackground });
    const [ error, setError ] = useState<string | null>(null);
    const { show, hide } = props;
    
    const refPreview = useRef<HTMLImageElement>();

    useEffect(() => {
        if( refPreview.current ) {
            refPreview.current.addEventListener('error', (event) => {
                setError("Image doesn't exist");
            });
        }
    }, [error]);

    useEffect(() => {
        setError(null);
    }, [imageState.previewImage]);

    // verif url
    // check upload?
    const handleSave = (event: React.MouseEvent) => {
        hide(true);
        const returnState = {...dashboardState, image: imageState.image, settings: {...dashboardState.settings, logo: {...dashboardState.settings.logo, background: imageState.background }}};
        dispatch( Actions.setDashboard( returnState ) );
    }

    const handleCancel = (event: React.MouseEvent) => {
        if( dashboardState.image !== imageState.image ) {
            return hide(false);
        }
        
        return hide(true);
    }

    const handleChange = ( background: string ) => {
        const tBackground = ["transparent", "dark", "light"];

        if( tBackground.includes(background) ) {
            console.log( imageState );
            setImageState({...imageState, background: background as DashboardBackground });
        } else {
            console.log('Background error');
        }
    }

    const handlePreview = (event: React.MouseEvent) => {

        return setImageState({...imageState, previewImage: imageState.image })  
    }
            
    return (
        <DostrikModal
            show={show}
            onHide={() => hide(false)}
            className="dashboard-image"
            size="lg"
            aria-labelledby="contained-modal-title"
            >
            <DostrikModal.Body>
                <Container>
                    <Row>
                        <Col lg="8">
                            <h3>Background color</h3>
                            <Flex>
                                    <Flex align="center" className="mr-3">
                                        <Form.Check
                                            type="radio"
                                            label="Transparent"
                                            name="bgColor"
                                            id="transparent"
                                            checked={imageState.background === "transparent"}
                                            onChange={(event: React.ChangeEvent) => handleChange(event.target.id)}
                                            />
                                    </Flex>
                                    <Flex align="center" className="mr-3">
                                        <Form.Check
                                            type="radio"
                                            label="Light"
                                            name="bgColor"
                                            id="light"
                                            checked={imageState.background === "light"}
                                            onChange={(event: React.ChangeEvent) => handleChange(event.target.id)}
                                            />
                                    </Flex>
                                    <Flex align="center" className="mr-3">
                                        <Form.Check
                                            type="radio"
                                            label="Dark"
                                            name="bgColor"
                                            id="dark"
                                            checked={imageState.background === "dark"}
                                            onChange={(event: React.ChangeEvent) => handleChange(event.target.id)}
                                            />
                                    </Flex>
                            </Flex>

                            <h3 className="mt-3">Image source</h3>
                            <Flex>
                                <input style={{ display: "flex", flexGrow: 1 }} defaultValue={imageState.image} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setImageState({...imageState, image: event.target.value })} />
                                <DostrikButton className="ml-2" onClick={handlePreview}>Preview</DostrikButton>
                            </Flex>
                            
                            <Flex className="mt-2">
                                <DostrikButton onClick={handleSave}>Save</DostrikButton>
                                <DostrikButton className="ml-2" onClick={handleCancel}>Cancel</DostrikButton>
                            </Flex>

                        </Col>
                        <Col lg="4">
                            <h3>Preview</h3>
                            <PreviewImage background={imageState.background}>
                                { error !== null ?
                                    <div>{error}</div> :
                                    <img src={imageState.previewImage} ref={refPreview as any} />
                                }
                                
                            </PreviewImage>
                        </Col>
                    </Row>
                </Container>
            </DostrikModal.Body>
        
        </DostrikModal>
    );
}
