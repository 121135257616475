import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { Flex } from 'styles';

/* Header */

export const Header = styled.header<{ isLoggedIn: boolean }>`
    position: relative;
    z-index: 99;
    
    .navbar {
        border-bottom: ${ props => props.isLoggedIn ? '1px solid '+props.theme.pixelPopDark : '0' };
    }
`;

export const SiteHeaderBackground = styled.div`
    background: ${ props => props.theme.header.background};
    box-shadow: ${ props => props.theme.header.shadow};
`;

export const FeaturedBackground = styled.div`
    background: ${ props => props.theme.header.featured.background };
`;

export const NavItem = styled.div<{ selected?: boolean }>`
    a {
        padding: 0.5rem 0.75rem;
        border-radius: .25rem;
        margin-right: 0.75rem;
        color: ${ props => props.selected ? props.theme.textActive : props.theme.text };
        border-bottom: 2px solid ${ props => props.selected ? props.theme.border : 'transparent' };

        &:hover {
            text-decoration: none;
            color: ${ props => props.theme.textActive };
        }
    }
`;

export const UserHeader = styled.div`
    display: flex;
    padding-top: 2px;
    margin-right: 0.75rem;
    align-items: center;

    .dropdown-menu {
        right: 0;
        left: initial;
        top: 120%;

        &:after {
            content: '';
            position: absolute;
            top: -7px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #FFFFFF;
        }
    }

    #dropdown-avatar {
        .dropdown-menu {
            &:after {
                right: 23px;
            }
        }
    }

    .react-toggle {
        svg {
            position: absolute;
            top: -4px;
            left: -3px;
            color: #FFFFFF;

            &.fa-moon {
                top: -2px;
                left: 0px;
            }
        }
    }
`;

// Search

export const SearchIcon = styled.div<{focus: boolean}>`
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 999;

    svg.svg-inline--fa.fa-w-16 {
        width: 1.75rem;
        height: 1.75rem;
        max-width: 1.75rem;
        max-height: 1.75rem;
    }
`;

export const SearchInput = styled( Form.Control )`
    z-index: 99;
    min-width: 295px;
    position: relative;
    font-size: 0.9rem;
    border-radius: 0;
    border: 1px solid ${ props => props.theme.header.search.border };
    background-clip: border-box;
    height: calc( 1.5em + .75rem + 10px);
    border-radius: 0.25rem;
    padding-left: 45px;
    color: ${props => props.theme.text};
    background: ${props => props.theme.header.search.input};

    &:focus {
        color: ${props => props.theme.text};
        background: initial;
    }

    &::placeholder {
        color: ${props => props.theme.text};
        opacity: 0.8;
    }

    &::-ms-input-placeholder {
        color: ${props => props.theme.text};
        opacity: 0.8;
    }
`;

    /* Ticker Container */

    export const TickerBarContainer = styled.div<{isLoggedIn: boolean}>`
        border-top: 1px solid ${ props => props.theme.border };
        min-height: 35px;
        font-size: 0.8rem;
        display: flex;

        .tickerbar-settings {
            min-width: ${ props => props.isLoggedIn ? '175px' : '125px' };
            max-width: 175px;
            display: flex;
            align-items: center;
            padding-right: 0.5rem;
            border-right: 1px solid ${ props => props.theme.border };
            margin-right: 0.5rem;

            /*
            .dropdown-toggle {
                background: none;
                border: 0px;
                font-size: 0.8rem;
                padding: 3px 6px;
                color: ${ props => props.theme.text };

                &:hover,
                &[aria-expanded="true"] {
                    background: ${ props => props.theme.darker };
                    color: ${ props => props.theme.text };
                }

                &:focus {
                    outline: 0;
                    box-shadow: 0px 0px 0px transparent;
                }
            }

            .dropdown-menu {
                background: ${ props => props.theme.darker };
                min-width: 8rem;
                transform: translate(0px, 21px) !important;
                border: none;

                .dropdown-divider {
                    border-top-color: ${ props => props.theme.border };
                }

                a {
                    color: ${ props => props.theme.text };
                    font-size: 0.8rem;
                    padding: 4px 10px;
                  
                    &:hover,
                    &:active {
                        background: ${ props => props.theme.darker };
                    }
                }
            }
  
            .dropdown-toggle::after {
                margin-left: .455em;
                vertical-align: 0.195em;
                color: $light;
            }

            */
        }

        #update-ticker {
            font-family: ${ props => props.theme.fonts.varela };
            background: none;
            border: 0px;
            font-size: 0.8rem;
            padding: 4px 6px 3px 6px;
            color: $light;
        }

        #featured-list {
            overflow: hidden;
            width: 100%;
            margin: 0 0.5rem;
            white-space: nowrap;
            position: relative;
            display: flex;
            align-items: center;

            > div {
                width: 100%;
            }

            span.tickerDisplay {
                display: flex;
                font-size: 0.8rem;
                font-family: ${ props => props.theme.fonts.varela };
                padding-right: 25px;

                .svg-inline--fa {
                    &.fa-caret-up,
                    &.fa-caret-down {
                        font-size: 0.75rem;
                        margin-right: 3px;
                    }

                    &.fa-star {
                        opacity: 0.75;
                        color: #e3c707;
                        vertical-align: text-top;
                    }
                }

                .valueDisplay {
                    padding-left: 0.5rem;
                    font-family: monospace;
                    line-height: 1.1rem;

                    span.ticker > span {
                        position: relative;
                    }
                }
            }
        }

        .icon {
            vertical-align: -3px;
        }

        .marquee {
            overflow: hidden;
        }

        .marquee-container {
            height: auto !important;
        }

        .svg-inline--fa {
            vertical-align: -2px;
        }
    `;

    export const TickerBarDisplayContainer = styled(Flex)`
        overflow: hidden;
        position: relative;
        min-width: 100px;

        &:before {
            content: "";
            position: absolute;
            z-index: 99;
            left: 0.5rem;
            width: 20px;
            height: 100%;
            background: transparent;
            background: -moz-linear-gradient(270deg, rgba(${props => props.theme.header.marquee.gradientRGB},0) 0%, rgba(${props => props.theme.header.marquee.gradientRGB},1) 100%);
            background: -webkit-linear-gradient(270deg, rgba(${props => props.theme.header.marquee.gradientRGB},0) 0%, rgba(${props => props.theme.header.marquee.gradientRGB},1) 100%);
            background: linear-gradient(270deg, rgba(${props => props.theme.header.marquee.gradientRGB},0) 0%, rgba(${props => props.theme.header.marquee.gradientRGB},1) 100%);
        }

        &:after {
            content: "";
            position: absolute;
            z-index: 99;
            right: 0.5rem;
            width: 20px;
            height: 100%;
            background: transparent;
            background: -moz-linear-gradient(90deg, rgba(${props => props.theme.header.marquee.gradientRGB},0) 0%, rgba(${props => props.theme.header.marquee.gradientRGB},1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(${props => props.theme.header.marquee.gradientRGB},0) 0%, rgba(${props => props.theme.header.marquee.gradientRGB},1) 100%);
            background: linear-gradient(90deg, rgba(${props => props.theme.header.marquee.gradientRGB},0) 0%, rgba(${props => props.theme.header.marquee.gradientRGB},1) 100%);
        }
    `;

    /* // */

    

    export const SignoutButton = styled.button`
        color: ${ props => props.theme.text };
    `;

    // temp

    export const HeaderMenuItem = styled.button`
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #22313e;

        &:hover {
            background-color: #304658;

            svg > * {
                fill: #F8F8F8;
            }
        }
    `;

    export const LoggedOutAvatar = styled(HeaderMenuItem)`
        margin-right: 0.5rem;
    `;

    export const LoggedInAvatar = styled.button`
        margin-right: 0.5rem;
        padding: 1px 6px;
        position: relative;

        &:hover img {
            opacity: 0.8;
        }
    `;

    export const LiveIndicator = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0.5rem;

    &:after {
        content: '';
        background: ${ props => ( props.color === 'green' ) ? props.theme.marketIndicator.live : ( ( props.color === 'red' ) ? props.theme.marketIndicator.trading : props.theme.marketIndicator.offline ) };
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        margin: 0px;
        height: 10px;
        width: 10px;
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
        animation: ${ (props: any) => props.isTrading ? 'pulse-'+props.color+' 2s infinite' : 'none' };
    }

    @keyframes pulse-red {
        0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
        }
        
        70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
        }
        
        100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
        }
    }
    
    .blob.green {
        background: rgba(51, 217, 178, 1);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
        animation: pulse-green 2s infinite;
    }
    
    @keyframes pulse-green {
        0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
        }
        
        70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
        }
        
        100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
        }
    }
    
`;

/* // Blob */

/* // Header */