import { Featured } from "components/display/Featured";
import { LoggedOutMessage } from "components/display/LoggedOut";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "store/app/types/state";
import styled from "styled-components";

export function Header( props: { title?: string; isLoggedIn: boolean }): JSX.Element {

    const { title, isLoggedIn } = props;
    const staticUrl = useSelector((state: { app: AppState }) => state.app.staticUrl);

    return (
        <ContentHeader>
            <Container>
                {
                    !isLoggedIn ? (
                        <LoggedOutMessage url={staticUrl} />
                    ) : null 
                }
                {
                    isLoggedIn ? <Featured /> : null
                }
                {
                    typeof title === "string" ?
                        <h2>{title}</h2> : null
                }
            </Container>
        </ContentHeader>
    );
};

const ContentHeader = styled.div`
    position: relative; 
    padding: 2rem 0 6.5rem 0;
    margin-bottom: -5.5rem;
    box-shadow: inset 0px -1px 5px rgba(0,0,0,0.3);
    background-color: ${ props => props.theme.header.featured.background };

    &:before {
            content: "";
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            /*background: url('https://www.dostrik.net/public/images/header-bg2.jpg') top center;*/
            background-position-y: -120px;
            filter: opacity(0.1);
    }
`;