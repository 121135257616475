import { produce } from 'immer';
import { UserState } from '../types/state';
import { ActionTypes } from '../actions/user';
import { Action, UserType, UserTypeFromSession } from '../types';
import { ErrorInit, UserInit } from '../init';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<UserState>({
    isLoggedIn: false,
    isLoading: true,
    info: UserInit,
    error: ErrorInit,
    // attempts: 0, see if useful, or server side?
    register: {
        isLoading: false,
        error: ErrorInit,
    },
});
  
export default ( state: UserState = initialState, action: Action ): UserState =>
    produce(state, (draft) => {
        switch( action.type ) {

            case ActionTypes.SET_USER_INFO: {
                const userInfo: UserType | UserTypeFromSession = action.payload;
                draft = { 
                    ...draft, 
                    isLoggedIn: true, 
                    isLoading: false, 
                    info: { 
                        id: typeof userInfo.id === 'string' ? parseInt(userInfo.id) : userInfo.id, 
                        username: userInfo.username, 
                        email: userInfo.email, 
                        lastLogin: typeof userInfo.lastLogin === 'string' ? parseInt(userInfo.lastLogin) : userInfo.lastLogin,
                        firstLogin: false, // temp, check
                        verified: typeof userInfo.verified === 'string' ? userInfo.verified === 'true' : userInfo.verified,
                        avatar: userInfo.avatar
                    }
                };
                return draft; 

            }

            case ActionTypes.SET_LOADING: {
                draft.isLoading = action.payload;

                return draft;                
            }
                
            case ActionTypes.GET_USER_INFO:
                draft.isLoading = true;
                return draft; 

            case ActionTypes.LOGIN_ATTEMPT:
                draft = { 
                    ...draft,
                    isLoading: true,
                    error: ErrorInit
                };
                return draft;
                
            case ActionTypes.LOGIN_SUCCESS:
                const loginInfo: UserType = action.payload;
                draft = { 
                    ...draft, 
                    isLoggedIn: true, 
                    isLoading: false, 
                    info: { 
                        id: loginInfo.id, 
                        username: loginInfo.username, 
                        email: loginInfo.email, 
                        avatar: loginInfo.avatar,
                        lastLogin: loginInfo.lastLogin,
                        firstLogin: loginInfo.firstLogin,
                        verified: loginInfo.verified,
                    }
                };
                return draft;        
                
            case ActionTypes.LOGIN_FAIL:
                draft = { 
                    ...draft,
                    isLoading: false,
                    error: {
                        status: true,
                        type: 'to do...',
                        message: 'Error logging in...'
                    }
                };
                return draft;
                
            case ActionTypes.LOGOUT:
                draft = { ...initialState };
                return draft;

            case ActionTypes.REGISTER_ATTEMPT:
                draft.register = {
                    isLoading: true,
                    error: { ...ErrorInit },
                };
                return draft;
                
            case ActionTypes.REGISTER_SUCCESS:
                draft.register.isLoading = false;
                return draft;        
                
            case ActionTypes.REGISTER_FAIL:
                draft.register = {
                    isLoading: false,
                    error: {
                        status: true,
                        type: 'to do...',
                        message: 'Error registering...'
                    }
                };
                return draft;
        
            default:
                return draft;
        }
    });