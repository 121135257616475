import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import Toggle from "react-toggle";
import { Footer as SiteFooter } from "./styles";

export function Footer(): JSX.Element {
    const dispatch = useDispatch();
    
    const toggleTheme = (): void => {
        dispatch({ type: 'TOGGLE_THEME' });
    };

    const Sun = (): JSX.Element => (
        <svg style={{ width: '18px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.37 162.37">
            <defs></defs>
            <circle
                cx="81.23"
                cy="81.18"
                r="45.39"
                fill="none"
                stroke="#e9e98a"
                strokeMiterlimit="10"
                strokeWidth="15"
            />
            <circle fill="#e9e98a" cx="81.18" cy="9.11" r="9.11" />
            <circle fill="#e9e98a" cx="81.18" cy="153.26" r="9.11" />
            <circle fill="#e9e98a" cx="153.26" cy="81.18" r="9.11" />
            <circle fill="#e9e98a" cx="9.11" cy="81.18" r="9.11" />
            <circle fill="#e9e98a" cx="144.14" cy="46.09" r="9.11" />
            <circle fill="#e9e98a" cx="18.23" cy="116.28" r="9.11" />
            <circle fill="#e9e98a" cx="116.28" cy="144.14" r="9.11" />
            <circle fill="#e9e98a" cx="46.09" cy="18.23" r="9.11" />
            <circle fill="#e9e98a" cx="120.03" cy="20.48" r="9.11" />
            <circle fill="#e9e98a" cx="42.34" cy="141.89" r="9.11" />
            <circle fill="#e9e98a" cx="141.89" cy="120.03" r="9.11" />
            <circle fill="#e9e98a" cx="20.48" cy="42.34" r="9.11" />
        </svg>
    );
    
    return (
        <SiteFooter className="page-footer font-small stylish-color-dark pt-4">

            <div className="container text-center text-md-left">

                <div className="row">

                    <div className="col-md-4 mx-auto">

                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Footer Content</h5>
                        <p>Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet,
                        consectetur
                        adipisicing elit.</p>

                    </div>

                    <hr className="clearfix w-100 d-md-none" />

                    <div className="col-md-2 mx-auto">

                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Links</h5>

                        <ul className="list-unstyled">
                        <li>
                            <a href="#!">Link 1</a>
                        </li>
                        <li>
                            <a href="#!">Link 2</a>
                        </li>
                        <li>
                            <a href="#!">Link 3</a>
                        </li>
                        <li>
                            <a href="#!">Link 4</a>
                        </li>
                        </ul>

                    </div>

                    <hr className="clearfix w-100 d-md-none" />

                    <div className="col-md-2 mx-auto">

                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Links</h5>

                        <ul className="list-unstyled">
                            <li>
                                <a href="#!">Link 1</a>
                            </li>
                            <li>
                                <a href="#!">Link 2</a>
                            </li>
                            <li>
                                <a href="#!">Link 3</a>
                            </li>
                            <li>
                                <a href="#!">Link 4</a>
                            </li>
                        </ul>

                    </div>

                    <hr className="clearfix w-100 d-md-none" />

                    <div className="col-md-2 mx-auto">

                        <Toggle
                            className="mr-3"
                            onClick={toggleTheme}
                            icons={{
                                checked: <FontAwesomeIcon icon={["fas", "moon"]} />,
                                unchecked: <Sun />,
                            }}
                            />

                    </div>

                </div>

            </div>

            <hr />

            <ul className="list-unstyled list-inline text-center py-2">
                <li className="list-inline-item">
                <h5 className="mb-1">Register for free</h5>
                </li>
                <li className="list-inline-item">
                <a href="#!" className="btn btn-danger btn-rounded">Sign up!</a>
                </li>
            </ul>

            <hr />

            <ul className="list-unstyled list-inline text-center">
                <li className="list-inline-item">
                    <a className="btn-floating btn-fb mx-1">
                        <i className="fab fa-facebook-f"> </i>
                    </a>
                </li>
                <li className="list-inline-item">
                    <a className="btn-floating btn-tw mx-1">
                        <i className="fab fa-twitter"> </i>
                    </a>
                </li>
                <li className="list-inline-item">
                    <a className="btn-floating btn-gplus mx-1">
                        <i className="fab fa-google-plus-g"> </i>
                    </a>
                </li>
                <li className="list-inline-item">
                    <a className="btn-floating btn-li mx-1">
                        <i className="fab fa-linkedin-in"> </i>
                    </a>
                </li>
                <li className="list-inline-item">
                    <a className="btn-floating btn-dribbble mx-1">
                        <i className="fab fa-dribbble"> </i>
                    </a>
                </li>
            </ul>
            
            <div className="footer-copyright text-center py-3">
                © 2021 &bull; dostrik
            </div>

        </SiteFooter>
    );
};