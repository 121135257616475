import { Action, DashboardPanelSettings, DashboardPanelState, DashboardState, SelectedPostType } from '../types';
//import {  } from '../types/props';

export const ActionTypes = {
    ADD_TO_DASHBOARD: "ADD_TO_DASHBOARD",
    SET_DASHBOARD: "SET_DASHBOARD",
    SET_SELECTED: "SET_SELECTED",
    SET_PANEL_INFO: "SET_PANEL_INFO",
    SET_CHART_DATA: "SET_CHART_DATA",
    SET_DASHBOARD_STATUS: "SET_DASHBOARD_STATUS",
    SET_PANEL_STATUS: "SET_PANEL_STATUS",
    GET_COLOR_PALETTE: "GET_COLOR_PALETTE",
    SET_COLOR_PALETTE: "SET_COLOR_PALETTE",

};

/* For reference
export const login = ( user: TUserLoginProp ) => ({
    type: ActionTypes.LOGIN_ATTEMPT,
    payload: user
}); */

const addToDashboard = ( id: string, size: any ): Action => { // check type
    console.log( 'addToDashboard' );
    return ({
        type: ActionTypes.ADD_TO_DASHBOARD,
        payload: {
            id: id,
            size: size
        }
    })
};

const setDashboard = ( data: { [key: string]: string } | DashboardState ): Action => ({
    type: ActionTypes.SET_DASHBOARD,
    payload: data
});

const setSelectedType = ( type: SelectedPostType ): Action => ({
    type: ActionTypes.SET_SELECTED,
    payload: type
});

const setPanelInfo = ( data: { [key: string]: string | DashboardPanelSettings } ): Action => ({
    type: ActionTypes.SET_PANEL_INFO,
    payload: data
});

const setChartData = ( updatedState: DashboardPanelState ): Action => ({
    type: ActionTypes.SET_CHART_DATA,
    payload: updatedState
});

const setDashboardStatus = ( status: boolean ): Action => ({
    type: ActionTypes.SET_DASHBOARD_STATUS,
    payload: status
});

const setPanelStatus = ( status: boolean ): Action => ({
    type: ActionTypes.SET_PANEL_STATUS,
    payload: status
});


export const Actions = {
    addToDashboard: addToDashboard,
    setDashboard: setDashboard,
    setSelectedType: setSelectedType,
    setPanelInfo: setPanelInfo,
    setChartData: setChartData,
    setDashboardStatus: setDashboardStatus,
    setPanelStatus: setPanelStatus,
    
};