import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Logout } from 'components/auth/Logout';
import { Helmet } from 'react-helmet-async';
import { Actions } from 'store/app/actions/app';
import { Stats } from '../temp/Admin/Stats';
import { Border } from 'styles';

export function Profile(): JSX.Element {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch( Actions.changePage( 'Profile' ) );
    }, []);

    return (
        <React.Fragment>

            <Helmet title="Profile" />

            <Border padding="1rem">

                <Logout type="BUTTON" />

            </Border>

        </React.Fragment>
    );
}