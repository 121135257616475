import React, { useState } from 'react';
import { DashboardImage as StyledDashboardImage, HoverButton} from 'pages/Post/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardImageModal from '../modal/image';
import { DashboardBackground } from 'store/app/types';

type DashboardImageProps = {
    isEditing: boolean;
    image: string;
    background: DashboardBackground;
};

export function DashboardImage( props: DashboardImageProps ): JSX.Element {
    
    const { isEditing, image, background } = props;
    const [ isHovering, setHovering ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const hasImage = image !== "";

    const onHide = ( save: boolean ): false | void => {
        if( save ) {
            return setShowModal( false );
        }
        
        if ( confirm('Discard changes?') ) {
            return setShowModal( false );
        } else {
            return false;
        }
    }

    const handleHover = ( status: boolean ) => isEditing ? setHovering(status) : null;

    if( !isEditing && !hasImage ) {
        return <></>;
    }
    
    return (
        <React.Fragment>
            {
                showModal ?
                    <DashboardImageModal show={true} hide={onHide} /> : null
            }
            
            <StyledDashboardImage 
                background={background}
                isEditing={isEditing}
                isHovering={isHovering}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                >
                {
                    hasImage ?
                        <img src={image} /> : null
                }
                {
                    isHovering && isEditing ?
                        <Hover hasImage={hasImage} onClick={() => setShowModal(true)} /> : null
                }
            </StyledDashboardImage>
        </React.Fragment>
    );
}

type HoverProps = {
    hasImage: boolean;
    onClick?: () => void;
}

const Hover = ( props: HoverProps ) => {
    const { hasImage, onClick } = props;
    const opts = {
        ...onClick && { onClick: onClick }
    }
    const icon = hasImage ? "edit" : "plus";

    return (
        <HoverButton {...opts}>
            <FontAwesomeIcon icon={[ "far", icon ]} />
        </HoverButton>
    );
}