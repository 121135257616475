import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Watchlist } from 'components/display/Watchlist';
import { SidebarProps } from '../../../store/app/types/props';
import { Loading } from 'components/display/Loading';
import { WatchlistState } from 'store/app/types/state';
import { SIDEBAR } from 'components/utils/const';
import { Border } from 'styles';

export function Sidebar(props: SidebarProps): JSX.Element {
    //const height = props.height;
    //const order = props.order ? props.order : true;
    const isLoggedIn = props.isLoggedIn;
    const { isLoaded, isFetching, error } = useSelector(
        (state: { watchlist: WatchlistState }) => state.watchlist.watchlists
    );
    const hasError = error.status === true;

    if( hasError ) {
        return (
            <Border className="sidebar mb-3">
                <span>An error occured</span>
            </Border>
        );
    }

    if (isLoggedIn && (!isLoaded || isFetching)) 
        return (
            <Border className="sidebar mb-3">
                <Loading className="pt-3 pb-3" />
            </Border>
        );

    return (
        <React.Fragment>
            <Watchlist component={SIDEBAR} />
        </React.Fragment>
    );
}
