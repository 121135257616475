import { produce } from 'immer';
import { AuthState } from '../types/state';
import { ActionTypes } from '../actions/auth';
import { Action } from '../types';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<AuthState>({
    forgotMsg: '',
    error: '',
    timestamp: '',
    loading: false,
    authenticated: false
});

export default (state: AuthState = initialState, action: Action): AuthState =>
    produce(state, (draft) => {
        switch( action.type ) {
            case ActionTypes.CLEARDOWN:
                draft = { ...initialState };
                return draft;

            case ActionTypes.AUTH_USER:
                draft = { ...initialState, authenticated: true };
                return draft;

            case ActionTypes.AUTH_IN_PROGRESS:
                draft = { ...initialState, loading: true };
                return draft;

            case ActionTypes.UNAUTH_USER:
                draft = initialState;
                return draft;

            /*
            case ActionTypes.FORGOT_SUCCESS:
                draft.forgotMsg = action.message;
                return draft;
            */

            case ActionTypes.AUTH_ERROR:
               draft = {
                   ...draft,
                    error: action.error, 
                    timestamp: action.timestamp 
                };
                return draft;

            default:
                return draft;
        }
    });
