import { Table } from "react-bootstrap";
import { DashboardBackground } from "store/app/types";
import styled from "styled-components";
import { DostrikForm } from "styles";

export const PlaceholderBox = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    h3 {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
`;

export const HoverButton = styled.button`
    color: ${ props => props.theme.text };
    font-family: ${ props => props.theme.fonts.varela };
    background: ${ props => props.theme.pixelPopLight };
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.75rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: -25px;
    margin-left: -25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: ${ props => props.theme.body };
    }
`;

export const PanelType = styled.button<{selected: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    margin-right: 1rem;
    border-radius: 1rem;
    color: ${ props => props.theme.text };
    opacity: ${ props => props.selected ? '1' : '0.7' };
    color:  ${ props => props.selected ? "#FF9900" : props.theme.text };
    border: 2px solid ${ props => props.selected ? "#FF9900" : props.theme.text };

    &:hover {
        opacity: 1;
    }
`;



export const PixelPop = styled.div<{color: "light" | "dark" }>`
    border-top: 1px solid ${ props => props.color === "light" ? props.theme.pixelPopLight : props.theme.pixelPopDark };
`;


export const Dashboard = styled.div`
    position: relative;
    background: ${ props => props.theme.body };
    z-index: 98;
    box-shadow: 0px 1px 0px ${ props => props.theme.pixelPopDark };
    border: 1px solid ${ props => props.theme.border };
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: .25rem;

    .grid-stack {

        .grid-stack-item {

            .grid-stack-item-content {
                overflow: visible;
                border: 1px dotted rgba( 0,0,0, 0.5 );
            }

            &.ui-draggable {

                .grid-stack-item-content {
                    border: 2px dashed;
                    border-radius: 5px;
                    cursor: grab;
                    -moz-cursor: grab;
                }

            }

            &.ui-draggable:active,
            &.ui-draggable-dragging {

                .grid-stack-item-content {
                    cursor: grabbing !important;
                    -moz-cursor: grabbing !important;
                }

            }

            > .ui-resizable-se {
                right: 15px !important;
                bottom: 15px !important;
            }
        }

    }

    .apexcharts-canvas svg {
        /*margin-left: -10px;*/
    }
`;

export const DashboardHeader = styled.div`
    display: flex;
    align-items: stretch;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid ${ props => props.theme.pixelPopDark };

`;

        
export const DashboardSettings = styled.div`
    display: flex;
    border-top: 1px solid ${ props => props.theme.pixelPopLight };
    border-bottom: 1px solid  ${ props => props.theme.pixelPopDark };
    padding: 1rem 0;
`;

export const DashboardContent = styled.div<{isEditing: boolean}>`
    padding-top: 1rem;
    margin: 0 auto;
`;

export const PreviewImage = styled.div<{ background: DashboardBackground }>`
    padding: 0.75rem;
    border-radius: 3px;
    box-sizing: content-box;
    align-items: center;
    background-color: ${ props => {
        switch( props.background ) {
            case "transparent":
                return "none";
            case "dark":
                return props.theme.darker;
            case "light":
                return props.theme.lightest;
        }
    }};

    img {
        max-width: 100%;
        max-height: 150px;
    }
`;

export const DashboardImage = styled.div<{background: DashboardBackground, isEditing: boolean, isHovering: boolean}>`
    display: flex;
    position: relative;
    padding: 1rem;
    border-radius: 3px;
    box-sizing: content-box;
    align-items: center;
    max-width: 150px;
    background-color: ${ props => {
        switch( props.background ) {
            case "transparent":
                return "none";
            case "dark":
                return props.theme.darker;
            case "light":
                return props.theme.lightest;
        }
    }};
    margin-right: ${ props => props.isEditing ? `1.5rem` : `2rem` };
    border: ${ props => props.isEditing ? `2px dashed ${props.theme.pixelPopLight}` : `none` };
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        transition: opacity .5s;
        background: rgb( 0,0,0 );
        opacity: 0;
        ${ props => props.isHovering ? `
                opacity: 0.7;
            ` : null
        }
    }
    

    img {
        max-width: 100%;
        max-height: 150px;
    }

`;

export const DashboardTitle = styled.div`
    display: flex;
    align-items: baseline;
    margin: 0.5rem 0;

    a {
        font-weight: bold;
    }

    span {
        font-size: 0.8rem;
    }

    h2 {
        font-size: 1.75rem;
        margin: 0 1rem 0 0;
    }

`;
export const DashboardDesc = styled.div`
    font-size: 1rem;

    p {
        margin-bottom: 0;
    }
`;

    // Replace placeholder color & input styles with global variables.
    export const DashboardTitleEdit = styled.input`
        border: 0px;
        width: 100%;
        max-width: 500px;
        font-size: 1.75rem;
        line-height: 1.65rem;
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
        margin-top: 0.25rem;
        margin-bottom: 0;
        background: rgba(255,255,255, 0.05 );
        color: ${ props => props.theme.text };
        font-family: ${ props => props.theme.fonts.varela };
        border-bottom: 1px solid ${ props => props.theme.pixelPopLight };
        
        &&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #697580;
            opacity: 1; /* Firefox */
        }
    `;

    export const DashboardDescEdit = styled.textarea`
        border: 0px;
        width: 100%;
        padding: 0.25rem 0.5rem 0.5rem 0.5rem;
        margin-top: 0.5rem;
        background: rgba(255,255,255, 0.05 );
        color: ${ props => props.theme.text };
        border-bottom: 1px solid ${ props => props.theme.pixelPopLight };
        min-height: 75px;

        &&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #697580;
            opacity: 1; /* Firefox */
        }

    `;


export const PanelForm = styled(DostrikForm)`
    padding-right: 1rem;
    max-height: 500px;

    h3 {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 1rem;
        font-family: ${ props => props.theme.fonts.varela };
    }

    input[type="text"],
    input[type="password"] {
        padding: 0.5em;
        height: calc(1.2em + .75rem + 5px);
        /*background: rgba( 255,255,255, 0.05 );*/
        background: transparent;
        color: ${ props => props.theme.text };
        border-color: transparent;
        border-bottom-color: ${ props => props.theme.darker };
    }

`;

export const PanelDataTable = styled(Table)`

    background: ${ props => props.theme.bgModal };

    tr, td, th, thead, tbody {
        border-color: ${ props => props.theme.border } !important;
    }

    th {
        font-weight: 600;
        color: ${ props => props.theme.text };
        font-family: ${ props => props.theme.fonts.openSans };
    }

    td.series-name input {
        width: 100%;
    }

    td.series-color {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const SeriesColor = styled.button<{ color: string }>`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${ props => props.color } !important;
`;



export const ViewPanel = styled.div<{ hasBackground: boolean }>`
    background: ${ props => props.hasBackground ? 'rgba( 255,255,255, 0.05 )' : 'none' };
    height: 100%;
`;

export const ViewPanelOptions = styled.div<{ show: boolean }>`
    width: 100%;
    height: 100%;
    z-index: 99;
    position: absolute;
    background: rgba( 7,13,16, 0.85 );
    padding-right: 0.5rem;
    border-radius: 2px;
    display: ${ props => props.show ? 'flex' : 'none' };
    justify-content: flex-end;
    align-items: baseline;
`;

export const ViewPanelButton = styled.button`
    color: ${ props => props.theme.text };
    font-size: 2rem;
    padding: 0.5rem 0.75rem 0.35rem 1rem;

    &:hover {
        background: ${ props => props.theme.body };
    }
`;

export const ViewPanelTitle = styled.h3<{ hasBackground: boolean }>`
    background: ${ props => props.hasBackground ? 'rgba( 0,0,0, 0.5 )' : 'none' };
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    
`;

export const ViewPanelContent = styled.div<{ hasTitle: boolean }>`
    padding: 0 0.75rem 0 0.75rem;
`;