import React from "react";
import { NEWS_RELOAD_DELAY } from "components/utils/const";


type ProgressProps = {
  values: number[];
  handleReload: () => void;
  children?: React.ReactNode;
};

type ProgressState = {
  valuesIndex: number;
};

class ChangingProgressProvider extends React.Component<ProgressProps, ProgressState> {
  /*
  static defaultProps = {
    interval: 1000
  };
  */

  state: ProgressState = {
    valuesIndex: 100
  };

  componentDidMount() {
      this.setState({
        valuesIndex: (this.state.valuesIndex - 1) % this.props.values.length
      });

      setTimeout( this.props.handleReload, (NEWS_RELOAD_DELAY*1000)); // use global const for time
  }

  render() {
      // check type
      return this.props.children( this.props.values[this.state.valuesIndex] );
  }
}

export default ChangingProgressProvider;
