//import * as React from 'react';
import React, { useEffect, useState, useRef, MouseEventHandler } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//import { Link } from 'react-router-dom';
//import { DateTime } from 'luxon';
import loadable from '@loadable/component';
import { Container, Row, Col } from 'react-bootstrap';
//import 'simplebar';

import { RootState } from 'store';

import { 
    checkCompanyDataState, checkIntradayState, checkHistoricalState, 
    checkWatchingState, checkLivePrice, checkUserState,
} from '../../store/app/selectors';

import { Actions } from '../../store/app/actions';

import { log, formatDecimals, getDisplayByTicker } from '../../helpers';

import { 
    TypeButtonContainer, TypeButton, Options, TagCloudList, Border, OverviewChartContainer, OverviewSidebar, DefaultButton, Flex, 
} from '../../styles';

import { SymbolPageProps } from '../../store/app/types/props';
import { StockNewsList } from 'components/display/News';
import { Loading, LoadingCandles, LoadingNews, LoadingTickerHeader, LoadingTickerNews, TickerHeaderLoader } from 'components/display/Loading';
import Calendar from 'components/display/Calendar';
import TickerHeader from 'components/display/TickerHeader';
import { OverviewTable } from './components/Tables';
import { OverviewTabs } from './components/Tabs';
import { DayRange, WeekRange } from './components/Range';
import { AppState, StocksState, WatchlistState } from 'store/app/types/state';
import { Helmet } from 'react-helmet-async';
import { Similar } from 'components/display/Similar';
import { BAR, CANDLE, COMPANY_NEWS, DEFAULT, FIVE_DAYS, FIVE_YEARS, ONE_DAY, ONE_MONTH, ONE_YEAR, SYMBOL } from 'components/utils/const';
//import { OverviewChart } from 'components/charts/OverviewChart';
const OverviewChart = loadable(() => import('components/charts/OverviewChart'));

/*

import { DayRange, WeekRange } from '../display/Range';
import TickerHeader from '../layout/TickerHeader';
import OverviewTabs from '../layout/OverviewTabs';
import { SimilarCompanies, SimilarCompanies2 } from '../utils/SimilarCompanies';
import NewsList from '../utils/NewsBlock';
import Calendar from '../utils/Calendar';
import { OverviewTable } from '../utils/Table';

// temp
import Table from '../utils/Table';
import { SmallSidebar } from '../layout/Sidebars';
import { ad } from 'technical-indicator';
import { tr } from 'technical-indicator';

*/

// Currently Symbol page reloads when dispatching, making it so  range/type always reset. Check why

export function Symbol( props: SymbolPageProps ): JSX.Element {    

    const dispatch = useDispatch();

    const ticker = props.match.params.symbol;
    const isLoaded = useRef<boolean | undefined>();

    console.log( 'Symbol page load '+ticker ); 

    const [ range, setRange ] = useState<string>( '1D' ); // 1D, 5D, 1M, 1Y, 5Y
    const [ type, setType ] = useState<string>('DEFAULT'); // DEFAULT, CANDLE, BAR

    const currentTicker = useSelector( (state: { app: AppState }) => state.app.current.symbol );
    const similarState = useSelector( (state: { stocks: StocksState }) => state.stocks.tags );
    const companyDataState = useSelector( (state: { stocks: StocksState }) => checkCompanyDataState( state.stocks, ticker ) );
    const intradayDataState = useSelector( (state: { stocks: StocksState }) => checkIntradayState( state.stocks, ticker ) );
    const historicalDataState = useSelector( (state: { stocks: StocksState }) => checkHistoricalState( state.stocks, { ticker: ticker, range: range} ), shallowEqual );

    const isWatching = useSelector( (state: RootState) => checkWatchingState( state, { ticker: ticker} ));
    const isFetching = useSelector( (state: RootState) => state.stocks.isFetching );
    const isTrading = useSelector( (state: RootState) => state.stocks.isTrading );

    const livePrice = useSelector( (state: { watchlist: WatchlistState }) => checkLivePrice( state.watchlist, { ticker: ticker } ) );
    const checkUser = useSelector( checkUserState );

    const intradayLoaded = intradayDataState.length !== 0 && !isFetching;
    const companyLoaded = companyDataState.length !== 0;
    const historicalDataLoaded = historicalDataState.length !== 0;
    const chartDataLoaded = range === '1D' ? intradayLoaded : historicalDataLoaded;
    const companyNewsLoaded = companyLoaded ? companyDataState[0].news.isLoaded : false;
    const calendarDataLoaded = companyLoaded ? companyDataState[0].calendar.isLoaded : false;

    // check live price reducer, sometimes null data;
    console.log( '## Remember ## Check live price reducer && ActionTypes.TICKER_UPDATE errors' );

    // live price is a string, validate for type, supposed to be number... 
    // currentPrice can't be a string in order to be sent to formatDecimals()
    const currentPrice = (livePrice && livePrice.price !== null) ? livePrice.price : ( intradayLoaded ? intradayDataState[0].currentPrice : null );
    const displayPrice = formatDecimals(currentPrice);

    const dayChange = livePrice ? formatDecimals(livePrice.changePercent) : ( intradayLoaded ? formatDecimals(intradayDataState[0].dayChange) : null );
    const previousClosePrice = intradayLoaded ? formatDecimals( intradayDataState[0].previousClosePrice ) : null;

    const dataState = range === '1D' ? intradayDataState : historicalDataState;

    const applyRange: MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const button = event.target as HTMLButtonElement;
        const newRange = button.id;
        if( range !== newRange ) {
            setRange( newRange );
        }
    }

    const applyType: MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const button = event.target as HTMLButtonElement;
        const newType = button.id;
        if( type !== newType ) {
            setType( newType );
        }
    };

    useEffect(() => {
        console.log( 'useEffect 1' ); 
        // check if current page === stocks, otherwise infinite loop
        dispatch( Actions.changePage( 'Stocks', ticker ) );
        return () => {
            isLoaded.current = true;
            if( isLoaded.current ) {
                // combine?
                if( range !== '1D' ) {
                    console.log( 'setRange' );
                    setRange( '1D' ); // reset
                }
                if( type !== 'DEFAULT' ) {
                    console.log( 'setType' );
                    setType( 'DEFAULT' ); // reset
                }
            }
        }
    }, [ticker]);

    useEffect( () => {
        console.log( 'useEffect 2' ); 
        //if( companyDataState.length === 0 ) {
        if( !intradayLoaded && !isFetching ) {
            console.log( 'getOverviewData' );
            dispatch( Actions.getOverviewData( ticker ) ) ;
        }
        //if( type !== '1D' && historicalData.length === 0 ) {
        if( range !== '1D' ) {
            console.log( 'getRangeData' );
            dispatch( Actions.getRangeData( ticker, range ) );
        }
        //}
        /*
        if( intradayData.length === 0 ) {
            dispatch( getIntradayData( ticker ) );
        }
        */

    }, [ticker, range, type]);

    const _Overview = {
        'ticker': ticker,
        'isLoaded': companyLoaded,
        'newsIsLoaded': companyNewsLoaded,
        'chartIsLoaded': chartDataLoaded,
        'calendarIsLoaded': calendarDataLoaded,
        'isLoggedIn': checkUser.isLoggedIn,
        'isWatching': isWatching,
        'isTrading': isTrading,
        'currentPrice': currentPrice,
        'displayPrice': displayPrice,
        'dayChange': dayChange,
        'previousClosePrice': previousClosePrice,
        'companyData': companyDataState,
        'chartData': {
            'data': dataState,
            'intradayData': intradayDataState,
            'range': range,
            'type': type,
            'applyRange': applyRange,
            'applyType': applyType
        }
    }


    const newsIsLoaded = _Overview.newsIsLoaded;
    const chartIsLoaded = _Overview.chartIsLoaded;
    const calendarIsLoaded = _Overview.calendarIsLoaded;
    const calendarIsLoading = _Overview.calendarIsLoaded;

    const isLoggedIn = checkUser.isLoggedIn;
    const companyData = companyDataState[0];
    const hasStats = _Overview.isLoaded ? companyData.stats : null;

    const chartData = _Overview.chartData;
    const intradayData = intradayDataState[0];
    const currency = intradayLoaded ? intradayData.currency : '';
    const data = dataState[0];

    const newsList = newsIsLoaded ? companyData.news.items : [];
    const newsCopyright = newsIsLoaded ? companyData.news.copyright : '';

    const calendarList = calendarIsLoaded ? companyData.calendar.list : [];

    // update high if intraday high is higher than 52wk
    const wk52Low = hasStats ? ( intradayData.dayLow < companyData.stats.week52low ? intradayData.dayLow : companyData.stats.week52low ) : 0; 
    const wk52High = hasStats ? ( intradayData.dayHigh > companyData.stats.week52high ? intradayData.dayHigh : companyData.stats.week52high ) : 0; 
    const noData = intradayLoaded ? intradayData.data === 'No data' : false;

    const theme = props.theme;

    


    useEffect( () => {
        console.log( 'useEffect company tag/sector');
        let unescapedTag = "";

        if( companyLoaded ) {

            const companyInfo = companyDataState[0].info;

            if( companyInfo.issueType === "Index" ) {
                unescapedTag = "Index";
            } else if( companyInfo.industry ) {
                unescapedTag = companyInfo.industry.replace('-','/');
            }

            if( unescapedTag !== "" ) {
                dispatch( Actions.getSymbolsByTag( unescapedTag, true, "INDUSTRY" ) );
            }
        }
    }, [companyLoaded]);

    let similarTitle = "";
    let similarLoaded = false;

    if( companyLoaded && similarState.isLoaded ) {
        if( companyData.info ) {
            if( companyData.info.issueType === "Index" ) {
                similarTitle = "Indexes";
                similarLoaded = true;
            } else if( companyData.info.industry ) {
                similarTitle = companyData.info.industry;
                similarLoaded = true;
            }
        }
    }

    const BrowserTitle = `${getDisplayByTicker(currentTicker, (_Overview.isLoaded && companyData.info.name) ? companyData.info.name : null)} ${displayPrice ? ` - $${displayPrice} ${dayChange}%` : ``}`;

    return (
        <div className="overview">
            {
                <Helmet title={BrowserTitle} />
            }
            <Row className="mb-3">
                <Col lg="9" className="overviewContent">
                    <Row>
                        <Col>
                            <Border padding="0 0.5rem">
                                {
                                    !_Overview.isLoaded || !intradayLoaded ?
                                        <TickerHeaderLoader /> :
                                        <React.Fragment>

                                            <TickerHeader 
                                                companyId={companyData.info.id}
                                                ticker={companyData.ticker} 
                                                name={companyData.info.name}
                                                securityName={companyData.info.securityName} 
                                                currentPrice={currentPrice}
                                                displayPrice={displayPrice}
                                                dayChange={dayChange} 
                                                currency={currency} 
                                                previousClosePrice={previousClosePrice} 
                                                isWatching={isWatching}
                                                isTrading={isTrading}
                                                hasStats={hasStats}
                                                companyData={companyData}
                                                intradayData={intradayData}
                                                //noData={noData}
                                                wk52High={wk52High}
                                                />
                                        </React.Fragment>
                                }
                                <OverviewChartContainer>
                                    <Options>
                                        {
                                            // Add more range? 6 months & max. Const already exists
                                        }
                                        <TypeButtonContainer>
                                            <DefaultButton id={ONE_DAY} onClick={applyRange} selected={range === ONE_DAY}>1D</DefaultButton>
                                            <DefaultButton id={FIVE_DAYS} onClick={applyRange} selected={range === FIVE_DAYS}>5D</DefaultButton>
                                            <DefaultButton id={ONE_MONTH} onClick={applyRange} selected={range === ONE_MONTH}>1M</DefaultButton>
                                            <DefaultButton id={ONE_YEAR} onClick={applyRange} selected={range === ONE_YEAR}>1Y</DefaultButton>
                                            <DefaultButton id={FIVE_YEARS} onClick={applyRange} selected={range === FIVE_YEARS}>5Y</DefaultButton>
                                        </TypeButtonContainer>
                                        <TypeButtonContainer>
                                            <DefaultButton id={DEFAULT} onClick={applyType} selected={type === DEFAULT}>Default</DefaultButton>
                                            <DefaultButton id={CANDLE} onClick={applyType} selected={type === CANDLE}>Candles</DefaultButton>
                                            <DefaultButton id={BAR} onClick={applyType} selected={type === BAR}>OHLC Bars</DefaultButton>
                                        </TypeButtonContainer>
                                    </Options>
                                    {
                                        !_Overview.isLoaded || !chartIsLoaded ?
                                            <LoadingCandles /> :
                                            //<LoadingCandles /> :
                                                //!noData ?
                                                    <OverviewChart 
                                                        ticker={companyData.ticker} 
                                                        companyName={companyData.info.name} 
                                                        data={chartData} 
                                                        currentPrice={currentPrice} 
                                                        previousClosePrice={intradayData.previousClosePrice} 
                                                        isLoggedIn={isLoggedIn} 
                                                        isTrading={isTrading} 
                                                        range={range} 
                                                        applyRange={applyRange}
                                                        type={type}
                                                        applyType={applyType}
                                                        theme={theme} />
                                                    // : 'No data'
                                    }
                                </OverviewChartContainer>
                            </Border>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="9" className="overviewFirstCol">
                            <Border className="mt-3" padding="0.5rem">
                                <StockNewsList 
                                    max={6} 
                                    list={newsList} 
                                    isLoaded={newsIsLoaded} 
                                    data={chartData} 
                                    currentPrice={currentPrice} 
                                    display={SYMBOL}
                                    type={COMPANY_NEWS}
                                    title={`${ticker} News`}
                                    />                                    
                                <div className="newsCopyright">{ newsCopyright }</div>
                            </Border>
                        </Col>
                        <Col lg="3" className="overviewLastCol">
                            <Border className="mt-3" padding="0.5rem">
                                {
                                    /*
                                    // edit to add isLoading ?
                                    !calendarIsLoaded ?
                                        <Loading /> :
                                        //<LoadingTickerNews /> :
                                        calendarList.length !== 0 ? (
                                            <Calendar 
                                                list={calendarList} 
                                                isLoaded={calendarIsLoaded} 
                                                /> 
                                            ) : ( <span>No data</span> )

                                            */
                                }
                                {
                                    <Calendar 
                                        list={calendarList} 
                                        isLoaded={calendarIsLoaded} 
                                        /> 
                                }
                            </Border>
                        </Col>
                    </Row>
                    
                    <Row className="mt-3">
                        <Col>
                            {
                                !_Overview.isLoaded ?
                                    <Loading /> :
                                    (companyData.info.issueType !== "Index") ?
                                        <OverviewTabs ticker={ticker} currentPrice={currentPrice} /> : null
                            }
                        </Col>
                    </Row>
                </Col>
                <Col lg="3">
                    <OverviewSidebar>
                        {
                            !_Overview.isLoaded ?
                                <Loading /> :
                                <OverviewTable 
                                    currentPrice={currentPrice} 
                                    hasStats={hasStats} 
                                    companyData={companyData} 
                                    />
                        }

                        {
                            !_Overview.isLoaded ?
                                <Loading /> :
                                (
                                    !noData ?
                                            <div className="pl-2 pr-2 mt-4">
                                                <DayRange 
                                                    currentPrice={currentPrice} 
                                                    low={intradayData.dayLow} 
                                                    high={intradayData.dayHigh} 
                                                    />
                                            </div> : null
                                )
                        }
                            
                        {
                            !_Overview.isLoaded ?
                                <Loading /> :
                                (
                                    hasStats ? 
                                        companyData.stats.week52low ? (
                                                <div className="pl-2 pr-2 mt-4">
                                                    <WeekRange 
                                                        currentPrice={currentPrice} 
                                                        low={wk52Low} 
                                                        high={wk52High} 
                                                        />
                                                </div> 
                                        ) : null : null
                                )
                        }
                        
                        {
                            !similarLoaded ?
                                // Make loading svg
                                <Loading className="mt-4" /> :
                                    <div className="similar mt-2">
                                        <Similar 
                                            title={similarTitle}
                                            ticker={ticker}
                                            data={similarState.tickers} 
                                            />
                                    </div>
                        }
                    </OverviewSidebar>
                </Col>
            </Row>
        </div>
    );
}

/*

function TagCloud( props ) {
    const tags = props.data.info.tags ? ( props.data.info.tags.length !== 0 ? props.data.info.tags : null ) : null;
    return( 
        <div style={{ marginTop: '2rem' }}>
            {
                tags ?
                    <TagCloudList>
                        {
                            tags.map( tag => {
                                // make function to escape tag ( / );
                                return( <li key={tag}><Link to={'/tag/'+encodeURI( tag.replace('/','-') )}>{ tag }</Link></li> )
                            })
                        }
                    </TagCloudList> : 'No tags'
            }
        </div>
    )
}

function Dial(): JSX.Element {
    return(
        <div className="widgetDial" style={{ marginTop: '2rem' }}>
            <div className="pin level0">
                <div className="mask"></div>
            </div>
            <div className="colorWheel"></div>
        </div>
    )
}

*/