import { Dropdown, Modal, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SparkItemType } from 'store/app/types';
import { BorderProps, IconButtonProps, TitleProps, TypeButtonProps } from 'store/app/types/props';
import styled, { css } from 'styled-components';  

/* Global */
type FlexProps = {
    direction?: string; 
    align?: string; 
    justify?: string; 
    grow?: string; 
    overflow?: string
};

export const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${ props => props.direction ? props.direction : 'initial' };
    flex-grow: ${ props => props.grow ? props.grow : '0' };
    align-items: ${ props => props.align ? props.align : 'initial' };
    justify-content: ${ props => props.justify ? props.justify : 'initial' };
`;

export const DostrikForm = styled(Form)`
    background-clip: border-box;

    input[type="text"],
    input[type="password"] {
        max-width: 300px;
        border-radius: 2px;
        border: 1px solid ${ props => props.theme.lighter };
        padding: 0.5rem 1rem;
        height: calc(1.5em + .75rem + 6px);
    }

    textarea {
        flex-grow: 1;
        border-radius: 2px;
        border: 1px solid ${ props => props.theme.lighter };
        padding: 0.5rem 1rem;
    }

    &.watchlist-form select {
        width: 100%;
        border-radius: 0;
        border-radius: 2px;
        padding: 0.5rem 1rem;
        height: calc(1.5em + .75rem + 6px);
        border: 1px solid ${ props => props.theme.lighter };
    }
    
`;

export const DostrikButton = styled(Button)<{ color?: "blue" | "green" | "red" }>`
    border-radius: 2px;
    padding: 0.5rem 1rem;
    color: ${ props => props.theme.light };
    border: 1px solid ${ props => props.theme.dark };
    background: ${ props => {
        switch(props.color) {
            case "blue":
                return "#194d83";
            case "green":
                return "#198343";
            case "red":
                return "#831919";
            default:
                return props.theme.dark;
        }
    }};

    &:hover,
    &:active,
    &:focus {
        padding: 0.5em 1rem;
        border: 1px solid ${ props => props.theme.dark };
        background: ${ props => {
            switch(props.color) {
                case "blue":
                    return `#194d83 !important`;
                case "green":
                    return `#198343 !important`;
                case "red":
                    return `#831919 !important`;
                default:
                    return `${props.theme.darker} !important`;
            }
        }};
    }

    svg {
        font-size: 1rem !important;
        margin-right: 0.35rem;
    }

    &.no-bg {
        border: none;
        background-color: none;
        color: ${ props => props.theme.text };

        &:hover {
            text-decoration: underline;
        }
    }
`;

/* .btn-dostrik = same as DostrikButton
*/

/* Tests for Global / Default Styles */

    export const defaultButtonStyles = (newProps: { selected?: boolean; }) => css`
        font-size: 0.9rem;
        border-radius: 3px; 
        color: ${ props => newProps.selected ? props.theme.textActive : props.theme.text };
        background: ${ props => newProps.selected ? props.theme.pixelPopLight : 'transparent' };
        font-family: ${ props => props.theme.fonts.openSans };
        border: 1px solid ${ props => props.theme.pixelPopLight };
    `;

    export const DefaultButton = styled(Button)<{ selected?: boolean; fontSize?: string; padding?: string }>`
        ${ props => defaultButtonStyles({ selected: props.selected === true }) }

        margin: 0 0.25rem;
        padding: ${ props => props.padding ? props.padding : `0.3rem 0.5rem 0.25rem 0.5rem;` };

        ${ props => props.fontSize ? `font-size: ${props.fontSize};` : null }
            
        &:hover {
            background: ${ props => props.theme.darker };
            border-color: ${ props => props.theme.darker };
            color: ${ props => props.theme.textActive };
        }
    `;

    export const DefaultDropdown = styled(Dropdown)<{fontSize?: string; padding?: string; }>`

        .dropdown-toggle {
            ${ props => defaultButtonStyles({ selected: props.selected === true }) }
            color: ${ props => props.theme.text };
            font-size: ${props => props.fontSize ? props.fontSize : '1rem' };
            transition: none !important;
            -webkit-transition: none !important;
            
            &[aria-expanded="true"] {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                background: ${props => props.theme.header.dropdown.background };
                border-color:  ${props => props.theme.header.dropdown.background };
                color: ${ props => props.theme.textActive };
            }

            &:focus,
            &:active {
                outline: 0;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
            }
        }
        
        .dropdown-menu {
            border-top-left-radius: 0;
            transform: translate(0px, 30px) !important;
            background: ${props => props.theme.header.dropdown.background };
            border-color:  ${props => props.theme.header.dropdown.background };

            a {
                color: ${props => props.theme.text };
                font-size: ${props => props.fontSize ? props.fontSize : '1rem' };
                padding: ${props => props.padding ? props.padding : '0.5rem 1rem' };

                &.active {
                    background-color: ${props => props.theme.watchlist.dropdown.active };
                }

                &:hover {
                    background-color: ${props => props.theme.watchlist.dropdown.hover };
                }
            }
        }
        
        &.show {
            border-color: ${ props => props.theme.darker };
        }
    `;

export const StyledDropdown = styled(Dropdown)<{ size?: string, padding?: string }>`

    .btn-dostrik {
        font-family: ${props => props.theme.fonts.varela };
        color: ${props => props.theme.text };
        font-size: ${props => props.size ? props.size : '1rem' };
        padding: ${props => props.padding ? props.padding : '0.5rem 1rem' };
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &[aria-expanded="true"] {
            background: ${props => props.theme.watchlist.dropdown.background };
            color: ${props => props.theme.text };
        }

        &:focus {
            outline: 0 !important;
            box-shadow: 0px 0px 0px transparent !important;
        }
        
        &::after {
            margin-left: .455em;
            vertical-align: 0.195em;
            color: $light;
        }
    }

    .dropdown-divider {
        border-top-color: ${ props => props.theme.pixelPopLight };
    }

    .dropdown-menu {
        background: ${props => props.theme.watchlist.dropdown.background };
        transform: translate(0px, 24px) !important;

        a {
            color: ${props => props.theme.text };
            font-size: ${props => props.size ? props.size : '1rem' };
            padding: ${props => props.padding ? props.padding : '0.5rem 1rem' };

            &.active {
                background-color: ${props => props.theme.watchlist.dropdown.active };
            }

            &:hover {
                background-color: ${props => props.theme.watchlist.dropdown.hover };
            }
        }
    }
`;

export const DostrikHeaderButton = styled.button<{ size?: string, padding?: string }>`
    font-family: ${props => props.theme.fonts.varela };
    color: ${props => props.theme.text };
    font-size: ${props => props.size ? props.size : '0.8rem' };
    padding: ${props => props.padding ? props.padding : '3px 6px' };
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &[aria-expanded="true"] {
        background: ${props => props.theme.pixelPopDark };
        color: ${props => props.theme.text };
    }

    &:focus {
        outline: 0;
        box-shadow: 0px 0px 0px transparent;
    }
`;

export const DostrikModal = styled(Modal)`

    padding-top: 5rem;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .loginBox {
        border-right: 1px solid $pixel-pop-dark;
    }

    .registerBox {
        border-left: 1px solid $pixel-pop-light;
    }

    .modal-content {
        background: ${ props => props.theme.bgModal };

        .modal-body {
            border-top: 1px solid $modal-dark-bg;
            padding-left: 0;
            padding-right: 0;
        }
        
        .close {
            color: $light;
            opacity: 1;
            text-shadow: 0 1px 0 $lighter;
        }
    }
`;

export const TickerDisplay = styled.div<{ showStatusIcon?: boolean }>`
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-align: left;
    font-size: 0.9rem;
    padding: 0 0.5rem 0 0.25rem;

    ${ props => (
        props.showStatusIcon ? `flex-grow: 0 !important` : ``
    )}
`;

// Responsive

export const ShowSmall = styled.div``;
export const HideSmall = styled.div``;

export const ShowMedium = styled.div`
    @media screen and (max-width: 767px) {
        display: none;
    }

    @media screen and (min-width: 768px) {
        display: block;
    }
`;

export const HideMedium = styled.div`
    @media screen and (max-width: 767px) {
        display: block;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const ShowLarge = styled.div``;
export const HideLarge = styled.div``;

/* // */

/* Header */

    /* Search */

    export const SearchResults = styled.div<{component?: string, visible: boolean, staticUrl: string}>`
        background: url(${props => props.staticUrl}public/images/loading.gif) ${props => props.theme.darker} center center no-repeat;
        background-size: 40px 40px;
        position: absolute;
        top: ${props => props.component === 'MODAL' ? 'initial' : '100%' };
        left: 0px;
        width: 100%;
        z-index: 89; 
        color: #f8f8f8;
        opacity: ${props => props.visible ? `1` : `0` };
        min-height: ${props => props.visible ? `57px` : `0px` };
        max-height: 250px;
        ${ props => {
            if( !props.visible ) {
                return (
                    `height: 0px !important;`
                )
            }
        }}

        ul {
            margin: 0;
            padding: 0;

            li {

                button {
                    position: relative;
                    padding-right: 3rem;
                    width: 100%;
                    text-align: left;

                    .svg-inline--fa {
                        position: absolute;
                        right: 1rem;
                        top: 1rem;
                    }
                }

                a,
                button {
                    display: block;
                    padding: 10px;
                    color: #f8f8f8;
                    background: ${props => props.theme.darker};
                    font-family: ${props => props.theme.fonts.varela};

                    &:hover,
                    &:focus {
                        outline: 0;
                        text-decoration: none;
                        background: ${props => props.theme.pixelPopLight};
                    }

                    span {
                        display: block;
                        font-size: 0.8rem;
                        font-weight: normal;
                    }

                    span:first-child {
                        font-family: $varela;
                        font-size: 1rem;
                        font-weight: bold;
                    }
                }
            }
        }
    `;

    export const EmptyResult = styled.div`
        background: ${props => props.theme.darker};
        font-family: ${props => props.theme.fonts.varela};
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        padding: 16px 5px;
        color: #f8f8f8;
    `;


    /* // */

/* // Header */

/* Watchlist */

export const EditWatchlistButton = styled.button`
    border: 1px solid transparent;
    margin-left: auto;
    background: transparent;
    color: ${ props => props.theme.text };
    position: relative;
    z-index: 9;
    height: 26px;
`;

export const TickerListHeader = styled.div`
    display: flex;
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    position: relative;
    z-index: 9;
    justify-content: space-between;

    &.selected {
        background: #204B64;
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: transparent;
        box-shadow: 0 0 3px ${ props => props.theme.darker };
    }

    h2 {
        font-size: 1.2rem;
        padding: 0.6rem;
        margin-bottom: 0;
        line-height: 1rem;
    }

    .dropdown-toggle::after {
        margin-left: 0.45em;
        vertical-align: 0.2em;
    }
`;

export const WatchlistDropdown = styled(DefaultDropdown)`
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    padding: 0.4rem;

    .dropdown-menu {
        transform: translate(-1px, 45px) !important;
    }

    .dropdown-toggle {
        padding: 0.65rem;
        font-size: 1.2rem;
        line-height: 1rem;
        border-color: transparent;
        font-family: ${ props => props.theme.fonts.varela };
    }
   
    &.show {
        background: ${props => props.theme.header.dropdown.background };
        border-color: ${props => props.theme.header.dropdown.background };
        color: ${ props => props.theme.text };
    }
`;

export const SortWatchlistDropdown = styled(DefaultDropdown)<{width?: number}>`

    .dropdown-menu {
        width: 150px;
        transform: translate(-118px, 32px) !important;
    }

    .dropdown-toggle {
        font-size: 1rem;

        &:after {
            display: none;
        }
    }
   
    &.show {
    }
`;

export const Notice = styled.button`
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 99;
    border: 1px solid transparent;
    background: transparent;
    color: ${ props => props.theme.text };;
    padding: 0.75rem 1rem 0.75rem 3rem;
    width: 100%;
    margin-left: -4px;
    font-weight: 600;
    font-size: 0.85rem;
    text-align: center;
    border-radius: .25rem;
    display: block;

    .fa-sign-in-alt {
        font-size: 1.4rem;
        position: absolute;
        left: 1rem;
        top: 0.65rem;
    }

    &:hover {
        text-decoration: underline;
    }
`;

/* // Watchlist */

/* Item w/ Spark Chart */

export const LoggedOutOverlay = styled.div`
    position: relative;
    filter: blur(3px);
    opacity: 0.4;
`;


export const SparkItem = styled.div<SparkItemType>`
    display: flex;
    align-items: center;
    font-family: ${ props => props.theme.fonts.varela};
    
    ${ (props) => {
        if( props.border ) {
            return (
                `border-radius: .25rem;
                box-shadow: ${ props.theme.shadow.small };  
                border: 1px solid ${ props.theme.border };
                padding: ${ props.padding ? props.padding : '0' };`
            )
        } else {
            return (
                `border-top: 1px solid ${props.theme.pixelPopLight};
                border-bottom: 1px solid ${props.theme.pixelPopDark};
                padding: 0.5rem 0.35rem 0.5rem 0;

                :first-child {
                    border-top: none;
                }

                :last-child {
                    border-bottom: none;
                }`
            )
        }
    }}

    & > * {
        flex: 1 1 0;
    }

    &.is-dragging {
        border-color: transparent;
    }

    .valueDisplay {
        display: flex;
        flex-direction: column;
        text-align: right;
        font-size:${ (props: any) => props.size === 'small' ? '0.8rem' : '0.8rem' };
        padding-right: ${ (props: any) => props.size === 'small' || props.border ? '0' : '0.5rem' };
    }


`;

    /* Handle for watchlist ordering */

    export const Handle = styled.div`
        width: 30px;
        height: 30px;
        font-size: 1.5rem;
        margin: 0 0.25rem 0 0.5rem;
        color: ${ props => props.theme.handle };
        flex-grow: 0 !important;
    `;

    /* // Handle */

/* // Item */

/* News List */

export const NewsItem = styled.li`
    .media-body {
        box-shadow: 0px 1px 0px ${ props => props.theme.pixelPopLight }; 
        border-bottom: 1px solid ${ props => props.theme.pixelPopDark }; 
        margin-right: 12px;
    }
`;

/* // */

/* Overview */

export const OverviewSidebar = styled.div`
    background: ${ props => props.theme.body };
`;

export const Table = styled.table`
    color: ${ props => props.theme.text };
`;

export const OverviewChartContainer = styled.div`
    position: relative;
    margin: 0 0 0.5rem 0;
    min-height: 265px;
    border-top-width: 0px;
`;

// check if still used
export const NewsMeta = styled.span`
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    padding: 2px 0
`;

export const NewsPrice = styled.span<{status: string}>`
    padding: 2px 4px 1px 4px;
    background: ${ props => props.status === 'bullish' ? props.theme.bullish : props.theme.bearish };
    border-radius: 4px;
    font-size: 0.8rem;
    color: #FFFFFF;
    margin-right: 0.5rem;
`;

export const Title3 = styled.h3<TitleProps>`
    font-size: 1.1rem;
    font-weight: 'Varela Round', sans-serif;
    border-radius: .25rem;
    ${ props => props.border ? 'box-shadow: 0px 1px 0px '+props.theme.pixelPopLight+'; border-bottom: 1px solid '+props.theme.pixelPopDark+'; padding: 0 0 0.5rem 0.75rem;' : null }
`;

export const Border = styled.div<BorderProps>`
    border-radius: .25rem;
    box-shadow: 0px 1px 0px ${ props => props.theme.pixelPopDark };
    border: 1px solid ${ props => props.theme.border };
    padding: ${ props => props.padding ? props.padding : '0' };
    display: ${ props => props.display ? props.display : 'inherit' };
    background: ${ props => props.theme.body };

`;

export const BorderTitle = styled(Flex)`
    padding: 0.25rem;
    padding-left: 0.5rem;
    box-shadow: 0 7px 8px ${ props => props.theme.body };

    h2, h3 {
        margin: 0;
    }

    .dropdown {
        margin-top: -3px;
    }
`;

export const FeaturedList = styled.div`
    min-height: 54px;
    position: relative;
    margin-bottom: 1rem;

    .bullish {
        color: ${ props => props.theme.bullish };
    }

    .bearish {
        color: ${ props => props.theme.bearish };
    }
`;

export const FeaturedOptions = styled.div`
    position: absolute;
    top: 10px;
    right: -70px;
    z-index: 99;
`;

export const FeaturedItem = styled.div`
    
    font-family: ${ props => props.theme.fonts.varela };
    display: inline-block;

    .sparklineContainer {
        position: relative;
        border-radius: 3px;
        margin-top: 0.25rem;
    }

    .featured-status-icon {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 1199px) {
        .sparklineContainer {
            display: none;
        }
    }

    @media screen and (min-width: 1200px) {
        .featured-status-icon {
            display: none;
        }
    }
    /*
    still useful?? check

    .sparklineClose {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted ${ props => props.theme.lighter };
    }
    */

`;

export const FeaturedLink = styled(Link)<{ $showLeftBorder: boolean; $showRightBorder: boolean }>`
    font-weight: normal;
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.75rem 0.3rem 0.75rem;
    border-radius: .25rem;
    margin-right: 10px;

    background: ${ props => props.theme.header.featured.item.background };
    border: 1px solid ${ props => props.theme.header.featured.item.border };

    @media screen and (max-width: 767px) {
        flex-direction: row;
    }

    &:hover {
        text-decoration: none;
    }
`;

export const Icon = styled.div`
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0 0.5rem;
    width: 30px;
    min-height: 46px;

    svg {
        font-size: 1.8rem;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;

export const InfoRow = styled.div`
    display: flex;
    padding-top: 0.25rem;
    justify-content: space-between;
    align-items: center;
`;

export const Symbol = styled.span`
    font-size: 0.9rem;
    font-weight: normal;
    margin-right: 1rem;
    white-space: nowrap;
    color: ${ props => props.theme.header.featured.item.text };
`;

export const Price = styled.span`
    font-size: 0.8rem;
    margin-right: 1rem;
    color: ${ props => props.theme.header.featured.item.text };
`;

export const Change = styled.span`
    font-size: 0.8rem;  
`;

export const Options = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    justify-content: space-between;
`;


export const TypeButtonContainer = styled.div`

`;

export const TypeButton = styled.button<TypeButtonProps>`
    font-size: 0.9rem;
    font-family: ${ props => props.theme.fonts.varela };
    background: ${ props => props.selected ? props.theme.pixelPopLight : 'transparent' };
    border: 1px solid ${ props => props.theme.pixelPopLight };
    color: ${ props => props.selected ? props.theme.textActive : props.theme.text };
    margin: 0 0.25rem;
    padding: 0.3rem 0.5rem 0.25rem 0.5rem;
    border-radius: .25rem; 

    /*border: 1px solid transparent;*/
    /*background: ${ props => props.selected ? props.theme.button.bgSelected : 'transparent' };*/
    /*border-bottom-color: ${ props => props.selected ? props.theme.button.borderSelected : 'transparent' };*/
`;

export const TagCloudList = styled.ul`
    padding-left: 0;

    li {
        list-style: none;
        font-family: ${ props => props.theme.fonts.varela };
        display: inline-block;

        a {
            display: inline-block;
            border-radius: .25rem;
            margin-right: 5px;
            padding: 0 6px;
            font-weight: normal;
            font-size: 0.8rem;
            -webkit-text-decoration: none;
            text-decoration: none;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 230px;
            height: 1.3rem;
            white-space: nowrap;
            border-bottom: 1px solid ${ props => props.theme.button.borderSelected }; 

            &:hover {
                border-color: #F9F871;
                color: #F8F8F8;
            }
        }
    }
`;


    /* Ticker Header */

    export const TickerHeaderContainer = styled.div`
        padding: 1rem;
        position: relative;
        z-index: 99;
        border-radius: .25rem;
        min-height: 75px;
        box-shadow: 0px 1px 0px ${ props => props.theme.border };
        border-bottom: 1px solid ${ props => props.theme.pixelPopDark };
    `;
    

    export const LoadingText = styled.div`
        width: 100px;
        height: 1em;
        background: rgba( 255,255,255, 0.1 );
    `;

    export const PriceContainer = styled.div`
        display: flex;
        align-items: center;
    `;

    /* // */

/* // Overview */


export const MarketStatusBlob = styled.div<{isTrading: boolean}>`
    display: flex;
    align-items: center;
    margin: 0 0.5rem;

    &:after {
        content: '';
        background: ${ (props: any) => ( props.color === 'green' ) ? props.theme.marketIndicator.live : ( ( props.color === 'red' ) ? props.theme.marketIndicator.trading : props.theme.marketIndicator.offline ) };
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        margin: 0px;
        height: 10px;
        width: 10px;
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
        animation: ${ (props: any) => props.isTrading ? 'pulse-'+props.color+' 2s infinite' : 'none' };
    }

    @keyframes pulse-red {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
        }
    }
    
    .blob.green {
        background: rgba(51, 217, 178, 1);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
        animation: pulse-green 2s infinite;
    }
    
    @keyframes pulse-green {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
        }
    }
    
`;

/* // Blob */


/* News */

export const NewsContainer = styled.div`
    /*max-height: 410px;*/
`;

export const SaveButton = styled.button`
    padding: 0 4px;
    background: transparent;
    color: #eaeaea;
    border: 1px solid transparent;
    font-size: 1.2rem
`;

// improve props

//color: ${ (props) => props.selected ? props.theme.button.icon[props.display].selected : props.theme.button.icon[props.display].color };
//font-size: ${ (props) => props.display === 'theme' ? '2rem' : '2.5rem' };

export const IconButton = styled.button<IconButtonProps>`
    background: none;
    color: #fff;
    border: 0;
    border: none;
    padding: 3px 2px;

    .svg-inline--fa {
        color: ${ props => props.selected ? props.theme.button.icon[props.display].selected : props.theme.button.icon[props.display].color };
        font-size: ${ props => props.display === 'theme' ? '2rem' : '2.5rem' };
    }

    &:active,
    &:focus {
        box-shadow: none;
        outline: none;
        background-color: transparent !important;
        border-color: transparent !important;
    }

    &:hover {
        box-shadow: none;
        outline: none;

        .svg-inline--fa {
            color: ${ (props: any) => !props.selected ? props.theme.button.icon[props.display].selected : props.theme.button.icon[props.display].color };
        }
    }
`;

/* Card */

export const Card = styled.div`
    background: transparent;
    border: 0;
    
    * {
        border-color: ${ props => props.theme.border } !important;
    }

    &.overview-card {
        border: 1px solid ${ props => props.theme.border };
        font-size: 0.9rem;

        .card-header {
            padding: 0.75rem;
            background: none;
        }

        .table {
            margin-bottom: 0;
        }

        .table-sm td, .table-sm th {
            padding: .3rem 0.75rem;
        }
    }

    .table {
        th.section-heading {
            background: ${ props => props.theme.border };
        }
    }

    .period-switcher {
        border: 0px;

        .tab-container {
            border: 0px;
            background: ${ props => props.theme.border };
            border-radius: .25rem;
            padding: 4px;

            ul {
                border: 0;
            }

            .nav-item {

                a.nav-link {
                    padding: 0.3rem 0.5rem 0.25rem;
                    font-size: 0.9rem;
                    border-radius: .25rem;
                    border-color: transparent !important;
                    color: #eaeaea;

                    &.active {
                        background: #1A606F;
                        color: #ffffff;
                    }
                }

                &:first-child {
                    margin-right: 3px;
                }
            }
        }
    }
`;

/* // */

/* Tabs */

export const OverviewTabs = styled.div`

`;

export const PanelModalTabs = styled.div`

`;

export const TabsHeader = styled.div`
    display: flex;
    justify-content: left;

    button {
        color: ${ props => props.theme.text };
        display: block;
        padding: 0.5rem 1.5rem;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        border: 1px solid ${ props => props.theme.body };
        border-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        background-color: ${ props => props.theme.border };
    
        &:hover {
            text-decoration: none;
        }
    }
    
    button.active {
        border-color: ${ props => props.theme.border };
        background-color: ${ props => props.theme.body };
        margin-bottom: -1px;
    }
`;

export const TabsContent = styled.div`
    background-color: ${ props => props.theme.body };

    > div {
        border-top-left-radius: 0;
    }

    .content {
        display: flex;
        flex-direction: row;
    }
`;


/* // */


/* Ticker Searchbar */


export const TickerList = styled.ul`
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        border-bottom: 1px solid ${ props => props.theme.pixelPopDark };
        border-top: 1px solid ${ props => props.theme.pixelPopLight };
    }

    li:first-child {
        border-top: 0;
    }
    li:last-child {
        border-bottom: 0;
    }
`;

export const Ticker = styled.span`
    font-family: 'Varela Round', sans-serif;
    font-weight: bold;
    display: flex;
    flex: 1 0 0;
`;

export const Name = styled.span`
    font-family: 'Varela Round', sans-serif;
    font-size: 0.9rem;
    display: flex;
    flex: 3 0 0;
`;

export const ActionButton = styled.button`
    margin-left: auto;
    color: ${ props => props.theme.text };
    background-color: ${ props => props.theme.lighter };
    border-radius: 12px;
    width: 23px;
`;