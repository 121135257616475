import { produce } from 'immer';
import { SettingsState } from '../types/state';
import { ActionTypes } from '../actions/settings';
import { Action } from '../types';
import { ErrorInit } from '../init';

//import { log } from '../../../helpers';

export const initialState = Object.freeze<SettingsState>({
    theme: 'dark',
    ticker: {
      isLoaded: false,
      status: 'PAUSED',
      display: 'PRICE',
      selected: {
        id: 'TRENDING',
        name: 'Trending'
      }
    },
    watchlist: {
      isLoaded: false,
      display: 'PRICE',
      selected: {
        id: '',
        name: ''
      }
    },
    trending: {
      isLoaded: false
    },
    featured: {
      showChart: true,
      isLoaded: false,
    },
    country: 'US',
    error: ErrorInit
});

export default ( state: SettingsState = initialState, action: Action ): SettingsState =>
    produce(state, (draft) => {
        switch( action.type ) { 

            case ActionTypes.TOGGLE_THEME: {
              const theme = draft.theme === 'dark' ? 'light' : 'dark';
              draft.theme = theme;

              return draft;
            }

            case ActionTypes.HANDLE_FEATURED_CHART : {
              draft.featured.showChart = action.payload;
            }
              
            

            default:
              return draft;
        }
    });
