/*
import React, { Component } from 'react';
import { Field, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import { ToastContainer, ToastMessage } from 'react-toastr';
import {Actions} from 'store/app/actions/auth';
import * as EmailValidator from 'email-validator';
import { RootState } from 'store';

//const ToastMessageFactory = React.createFactory(ToastMessage.animation);

function Signin( props ) {
    const dispatch = useDispatch();
    const { handleSubmit, pristine, reset, submitting } = props;

    function renderField(field) {
        const { meta: { touched, error } } = field;
        const classNameFormGroup = `form-group ${touched && error ? "has-error" : ""}`;
        const classNameFormControl = `form-control ${touched && error ? "has-error" : ""}`;

        return (
            <div className={classNameFormGroup}>
                <label className="col-xs-3 control-label">{field.label}</label>
                <div className="col-xs-6">
                <input className={classNameFormControl} type="text" {...field.input} />
                <div className="text-help">
                    {touched ? error : ""}
                </div>
                </div>
            </div>
        );
    }

    function renderPasswordField( field ) {
        const { meta: { touched, error } } = field;
        const classNameFormGroup = `form-group ${touched && error ? "has-error" : ""}`;
        const classNameFormControl = `form-control ${touched && error ? "has-error" : ""}`;

        return (
            <div className={classNameFormGroup}>
                <label className="col-xs-3 control-label">{field.label}</label>
                <div className="col-xs-6">
                <input className={classNameFormControl} type="password" {...field.input} />
                <div className="text-help">
                    {touched ? error : ""}
                </div>
                </div>
            </div>
        );
    }

    function getSigninButton() {
        if(submitting) {
            return (
                <div className="spinner spinner-md is-auth0">
                    <div className="circle"></div>
                </div>
            );
        }
        return <button type="submit" className="btn btn-success btn-sm">Submit</button>;
    }

    function getForgotButton() {
        if(!submitting) {
            return <Link to="/forgot" className="btn btn-primary btn-sm">Forgot Password</Link>;
        }
    }

    function onSubmit( values ) {
        const { email, password } = values;
        //Actions.signinUser({ email, password }, () => {
        //  return this.props.history.push("/home");
        //});
        console.log( 'submit' );
        dispatch( Actions.signinUser({ email, password }) );
    }

    return (
        <form className="form-horizontal col-xs-10 col-xs-offset-1" onSubmit={handleSubmit( onSubmit )}>
            <Field
                label="Email"
                name="email"
                component={renderField}
            />
            <Field
                label="Password"
                name="password"
                component={renderPasswordField}
            />
            <div className="form-group">
                <span className="col-xs-3"></span>
                <div className="col-sm-6">
                {getSigninButton()}
                {getForgotButton()}
                </div>
            </div>
        </form>
    );
}

function validate(values) {
    const errors = {};

    if (!values.email || !EmailValidator.validate(values.email)) {
        errors.email = "Enter an email";
    }
    if (!values.password) {
        errors.password = "Enter a password";
    }

    return errors;
}

export default reduxForm({
    validate,
    form: "SigninForm"
  })(Signin);

*/


import React, { Component } from 'react';
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//import * as actions from '../../actions/auth';
import * as AuthActions from 'store/app/actions/auth';

import * as EmailValidator from 'email-validator';
import { LoadingLogin } from 'components/display/Loading';
import { Button } from 'react-bootstrap';
import { DefaultButton, DostrikButton, DostrikForm } from 'styles';

class Signin extends Component {

  componentWillMount() {
    this.props.signoutUser();
  }

  componentWillUpdate(nextProps, nextState) {
    const { error, forgotMsg } = nextProps;
    if (error) {
      nextProps.cleardown();
    } else if (forgotMsg) {
      nextProps.cleardown();
    }
  }

  renderField(field) {
      const { meta: { touched, error } } = field;
      const classNameFormGroup = `form-group ${touched && error ? "has-error" : ""}`;
      const classNameFormControl = `form-control ${touched && error ? "has-error" : ""}`;
      return (
        <div className={classNameFormGroup}>
          <label className="col-xs-3 control-label">{field.label}</label>
          <div className="col-xs-6">
            <input className={classNameFormControl} type="text" {...field.input} />
            <div className="text-help">
              {touched ? error : ""}
            </div>
          </div>
        </div>
      );
  }

  renderPasswordField(field) {
      const { meta: { touched, error } } = field;
      const classNameFormGroup = `form-group ${touched && error ? "has-error" : ""}`;
      const classNameFormControl = `form-control ${touched && error ? "has-error" : ""}`;
      return (
        <div className={classNameFormGroup}>
          <label className="col-xs-3 control-label">{field.label}</label>
          <div className="col-xs-6">
            <input className={classNameFormControl} type="password" {...field.input} />
            <div className="text-help">
              {touched ? error : ""}
            </div>
          </div>
        </div>
      );
  }

  onSubmit(values) {
    const { email, password } = values;
    //this.props.signinUser({ email, password });
    this.props.dispatch( AuthActions.signinUser({ email, password }) );
  }

  getSigninButton() {
    const disabled = {
      ...this.props.loading && { disabled: true }
    };
    return <DefaultButton type="submit" fontSize="1rem" padding="0.5rem 1rem" {...disabled}>Sign in</DefaultButton>;
  }

  getForgotButton() {
    if (!this.props.loading) {
      return <Link to="/forgot" className="ml-2 btn-sm">Forgot Password</Link>;
    }
  }

  isLoading() {
    return this.props.loading;
  }

  render() {
    const { handleSubmit } = this.props;

    // <form className="user-form form-horizontal" onSubmit={handleSubmit(this.onSubmit.bind(this))}>

    return (
        <div className="col-xs-10 col-xs-offset-1">
            <DostrikForm onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <Field
                    label="Email"
                    name="email"
                    component={this.renderField}
                    />
                <Field
                    label="Password"
                    name="password"
                    component={this.renderPasswordField}
                    />
                <div className="form-group">
                    {this.getSigninButton()}
                    {this.getForgotButton()}
                </div>
            </DostrikForm>
        </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.email || !EmailValidator.validate(values.email)) {
    errors.email = "Enter an email";
  }
  if (!values.password) {
    errors.password = "Enter a password";
  }
  return errors;
}

function mapStateToProps(state) {
  const { error, timestamp, forgotMsg, loading } = state.auth;
  return {
    error,
    timestamp,
    forgotMsg,
    loading
  };
}

export default reduxForm({
  validate,
  form: "SigninForm"
})(connect(mapStateToProps, AuthActions)(Signin));