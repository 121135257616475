//import update from 'immutability-helper';

import { produce } from 'immer';
import { StocksState } from '../types/state';
import { ActionTypes } from '../actions/stocks';
import { ActionTypes as AppActionTypes } from '../actions/app';
import { 
    Action, ITickerData, TStocksAll, TCompanyData, TCompanyNews, 
    ICalendarList, IFinancialsType, Financials, TIntraday, THistorical
} from '../types';
import { ErrorInit, TagsInit, FinInit } from '../init'
   
import { log } from '../../../helpers';

//rename 'list' tickerList, companiesList
export const initialState = Object.freeze<StocksState>({
    all: [],
    companyData: [],
    intraday: [],
    historical: {
        '1D': [],
        '5D': [],
        '1M': [],
        '6M': [],
        '1Y': [],
        '5Y': [],
        'ALL': []
    },
    chart: [],

    tags: TagsInit,
    // change for isFetching in intraday and historical
    isFetching: false,
    isTrading: false,
    error: ErrorInit
});
  
export default ( state: StocksState = initialState, action: Action ): StocksState =>
    produce(state, (draft) => { 
        switch( action.type ) {

            case ActionTypes.FETCH_STATUS:
                const isFetchingPayload: boolean = action.payload;
                draft.isFetching = isFetchingPayload;
                return draft;
            
            case ActionTypes.GET_OVERVIEW_DATA_SUCCESS: {
                draft.isFetching = false;
                return draft;
            }

            case ActionTypes.GET_ALL_STOCKS_SUCCESS:
                const allStocksPayload: TStocksAll[] = action.payload;
                draft.all = allStocksPayload;
                return draft;


            case ActionTypes.GET_SYMBOLS_BY_TAG:
                draft.tags = { ...TagsInit, isFetching: true };
                return draft;

            case ActionTypes.GET_SYMBOLS_BY_TAG_SUCCESS:
                const resultTag = action.payload;

                console.log( 'resultTag' );
                console.log( resultTag );

                /* check when loading data is false, not the same result / object tree.
                if( resultTag.data.length !== 0 ) {
                    
                    const dataTag = resultTag.data[0];
                    
                    draft.tags = { 
                        ...draft.tags, 
                        isLoaded: true,
                        isFetching: false,
                        tickers: dataTag.companies, 
                        selected: dataTag.tag, // check if necessary?
                    };
                }*/

                if( resultTag.length !== 0 ) {
    
                    draft.tags = { 
                        ...draft.tags, 
                        isLoaded: true,
                        isFetching: false,
                        tickers: resultTag.data, 
                        selected: resultTag.tag, // check if necessary?
                    };

                }

                return draft;

            case ActionTypes.GET_SYMBOLS_BY_TAG_FAIL:
                draft.tags = { 
                    ...TagsInit,
                    error: { 
                        status: true, 
                        type: 'to do',
                        message: action.payload.message 
                    }
                };
                return draft;
    
            case ActionTypes.GET_COMPANY_DATA_SUCCESS:
                const companyData = action.payload.data;
                const companyDataUpdate: TCompanyData = {
                    ticker: companyData.company.ticker,
                    stats: companyData.company.company_stats[0],
                    info: companyData.company,
                    calendar: { isLoaded: false, list: [] },
                    news: { isLoaded: false, items: [] },
                    financials: FinInit
                }
                draft.companyData = [ ...draft.companyData, companyDataUpdate]
                return draft;

            case ActionTypes.GET_COMPANY_DATA_FAIL:
                return draft;
            
            case ActionTypes.GET_COMPANY_NEWS_SUCCESS:
                const newsState = [ ...draft.companyData ]
                const newsTicker: string = action.payload.ticker;
                const newsData: TCompanyNews = action.payload.data;
                const companyDataWithNews = newsState.map( item => {
                    if( item.ticker === newsTicker ) {
                        return { ...item, news: { ...newsData, isLoaded: true } };
                    } else {
                        return { ...item };
                    }
                });
               draft.companyData =  companyDataWithNews;
               return draft;

            case ActionTypes.GET_COMPANY_NEWS_FAIL:
                return draft;
        
            case ActionTypes.GET_COMPANY_CALENDAR_SUCCESS:
                const calendarState = [ ...draft.companyData ]
                const calendarTicker: string = action.payload.ticker;
                const calendarData: ICalendarList[] = action.payload.data;
                const companyDataWithCalendar = calendarState.map( item => {
                    if( item.ticker === calendarTicker ) {
                        return { ...item, calendar: { isLoaded: true, list: [ ...calendarData ] } };
                    } else {
                        return { ...item };
                    }
                });
                draft.companyData = companyDataWithCalendar;
                return draft;

            case ActionTypes.GET_COMPANY_CALENDAR_FAIL:
                return draft;

            case ActionTypes.GET_COMPANY_FINANCIALS:
                const financialSymbol = action.payload.symbol;
                const financialState = [ ...draft.companyData ];
                //findAndUpdate( ticker, data)
                const updatedFinancials = financialState.map( item => {
                    if( item.ticker === financialSymbol ) {
                        return { ...item, financials: { ...item.financials, isFetching: true }};
                    } else {
                        return { ...item };
                    }
                });
                draft.companyData = updatedFinancials;
                return draft;;

            case ActionTypes.GET_COMPANY_FINANCIALS_SUCCESS:
                const companyFinancialsPayload: { symbol: string, data: IFinancialsType[] } = action.payload;
                const _financialSymbol: string = companyFinancialsPayload.symbol;
                const financialData  = companyFinancialsPayload.data;
                const _financialState = [ ...draft.companyData ];
                const newBalance = financialData.filter( item => item.type === 'BALANCE_SHEET' );
                const newCash = financialData.filter( item => item.type === 'CASH_FLOW' );
                const newIncome = financialData.filter( item => item.type === 'INCOME_STATEMENT' );
                const newEarnings = financialData.filter( item => item.type === 'EARNINGS' );
                const newFinancialItem: Financials = { 
                    isLoaded: true, 
                    isFetching: false,
                    balance: newBalance, 
                    cash: newCash, 
                    earnings: newEarnings, 
                    income: newIncome
                };
                const _updatedFinancials = _financialState.map( item => {
                    if( item.ticker === _financialSymbol ) {
                        return { ...item, financials: newFinancialItem };
                    } else {
                        return { ...item };
                    }
                });
                draft.companyData = _updatedFinancials;
                return draft;

            case ActionTypes.GET_COMPANY_FINANCIALS_FAIL:
                const companyFinancialsPayload2: { symbol: string, data: IFinancialsType[] } = action.payload;
                const _financialSymbol2: string = companyFinancialsPayload2.symbol;
                const financialData2  = companyFinancialsPayload2.data;
                const _financialState2 = [ ...draft.companyData ];
                const newFinancialItem2: Financials = { 
                    isLoaded: true, 
                    isFetching: false,
                    balance:  [], 
                    cash:  [], 
                    earnings:  [], 
                    income: []
                };
                const _updatedFinancials2 = _financialState2.map( item => {
                    if( item.ticker === _financialSymbol2 ) {
                        return { ...item, financials: newFinancialItem2 };
                    } else {
                        return { ...item };
                    }
                });
                draft.companyData = _updatedFinancials2;
                return draft;

            case ActionTypes.GET_INTRADAY_DATA_SUCCESS:
                let exists = false;
                const resultIsTrading: boolean = action.payload.debugIsTrading;
                const intradayChartData = action.payload.data;
                const intradayData: TIntraday = {
                    ticker: action.payload.ticker,
                    dateStart: action.payload.dateStart,
                    timeStart: action.payload.timeStart,
                    timeEnd: action.payload.timeEnd,
                    source: action.payload.source,
                    currentPrice: parseFloat( action.payload.current ),
                    dayHigh: action.payload.dayHigh,
                    dayLow: action.payload.dayLow,
                    dayChange: parseFloat( action.payload.dayChange ),
                    previousClosePrice: action.payload.previousClosePrice,
                    currency: action.payload.currency,
                    data: intradayChartData
                };
                const intradayListState = [ ...draft.intraday ];
                const updatedIntraday = [];
                const tIntraday = intradayListState.map( item => {
                    if( item.ticker === intradayData.ticker ) {
                        exists = true;
                        return intradayData;
                    } else {
                        return item;
                    }
                });
                if( !exists ) { updatedIntraday.push( intradayData ); }
                const returnIntraday = updatedIntraday.concat( tIntraday );
                draft = { ...draft, isFetching: false, isTrading: resultIsTrading, intraday: returnIntraday };
                return draft;
                //return { ...state, isFetching: false, intraday: [ ...state.intraday, intradayData ], historical: { ...state.historical, '1D': [intradayData] } }

            case ActionTypes.GET_INTRADAY_DATA_FAIL:
                return draft;

            case ActionTypes.GET_RANGE_DATA_SUCCESS:
                console.log('RANGE REDUCER');
                let _exists = false;
                const rangeItem: { range: string, data: ITickerData } = action.payload;
                const rangeTicker = rangeItem.data.ticker;
                const range = rangeItem.range;
                const rangeListState = [ ...draft.historical[range] ];
                const rangeData = {
                    ticker: action.payload.data.ticker,
                    dateStart: action.payload.data.dateStart,
                    timeStart: action.payload.data.timeStart,
                    timeEnd: action.payload.data.timeEnd,
                    source: action.payload.data.source,
                    range: action.payload.range,
                    previousClosePrice: action.payload.data.previousClosePrice,
                    currency: action.payload.data.currency,
                    data: action.payload.data.data
                }
                const updatedRange: THistorical[] = [];
                const tRange = rangeListState.map( item => {
                    if( item.ticker === rangeTicker ) {
                        _exists = true;
                        return rangeData;
                    } else {
                        updatedRange.push( rangeData );
                        return item;
                    }
                });
                if( !_exists ) { updatedRange.push( rangeData ); }
                draft.historical = { ...draft.historical, [range]: updatedRange.concat( tRange ) };
                return draft;

            case ActionTypes.GET_RANGE_DATA_FAIL:
                return draft;

            case ActionTypes.TRADING_STATUS:
                draft.isTrading = action.payload;
                return draft;

            
              
            case AppActionTypes.CHANGE_PAGE: {
                draft.tags = { ...TagsInit };
                
                return draft;
            }

            default:
                return draft;

        }
});