import update from 'immutability-helper';

import { produce } from 'immer';
import { WatchlistState } from '../types/state';
import { ActionTypes } from '../actions/watchlist';
import { ActionTypes as UserActionTypes } from '../actions/user';
import { ActionTypes as AppActionTypes } from '../actions/app';


import { 
    Action, IWatchlists, ITickerData, ITickerUpdateData, 
    IStockList, ICrypto, INewsList, CryptoDataPayload 
} from '../types';
   
import { formatDecimals, checkForNull } from '../../../helpers';
import { CryptoListInit, ErrorInit, TickerDataInit } from '../init';


function checkForNull2( data: number[] ) {
    return data.filter( (number) => number !== null );
}

export const initialState = Object.freeze<WatchlistState>({
    live: [],
    watchlists: {
        isLoaded: false,
        isFetching: false,
        isEmpty: true,
        selected: null,
        data: [],
        temp: [],
        error: ErrorInit,
    },
    trending: {
        "US": {
            isFetching: false,
            isLoaded: false,
            isLoadedData: false,
            list: [],
            error: ErrorInit,
        },
        "CA": {
            isFetching: false,
            isLoaded: false,
            isLoadedData: false,
            list: [],
            error: ErrorInit,
        }
    },
    featured: {
        isFetching: false,
        isLoaded: false,
        list: [],
        error: ErrorInit,
    },
    crypto: CryptoListInit,
    isLive: false,
    error: {
        status: false,
        type: "",
        message: ""
    }
});


export default ( state: WatchlistState = initialState, action: Action ): WatchlistState =>
    produce(state, (draft) => {
        switch( action.type ) {

            /* User */

            case UserActionTypes.LOGOUT: {
                draft.watchlists = { ...initialState.watchlists };
                return draft;
            }
            /*
            ############################################
            ################  Watchlists  ##############
            ############################################ */
            
            case ActionTypes.LOAD_ALL_WATCHLISTS: {

                draft.watchlists = { 
                    ...draft.watchlists,
                    isLoaded: false, 
                    isFetching: true, 
                    error: ErrorInit 
                };

                return draft;
            }

            case ActionTypes.LOAD_ALL_WATCHLISTS_SUCCESS: {
                const watchlists = action.payload;
                const checkTrendingList = { ...draft.trending };
                const tStateWatchlists = watchlists.map( (watchlist: IWatchlists) => ({ ...watchlist, isLoaded: false }));
                const tAllWatching: string[] = [];
                let isEmpty = watchlists.length === 0;
                let selected = null;
                let updatedList = [...draft.live];

                if( !isEmpty ) { selected = watchlists[0].id; }

                watchlists.map( (watchlist: IWatchlists) => 
                    watchlist.list.map( item => {
                        const symbol = item.symbol ? item.symbol : "";
                        const newItem = { ...TickerDataInit, id: item.id, symbol: symbol, ticker: symbol };
                        updatedList = updateLiveData( updatedList, newItem );
                        tAllWatching.push( symbol );
                    })
                );

                Object.keys( checkTrendingList ).map( key => {
                    const tTrendingTickers = checkTrendingList[key].list;
                    const tUpdatedTrendingTickers = [];
                    for( var k=0; k<tTrendingTickers.length; k++) {
                        let isWatching = tAllWatching.find( ticker => tTrendingTickers[k].symbol === ticker );
                        tUpdatedTrendingTickers.push({ 
                            ...tTrendingTickers[k], 
                            ...isWatching !== undefined && { watching: true }
                        });
                    }
                    draft.trending[key].list = tUpdatedTrendingTickers;
                });

                draft.live = updatedList;
                draft.watchlists = {
                    ...draft.watchlists,
                    isLoaded: true, 
                    isFetching: false, 
                    isEmpty: isEmpty, 
                    selected: selected, 
                    data: tStateWatchlists 
                };

                return draft;
            }

            case ActionTypes.LOAD_ALL_WATCHLISTS_FAIL: {
                draft.watchlists.error = {
                    status: true,
                    type: 'Error',
                    message: action.payload
                };

                return draft;
            }

            case ActionTypes.UPDATE_SELECTED_WATCHLIST_SUCCESS: {
                const selectedListPayload = action.payload;   
                const selectedList = selectedListPayload.key;
                const updateSelectedWatchlist = [...draft.watchlists.data];

                draft.watchlists.data = updateSelectedWatchlist.map( list => {
                    if( list.id === selectedList ) {
                        return { ...list, isLoaded: true };
                    }
                    return {...list};
                });
                draft.watchlists.selected = selectedList;

                return draft;
            }

            //To do: Add / remove from watchlists
            case ActionTypes.UPDATE_WATCHLIST_SUCCESS: {
                const updateWatchlistId = action.payload.watchlistId;
                const updateTickerId = action.payload.tickerId;
                const updateTicker = action.payload.ticker;
                const updateType = action.payload.type;
                const updatePrice = action.payload.price;
                let newStockToWatchList = [];

                switch( updateType ) {
                    case 'ADD':
                        // Voir array / object
                        var toPush = { id: updateTickerId, symbol: updateTicker };
                        /*
                        newStockToWatchList = update( state.list, {$push: [toPush]} );

                        draft.live
                        add to live!!
                        */
                        const watchlistsDraft = [...draft.watchlists.data];
                        const liveDraft = [...draft.live];

                        draft.watchlists.data = watchlistsDraft.map( watchlist => {
                            if( watchlist.id === updateWatchlistId ) {
                                return { ...watchlist, list: update([...watchlist.list], {$push: [toPush]}) }
                            }
                            return watchlist;
                        });

                        const exists = liveDraft.find( live => live.ticker === updateTicker );

                        if( !exists ) {
                            // somehow get all info?
                            // make it so when it's added, goes in right spot in list. currently ordered by id, order by date added by default?
                            draft.live = update([...liveDraft], {$push: [{id: updateTickerId, ticker: updateTicker, price: formatDecimals( updatePrice ) }]});
                        }
                }

                //return { ...state, list: newStockToWatchList }
                return draft;
            }

            case ActionTypes.UPDATE_WATCHLIST_FAIL: {
                return draft;
            }

            case ActionTypes.ADD_TO_WATCHLIST: {
                let tempWatchlist = [...draft.watchlists.temp];
                const addToTemp = action.payload;
                draft.watchlists.temp = update(tempWatchlist, {$push: [addToTemp]});

                return draft;
            }

            case ActionTypes.REMOVE_FROM_WATCHLIST: {
                let tempWatchlist = [...draft.watchlists.temp];
                const idToRemoveFromTemp = action.payload;
                draft.watchlists.temp = tempWatchlist.filter( item => item.id !== idToRemoveFromTemp );
                return draft;
            }

            // When submitting, post watchlist to DB and get ID before updating state
            // Add SUBMIT_TEMP_WATCHLIST_SUCCESS
            case ActionTypes.SUBMIT_TEMP_WATCHLIST: {
                const tempName = action.payload;
                
                let tempWatchlist = [...draft.watchlists.temp].map( item => ({ id: item.id, symbol: item.ticker }));
                let watchlistsData = [...draft.watchlists.data];

                const newWatchlist = { id: 0, name: tempName, list: tempWatchlist, isLoaded: false };
                draft.watchlists.data = update(watchlistsData, {$push: [newWatchlist]});

                return draft;
            }
    

            /*  Still useful?
                Use for single watchlist?
                
            case WATCHLIST_EMPTY:
                console.log( 'WATCHLIST_EMPTY' );
                return { ...state, isEmpty: true };*/
            

            case ActionTypes.LOAD_WATCHLIST_DATA_SUCCESS: {
                const stateWatchlist = [ ...draft.watchlists.data ];
                const dataList: ITickerData[] = action.payload.data;
                const watchlistId = action.payload.watchlistId;
                const updatedById = stateWatchlist.map( watchlist => {
                    if( watchlist.id === watchlistId ) {
                        const updatedWatchlist = [];
                        const list = [ ...watchlist.list ];
                        for( let i=0; i < list.length; i++ ) {
                            updatedWatchlist.push({
                                ...list[i], 
                                ...(dataList.find( item => item.ticker === list[i].ticker))}
                            );
                        }
                        return { ...watchlist, list: updatedWatchlist, isLoaded: true };
                    } else {
                        return { ...watchlist };
                    }
                });
                console.log('updatedById');
                console.log( updatedById );

                draft.watchlists = { ...draft.watchlists, data: updatedById };
                return draft;
            }

            case ActionTypes.LOAD_WATCHLIST_DATA_FAIL: {
                return draft;
            }


            /*
            ##############################################
            ################  Spark Charts  ##############
            ############################################## */
            
            case ActionTypes.GET_SPARK_CHARTS: {
                // not in state, check if necessary
                //draft.isFetching = true;

                return draft;
            }

            case ActionTypes.GET_SPARK_CHARTS_SUCCESS: {
                const listToUpdate = [ ...draft.live ];
                const tSparkData: ITickerData[] = action.payload;
                const time1 = new Date().getTime();
                let updatedList = listToUpdate;

                for( var k=0; k<tSparkData.length; k++) {
                    const validSparkData = checkForNull2( tSparkData[k].data );
                    const sparkData = { ...tSparkData[k], data: validSparkData };

                    updatedList = updateLiveData( updatedList, sparkData );
                }

                /*
                for(let i=0; i<listToUpdate.length; i++) {
                    console.log('For');
                    let itemFormatted;
                    const findIndex = tData.findIndex( item => item.ticker === listToUpdate[i].symbol );
                    const item = findIndex !== -1 ? tData[findIndex] : listToUpdate[i];

                    const priceFormatted = item.price ? formatDecimals( item.price ) : null;
                    const changePercentFormatted = item.changePercent ? formatDecimals( item.changePercent ) : null;
                    const changePriceFormatted = item.changePrice ? formatDecimals( item.changePrice ) : null;
                    itemFormatted = { ...item, price: priceFormatted, changePercent: changePercentFormatted, changePrice: changePriceFormatted, data: checkForNull( [...item.data] ) };
                    
                    updatedList.push({
                        ...listToUpdate[i], 
                        ...itemFormatted
                    });
                }

                console.log( 'GET_SPARK_CHARTS_SUCCESS' );
                console.log( JSON.stringify( updatedList ) );
                */

                
                const time2 = new Date().getTime();

                console.log( 'GET_SPARK_CHARTS_SUCCESS: '+(time2 - time1)+'ms' );

                draft = { ...draft, /*isFetching: false,*/ live: updatedList }
                return draft;
            }

            case ActionTypes.GET_SPARK_CHARTS_FAIL: {
                return draft;
            }


            /*
            ########################################
            ################  Crypto  ##############
            ######################################## */
            
            case ActionTypes.CRYPTO_UPDATE: {
                const cryptoState = [ ...draft.crypto ];
                const resultCryptoList: ICrypto[] = action.payload;
                const updatedCryptoByTicker: ICrypto[] = [];
                for( let i=0; i < cryptoState.length; i++ ) {
                    updatedCryptoByTicker.push({
                        ...cryptoState[i], 
                        ...(resultCryptoList.find( (item) => item.ticker === cryptoState[i].ticker ))
                    });
                }

                draft.crypto = updatedCryptoByTicker;
                return draft;
            }

            // payload is only 1 result, remove .find, uncessary
            case ActionTypes.GET_CRYPTO_CHART_SUCCESS: {
                // temp, only need data when remove .find
                const _cryptoState = [ ...draft.crypto ];
                //const tickerCrypto = action.payload.ticker;
                //const dataCrypto = action.payload.data;
                const payloadCrypto: CryptoDataPayload = action.payload;
                const updatedCryptoList: ICrypto[] = [];
                //let found = false;
                for( let i=0; i<_cryptoState.length; i++ ) {
                    const cItem = _cryptoState[i];
                    if( payloadCrypto.ticker === cItem.ticker ) {
                        const newObj = update(cItem, { $merge: { data: payloadCrypto.data } });
                        updatedCryptoList.push( newObj );
                    }
                    /*
                    const item = payloadCrypto.find( item => item.ticker === _cryptoState[i].ticker );
                    updatedCryptoList.push({
                        ..._cryptoState[i], 
                        ...(payloadCrypto.find( item => {
                            const t = item.ticker;
                            return t === _cryptoState[i].ticker 
                        } ))}
                    ); */
                }

                draft.crypto = updatedCryptoList;
                return draft;
            }

            case ActionTypes.GET_CRYPTO_CHART_FAIL: {
                return draft;
            }


            /*
            ########################################
            ################  Ticker  ##############
            ######################################## */
            
            case ActionTypes.TICKER_UPDATE: {
                const _listToUpdate = [ ...draft.live ];
                const tickerUpdatePayload: ITickerUpdateData[] = action.payload;

                const tickerUpdateData = tickerUpdatePayload.map( item => ({ ...item, price: formatDecimals( item.price ), changePercent: formatDecimals( item.changePercent ), updated: item.updated }));
                const _updatedList: ITickerData[] = [];
                for(let i=0; i<_listToUpdate.length; i++) {
                    _updatedList.push({
                        ..._listToUpdate[i], 
                        ...(tickerUpdateData.find( (item) => item.ticker === _listToUpdate[i].ticker ))
                    });
                }

                draft.live = _updatedList;
                return draft;
            }

            case ActionTypes.GET_TICKER_DATA: {
                //draft.isFetching = true;
                return draft;
            }

            case ActionTypes.GET_TICKER_DATA_SUCCESS: {
                //const __listToUpdate = [ ...draft.live ];
                //const __updatedList: ITickerData[] = [];

                const tTickerData: ITickerData[] = action.payload;

                let updatedList = [ ...draft.live ];

                for( var k=0; k<tTickerData.length; k++) {
                    const validTickerData = checkForNull2( tTickerData[k].data );
                    const tickerData = { ...tTickerData[k], data: validTickerData };
                    
                    updatedList = updateLiveData( updatedList, tickerData );
                }

                /*
                for(let i=0; i<__listToUpdate.length; i++) {
                    const findIndex = tTickerData.findIndex( item => item.ticker === __listToUpdate[i].symbol );
                    const item = findIndex !== -1 ? tTickerData[findIndex] : __listToUpdate[i];

                    const priceFormatted = item.price ? formatDecimals( item.price ) : null;
                    const changePercentFormatted = item.changePercent ? formatDecimals( item.changePercent ) : null;
                    const changePriceFormatted = item.changePrice ? formatDecimals( item.changePrice ) : null;
                    const itemFormatted = { ...item, price: priceFormatted, changePercent: changePercentFormatted, changePrice: changePriceFormatted, data: checkForNull( [...item.data] ) };
                    
                    __updatedList.push({ ...__listToUpdate[i], ...itemFormatted });
                }
                */

                draft.live = updatedList;
                return draft;
            }

            case ActionTypes.GET_TICKER_DATA_FAIL: {
                return draft;
            }


            /*
            ##########################################
            ################  Trending  ##############
            ########################################## */
            
            case ActionTypes.LOAD_TRENDING: {
                const trendingCountry: string = action.payload.country;

                draft = { 
                    ...draft, 
                    trending: { 
                        ...draft.trending, 
                        [trendingCountry]: { 
                            ...draft.trending[trendingCountry], 
                            isLoaded: false, 
                            isFetching: true,
                            error: ErrorInit
                        } 
                    } 
                };

                return draft;
            }

            case ActionTypes.LOAD_TRENDING_SUCCESS: {

                console.log("   LOAD_TRENDING_SUCCESS reducer");
                // Checking for tickers watched by user in trending list.
                const all = [ ...draft.watchlists.data ];
                const trendingPayload: { country: string, data: string[] } = action.payload;
                const _trendingCountry = trendingPayload.country;
                const newTrendingList: IStockList[] = trendingPayload.data.map( ticker => {
                    for( let i = 0; i < all.length; i++ ) {
                        const watching = all[i].list.some( watchlistItem => ticker === watchlistItem.symbol );
                        if( watching ) { 
                            return { /*id: 0,*/ symbol: ticker, watching: true };
                        }
                    }
                    // check if working, out of if so it doesn't return undefined.
                    return { /*id: 0,*/ symbol: ticker, watching: false };
                });

                draft.trending = { 
                    ...draft.trending, 
                    [_trendingCountry]: { 
                        ...draft.trending[_trendingCountry], 
                        // isLoaded, but still fetching data...
                        isLoaded: true,
                        list: newTrendingList
                    }  
                };
                return draft;
            }

            // improve error names
            case ActionTypes.LOAD_TRENDING_FAIL: {
                const errorCountry: string = action.payload.country;

                draft.trending = { 
                    ...draft.trending, 
                    [errorCountry]: { 
                        // create InitTrending - check
                        isFetching: false,
                        isLoaded: false,
                        isLoadedData: false,
                        list: [],
                        error: {
                            status: true,
                            type: 'Error',
                            message: 'Network error'
                        }
                    }  
                };

                return draft;
            }

            case ActionTypes.LOAD_TRENDING_DATA: {

                const trendingCountry: "US" | "CA" = "US"; // temp - check

                draft.trending = { 
                    ...draft.trending, 
                    [trendingCountry]: { 
                        ...draft.trending[trendingCountry], 
                        isFetching: true
                    } 
                };

                return draft;
            }

            case ActionTypes.LOAD_TRENDING_DATA_SUCCESS: {
                //const ___listToUpdate = [ ...draft.live ];
                //const trendingData = trendingDataPayload.data;
                //const ___updatedList = ___listToUpdate.concat( trendingData );

                console.log( "LOAD_TRENDING_DATA_SUCCESS reducer" );


                const trendingData: { country: "US" | "CA" } = action.payload;
                // change const name

                draft.trending = { 
                    ...draft.trending, 
                    [trendingData.country]: { 
                        ...draft.trending[trendingData.country], 
                        isFetching: false,
                        isLoadedData: true
                    } 
                };

                return draft;
            }

            case ActionTypes.LOAD_TRENDING_DATA_FAIL: {
                // tf = trending fail

                const trendingFailCountry: "US" | "CA" = action.payload.country;
                const trendingFailError = action.payload.error;
                
                draft.trending[trendingFailCountry] = { 
                    ...draft.trending[trendingFailCountry],
                    // create InitTrending - check
                    // reset data? if list loaded but not data, what do?
                    /*
                        isFetching: false,
                        isLoaded: false,
                        isLoadedData: false,
                        list: [],
                    */
                    isFetching: false,
                    error: {
                        status: true,
                        type: trendingFailError.type,
                        message: trendingFailError.message
                    }
                };

                return draft;
            }

            case ActionTypes.UPDATE_TRENDING: {
                const tickerToUpdate = action.payload;
                // temp, see how to get current country
                const trendingList = [...draft.trending["US"].list];
                const trendingUpdate = trendingList.map( item => {
                    if( item.symbol === tickerToUpdate ) {
                        return {...item, watching: true };
                    }
                    return {...item};
                });

                draft.trending["US"].list = trendingUpdate;

                return draft;
            }


            /*
            ##########################################
            ################  Featured  ##############
            ########################################## */
            
            case ActionTypes.LOAD_FEATURED: {
                draft.featured = { ...draft.featured, isLoaded: false, isFetching: true };
                return draft;
            }

            case ActionTypes.LOAD_FEATURED_SUCCESS: {

                const featuredPayload: Array<{id: number, symbol?: string, ticker?: string}> = action.payload;
                const tFeaturedTickers = featuredPayload.map( item => ({ id: item.id, symbol: item.symbol }) );
                
                    // see if necessary
                    /*
                    const tFeaturedData: ITickerData[] = featuredPayload.map( item => ({ ...TickerDataInit, id: item.id, symbol: item.symbol, ticker: item.symbol, data: [] }) );
                    let updatedList = [ ...draft.live ];

                    for( var k=0; k<tFeaturedData.length; k++) {
                        updatedList = updateLiveData( updatedList, tFeaturedData[k] );
                    }
                    */

                draft = { 
                    ...draft, 
                    //live: updatedList, 
                    featured: { 
                        ...draft.featured, 
                        list: tFeaturedTickers 
                    }
                };

                return draft;
            }

            case ActionTypes.LOAD_FEATURED_FAIL: {
                return draft;
            }

            case ActionTypes.LOAD_FEATURED_DATA_SUCCESS: {

                draft.featured = { 
                    ...draft.featured, 
                    isLoaded: true, 
                    isFetching: false
                };

                return draft;
            }

            case ActionTypes.LOAD_FEATURED_DATA_FAIL: {
                return draft;
            }


            /*
            ###########################################
            ################  Websocket  ##############
            ########################################### */

            case ActionTypes.WS_CONNECT: {
                draft.isLive = true;
                return draft;
            }

            case ActionTypes.WS_DISCONNECT: {
                draft.isLive = false;
                return draft;
            }

            case ActionTypes.WS_SUBSCRIBE_SUCCESS: {
                const subscribePayload = action.payload;
                const listToUpdateWS = [ ...draft.live ];
                const updatedListWS = listToUpdateWS.map( item => {
                    let r = item;
                    for( let i=0; i<subscribePayload.length; i++ ) {
                        if( subscribePayload[i].ticker === item.ticker ) {
                            r = { ...item, sub: true };
                            break; 
                        }
                    }
                    return r;
                });
                draft.live = updatedListWS;
                return draft;
            }

            case ActionTypes.WS_SUBSCRIBE_FAIL: {
                return draft;
            }
                
            case ActionTypes.WS_SUBSCRIBE_CRYPTO_SUCCESS: {
                return draft;
            }
            
            case ActionTypes.WS_SUBSCRIBE_CRYPTO_FAIL: {
                return draft;
            }

            case ActionTypes.WS_UNSUBSCRIBE_CRYPTO: {
                return draft;
            }
        

                /* APP */

                /* check? no error in app state?
                case AppActionTypes.UPDATE_TICKERBAR_VALUE: {
                    //temp
                    draft.trending["US"].error = ErrorInit;
                    return draft;
                }
                */


            /*
            #########################################
            ################  Default  ##############
            ######################################### */

            default:
                return draft;
        }
    });




// Add functions to format decimals, manage types, etc.
function updateLiveData( liveData, newData ) {
    const live = liveData;
    const data = newData;
    let updateLive = [];

    /*
    const liveInit = {
        id: number,
        symbol?: string,
        ticker?: string,
        name?: string,
        price: string | number | null,
        data: number[], // uniformiser
        changePercent: number | null, // uniformiser
        changePrice: string | number, // uniformiser
        previousClose: string | number, // uniformiser
        previousDate?: string,
        isLoaded?: boolean,
        watching?: boolean,
        sub?: boolean
    };
    */

    //check if optimal / performance 
    // add types
    const exists = live.findIndex( (liveItem) => {
        if( liveItem.id === undefined || liveItem.id === 0 || data.id === undefined || data.id === 0 ) {
            return liveItem.ticker === data.ticker;
        }
        return liveItem.id === data.id;
    });


    if( exists === -1 ) {
        const formattedData = { 
            ...data, 
            //  temp check - live price supposed to be number.
            //...data.price !== null && { price: formatDecimals(data.price) }, 
            ...data.price !== null && { price: data.price }, 
            ...data.changePercent !== null && { changePercent: data.changePercent },
            ...data.changePrice !== "" && { changePrice: formatDecimals(data.changePrice) },
            ...data.status && { status: data.status },
        };

        updateLive = update(live, {$push: [formattedData]});

        return updateLive;
    }

    updateLive = live.map( (liveItem, index) => {
        if( index === exists ) {
            return {
                ...liveItem,
                ...data.id && { id: data.id },
                ...data.name && { name: data.name },
                //...data.price !== null && { price: formatDecimals(data.price) }, 
                ...data.price !== null && { price: data.price }, 
                ...data.changePercent !== null && { changePercent: data.changePercent },
                // temp check if formatDecimals ok here
                //...data.changePercent !== "" && { changePrice: formatDecimals(data.changePrice) },
                ...data.changePrice !== "" && { changePrice: data.changePrice },
                ...data.status && { status: data.status },
                data: data.data,
                previousClose: data.previousClose,
                previousDate: data.previousDate,
            };
        }
        return { ...liveItem };
    });

    return updateLive;

}