import { Action } from '../types';
import { UserLogin } from '../types';

export const ActionTypes = {
    LOGIN_ATTEMPT: "LOGIN_ATTEMPT",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",
    LOGOUT: "LOGOUT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

    REGISTER_ATTEMPT: "REGISTER_ATTEMPT",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAIL: "REGISTER_FAIL",

    // test
    GET_USER_INFO: "GET_USER_INFO",
    SET_USER_INFO: "SET_USER_INFO",

    SET_LOADING: "SET_LOADING",
};

/* User Actions */

export const login = ( user: UserLogin ): Action => ({
    type: ActionTypes.LOGIN_ATTEMPT,
    payload: user
});

export const logout = (): Action => ({
    type: ActionTypes.LOGOUT
});

export const Actions = {
    login: login,
    logout: logout
};