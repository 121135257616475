import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Border, OverviewTabs as Tabs, TabsContent, TabsHeader } from 'styles';
import { BalanceTable, CashTable, IncomeTable, EarningsTable } from './Tables';
import { Loading } from 'components/display/Loading';
import { checkCompanyFinancialsState } from 'store/app/selectors';
import { RootState } from 'store';

export function OverviewTabs( props: { ticker: string, component?: string, currentPrice: string | number | null } ) {
    const dispatch = useDispatch();
    const ticker =  props.ticker;
    const { data, isLoaded } = useSelector( (state: RootState) => checkCompanyFinancialsState( state, { type: 'ALL', symbol: ticker } ) );
 
    const balance = data.balance;
    const cash = data.cash;
    const earnings = data.earnings;
    const income = data.income;

    const component = props.component ? props.component : 'BALANCE';
    const currentPrice = props.currentPrice;

    const[ activeTab, setActiveTab ] = useState( component );

    const handleTab = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const tab = event.target as HTMLButtonElement;
        return setActiveTab( tab.id );
    }
    

    useEffect( () => {
        if( !isLoaded ) {
            console.log('dispatch');
            dispatch({ type: 'GET_COMPANY_FINANCIALS', payload: ticker });
        }
    }, []);

    console.log(balance);

    const renderTabContent = () => {
        switch( activeTab ) {
            case 'BALANCE':
                return <BalanceTable data={balance} isLoaded={isLoaded} isEmpty={isLoaded && balance.length === 0} />
            case 'CASH_FLOW':
                return <CashTable data={cash} />
            case 'EARNINGS':
                return <EarningsTable data={earnings} isLoaded={isLoaded} isEmpty={isLoaded && earnings.length === 0} />
            case 'INCOME':
                return <IncomeTable data={income} />
        }
    }

    return (
        <Tabs>
            <TabsHeader className="tabs">
                <button onClick={ handleTab } id="BALANCE" className={ activeTab === 'BALANCE' ? "active" : "" }>Balance Sheet</button>
                <button onClick={ handleTab } id="CASH_FLOW" className={ activeTab === 'CASH_FLOW' ? "active" : "" }>Cash Flow</button>
                <button onClick={ handleTab } id="EARNINGS" className={ activeTab === 'EARNINGS' ? "active" : "" }>Earnings</button>
                <button onClick={ handleTab } id="INCOME" className={ activeTab === 'INCOME' ? "active" : "" }>Income</button>
            </TabsHeader>
            <TabsContent>
                <Border padding="0.5rem">
                    { renderTabContent() }
                </Border>
            </TabsContent>
        </Tabs>
    );
}