import React from 'react';
import { useSelector } from 'react-redux';
//import '../assets/styles/Loading.css';
import ContentLoader from 'react-content-loader';

import { Spinner, SpinnerProps } from 'react-bootstrap';
import { LoadingProps } from 'store/app/types/props';
import { AppState, SettingsState } from 'store/app/types/state';
import { Flex } from 'styles';
import { ID } from 'helpers';

export function Loading(props: LoadingProps): JSX.Element {
    const theme = useSelector((state: { settings: SettingsState }) => state.settings.theme);
    const loadingColor = theme === 'dark' ? 'light' : 'dark';
    const className = props.className ? props.className : '';
    const spinnerProps: SpinnerProps = {
        animation: 'border',
        variant: loadingColor,
        ...(props.size === 'small' && { size: 'sm' }),
    };

    return (
        <div className={'loading medium text-center ' + className}>
            <Spinner {...spinnerProps} />
        </div>
    );
}

export function LoadingTickerHeader(): JSX.Element {
    return (
        <div className="loadingTickerHeader content-block">
            <div className="loader-wrapper">
                <div className="loader-animation">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 719.53 59.49">
                        <path
                            d="M0 0v59.49h719.53V0zm186.42 56.47H7.19V4.38h179.23zm130.21 0h-93.44V4.38h93.44zm120 0h-93.44V4.38h93.44zm120 0h-93.44V4.38h93.44z"
                            fill="#141f27"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export function LoadingTickerNews(): JSX.Element {
    return (
        <div className="loadingTickerNews content-block">
            <div className="loader-wrapper">
                <div className="loader-animation">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 719.53 59.49">
                        <path
                            d="M0 0v59.49h719.53V0zm186.42 56.47H7.19V4.38h179.23zm130.21 0h-93.44V4.38h93.44zm120 0h-93.44V4.38h93.44zm120 0h-93.44V4.38h93.44z"
                            fill="#141f27"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export function LoadingFeatured(props: { nb: number }): JSX.Element {
    return (
        <Flex justify="space-between">
            {Array.from(Array(props.nb), (e, i) => (
                <ContentLoaderFeatured key={`loading-featured-` + i} />
            ))}
        </Flex>
    );
}
/*
<ContentLoader
            speed={2}
            width={177} // temp
            height={91}
            viewBox="0 0 177 91"
            backgroundColor="#141f27"
            foregroundColor="#1d2b35"
        >
            <rect x="20" y="0" rx="2" ry="2" width="150" height="40" />

            <rect x="20" y="50" rx="2" ry="2" width="50" height="13" />
            <rect x="130" y="50" rx="2" ry="2" width="40" height="13" />

            <rect x="20" y="71" rx="2" ry="2" width="50" height="13" />
            <rect x="130" y="71" rx="2" ry="2" width="40" height="13" />
        </ContentLoader>

        */
function ContentLoaderFeatured(): JSX.Element {
    return (
        
        <ContentLoader 
            gradientRatio={2}
            speed={5}
            width={270} // temp
            height={50}
            viewBox=""
            backgroundColor="#141f27"
            foregroundColor="#1d2b35"
        >
            <rect x="20" y="10" rx="3" ry="3" width="85" height="35" /> 

            <rect x="115" y="12" rx="2" ry="2" width="60" height="11" /> 
            <rect x="203" y="12" rx="2" ry="2" width="60" height="11" /> 

            <rect x="115" y="32" rx="2" ry="2" width="60" height="11" /> 
            <rect x="203" y="32" rx="2" ry="2" width="60" height="11" /> 
        </ContentLoader>
    );
}

export function TickerHeaderLoader(): JSX.Element {
    return (
        <ContentLoader
            speed={1.5}
            width={921} // temp
            height={78}
            viewBox="0 0 921 78"
            backgroundColor="#141f27"
            foregroundColor="#1d2b35"
        >
            <rect x="14" y="14" rx="2" ry="2" width="135" height="50" />
            <rect x="165" y="14" rx="2" ry="2" width="210" height="50" />
            <rect x="390" y="14" rx="2" ry="2" width="120" height="50" />

            <circle cx="835" cy="40" r="21" />
            <circle cx="890" cy="40" r="21" />
        </ContentLoader>
    );
}

export function LoadingNews(props: { nb: number; type: string }): JSX.Element {
    let key = 1;
    const rand = () => Math.floor(Math.random() * 10000);
    const randomKey = () => {
        const newKey = rand();
        if (newKey === key) {
            key = rand();
            return key;
        }
        key = newKey;
        return key;
    };

    return (
        <Flex direction="column" className="p3">
            {Array.from(Array(props.nb), (e, i) => (
                <ContentLoaderNews
                    key={`loading-` + props.type + `-` + i}
                    uniqueKey={`loading-` + props.type + `-` + i}
                />
            ))}
        </Flex>
    );
}

const ContentLoaderNews = (props: { uniqueKey: string }): JSX.Element => (
    <ContentLoader
        speed={2}
        width={440} // prop?
        height={35}
        viewBox="0 0 440 35"
        backgroundColor="#141f27" // use state / prop
        foregroundColor="#1d2b35" // use state / prop
        uniqueKey={props.uniqueKey}
    >
        <circle cx="8" cy="15" r="8" />
        <rect x="23" y="9" rx="2" ry="2" width="25" height="12" />
        <rect x="55" y="9" rx="2" ry="2" width="385" height="12" />
    </ContentLoader>
);

export function LoadingCandles(): JSX.Element {
    const staticUrl = useSelector((state: { app: AppState }) => state.app.staticUrl);
    const candles = staticUrl + 'public/images/candles.gif';
    return (
        <div className="text-center" style={{ paddingTop: '40px', opacity: 0.8 }}>
            <img src={candles} srcSet={candles + ' 2x'} width="181" />
        </div>
    );
}

export function LoadingLogin({ loading }: { loading: boolean }): JSX.Element {
    const staticUrl = useSelector((state: { app: AppState }) => state.app.staticUrl);
    const candles = staticUrl + 'public/images/candles.gif';
    return (
        <div
            className="text-center"
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                backgroundColor: '#1a2831',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...(loading === false && { display: 'none' }),
            }}
        >
            <img src={candles} srcSet={candles + ' 2x'} width="181" />
        </div>
    );
}

export const LoadingTickerDisplay = (props: { ticker?: string }): JSX.Element => {
    return (
        <ContentLoader
            uniqueKey={`loader-` + props.ticker}
            speed={1}
            width={110} // temp
            height={12}
            viewBox="0 0 100 12"
            backgroundColor="#141f27"
            foregroundColor="#1d2b35"
        >
            <rect x="5" y="2" rx="2" ry="2" width="50" height="12" />
            <rect x="60" y="2" rx="2" ry="2" width="45" height="12" />
        </ContentLoader>
    );
};
