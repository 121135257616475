import React from "react";

const iconFillColor = "#b8d0e1";

export const AvatarSVG = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 96 96"
        width="22" 
        height="22" 
        >
        <path 
            fill={iconFillColor} 
            d="M15.22 55.89C12 59 11.5 66.18 14 72.08c2 4.92 9.64 11.28 16.43 13.57 7.88 2.74 25.39 2.63 33.7-.11 7.34-2.4 14.78-8.31 16.86-13.46 2.51-5.9 2-13.13-1.21-16.19l-2.62-2.74H17.84Z"
            />
        <circle 
            fill={iconFillColor} 
            cx="48.08" 
            cy="27.51" 
            r="19.16"
            />
    </svg>
);

export const NotificationSVG = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 96 96"
        width="22" 
        height="22" 
        >
        <path 
            fill={iconFillColor} 
            d="M80.2 62.47a7.09 7.09 0 0 0 4.73-5.34 6 6 0 0 0-2.11-6.53A27.71 27.71 0 0 1 70.72 35a23.49 23.49 0 0 1-1.09-5.48S66.81 6.24 40.17 9h-.09c-26.22 5.43-21.9 28.48-21.9 28.48a24.49 24.49 0 0 1 .61 5.52 27.71 27.71 0 0 1-6.85 18.51 6 6 0 0 0 0 6.86A7.08 7.08 0 0 0 18 72.05s22.29.19 62.2-9.58ZM42 87.67a14.89 14.89 0 0 0 14.82-13.4 286.09 286.09 0 0 1-28.93 3.27A14.89 14.89 0 0 0 42 87.67Z"
            />
    </svg>
);
