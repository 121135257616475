import { ADD, DELETE } from 'components/utils/const';
import { Action } from '../types';
//import {  } from '../types/props';

export const ActionTypes = {
    TICKER_UPDATE: "TICKER_UPDATE", // mettre dans settings?
    CRYPTO_UPDATE: "CRYPTO_UPDATE",

    LOAD_ALL_WATCHLISTS: "LOAD_ALL_WATCHLISTS",
    LOAD_ALL_WATCHLISTS_SUCCESS: "LOAD_ALL_WATCHLISTS_SUCCESS",
    LOAD_ALL_WATCHLISTS_FAIL: "LOAD_ALL_WATCHLISTS_FAIL",
    
    UPDATE_WATCHLIST_SUCCESS: "UPDATE_WATCHLIST_SUCCESS", 
    UPDATE_WATCHLIST_FAIL: "UPDATE_WATCHLIST_FAIL", 

    UPDATE_TRENDING: "UPDATE_TRENDING", 
    
    GET_SPARK_CHARTS: "GET_SPARK_CHARTS",
    GET_SPARK_CHARTS_SUCCESS: "GET_SPARK_CHARTS_SUCCESS",
    GET_SPARK_CHARTS_FAIL: "GET_SPARK_CHARTS_FAIL",
    
    GET_CRYPTO_CHART_SUCCESS: "GET_CRYPTO_CHART_SUCCESS",
    GET_CRYPTO_CHART_FAIL: "GET_CRYPTO_CHART_FAIL",

    LOAD_WATCHLIST_DATA_SUCCESS: "LOAD_WATCHLIST_DATA_SUCCESS",
    LOAD_WATCHLIST_DATA_FAIL: "LOAD_WATCHLIST_DATA_FAIL",

    GET_TICKER_DATA: "GET_TICKER_DATA",
    GET_TICKER_DATA_SUCCESS: "GET_TICKER_DATA_SUCCESS",
    GET_TICKER_DATA_FAIL: "GET_TICKER_DATA_FAIL",

    LOAD_TRENDING: "LOAD_TRENDING",
    LOAD_TRENDING_SUCCESS: "LOAD_TRENDING_SUCCESS",
    LOAD_TRENDING_FAIL: "LOAD_TRENDING_FAIL",
    
    LOAD_TRENDING_DATA: "LOAD_TRENDING_DATA",
    LOAD_TRENDING_DATA_SUCCESS: "LOAD_TRENDING_DATA_SUCCESS",
    LOAD_TRENDING_DATA_FAIL: "LOAD_TRENDING_DATA_FAIL",

    LOAD_FEATURED: "LOAD_FEATURED",
    LOAD_FEATURED_SUCCESS: "LOAD_FEATURED_SUCCESS",
    LOAD_FEATURED_FAIL: "LOAD_FEATURED_FAIL",
    LOAD_FEATURED_DATA_SUCCESS: "LOAD_FEATURED_DATA_SUCCESS",
    LOAD_FEATURED_DATA_FAIL: "LOAD_FEATURED_DATA_FAIL",

    WS_SUBSCRIBE: "WS_SUBSCRIBE",
    WS_SUBSCRIBE_SUCCESS: "WS_SUBSCRIBE_SUCCESS",
    WS_SUBSCRIBE_FAIL: "WS_SUBSCRIBE_FAIL",
    
    WS_SUBSCRIBE_CRYPTO_SUCCESS: "WS_SUBSCRIBE_CRYPTO_SUCCESS",
    WS_SUBSCRIBE_CRYPTO_FAIL: "WS_SUBSCRIBE_CRYPTO_FAIL",
    WS_UNSUBSCRIBE_CRYPTO: "WS_UNSUBSCRIBE_CRYPTO", // add success/fail?
    WS_CONNECT: "WS_CONNECT",
    WS_DISCONNECT: "WS_DISCONNECT",

    UPDATE_WATCHLIST: "UPDATE_WATCHLIST",

    UPDATE_SELECTED_WATCHLIST: "UPDATE_SELECTED_WATCHLIST",
    UPDATE_SELECTED_WATCHLIST_SUCCESS: "UPDATE_SELECTED_WATCHLIST_SUCCESS",

    // temp watchlist on sign up
    // reuse for watchlist management?
    ADD_TO_WATCHLIST: "ADD_TO_WATCHLIST",
    REMOVE_FROM_WATCHLIST: "REMOVE_FROM_WATCHLIST",
    SUBMIT_TEMP_WATCHLIST: "SUBMIT_TEMP_WATCHLIST",

};

// Watchlist Actions 

// put type in file - temp / check
export const loadTrendingData = (country: "US" | "CA", tickers: string): Action => {
    console.log("loadTrendingData() action");
    return {
        type: ActionTypes.LOAD_TRENDING_DATA,
        payload: {
            country: country,
            tickers: tickers
        }
    }
};
    

// Replace data for payload... add return type
export const addTickerToWatchList = ( watchlistId: number, tickerId: number, ticker: string, price: string | number ): Action => ({
    type: ActionTypes.UPDATE_WATCHLIST,
    payload: {
        type: ADD,
        watchlistId: watchlistId,
        tickerId: tickerId,
        ticker: ticker,
        price: price,
    }
});

// Replace data for payload... add return type
export const removeTickerFromWatchlist = ( tickerId: number, ticker: string, userId: number | null ) => ({
    type: ActionTypes.UPDATE_WATCHLIST,
    data: {
        type: DELETE,
        tickerId: tickerId,
        ticker: ticker,
        userId: userId
    }
});

export const updateSelectedWatchlist = ( key: string | number, name: string ): Action => ({
    type: ActionTypes.UPDATE_SELECTED_WATCHLIST,
    payload: {
        key: key,
        name: name
    }
});

// change name? used for modal on first sign in. also use for regular watchlists?
export const addTickerToTempWatchlist = ( id: number, ticker: string, name: string ): Action => ({
    type: ActionTypes.ADD_TO_WATCHLIST,
    payload: {
        id: id,
        ticker: ticker,
        name: name,
    }
});

// change name? used for modal on first sign in. also use for regular watchlists?
export const removeTickerFromTempWatchlist = ( id: number ): Action => ({
    type: ActionTypes.REMOVE_FROM_WATCHLIST,
    payload: id
});

// change name? used for modal on first sign in. also use for regular watchlists?
export const submitTempWatchlist = (name: string): Action => ({
    type: ActionTypes.SUBMIT_TEMP_WATCHLIST,
    payload: name
});

export const Actions = {
    loadTrendingData: loadTrendingData,
    addTickerToWatchList: addTickerToWatchList,
    removeTickerFromWatchlist: removeTickerFromWatchlist,

    updateSelectedWatchlist: updateSelectedWatchlist,

    addTickerToTempWatchlist: addTickerToTempWatchlist,
    removeTickerFromTempWatchlist: removeTickerFromTempWatchlist,

    submitTempWatchlist: submitTempWatchlist,
};